import React from 'react';
import { Map } from 'subway-router';
import { TLoadService } from '@Framework/Core/App';
import { CompanyInstance, LoggedIn } from '@App/Middleware';
import {
    Create, CreateDataSources,
    CreateDevices, CreateSensors, CreateSets, DetailsDataSources,
    DetailsDevices,
    DetailsLogs, DetailsSensors, DetailsSets,
    List, ListDataSources,
    ListDevices,
    ListLogs, ListSensors, ListSets,
    Settings,
} from '@App/Service/Companies';
import { Location } from '@Framework/Core/Location';
import { RangeSelector } from '@App/Component/RangeSelector';

export function register(map : Map, loadService : TLoadService) {
    map.group('/companies', group => {
        group.route('/', res => {
            loadService(<List
                page={Number(res.key('page')) || 1}
                limit={Number(res.key('limit')) || 50}
                orderBy={res.key('orderBy') || undefined}
                sort={res.key('sort') || undefined}
                deleted={Number(Location.param('deleted')) || null}
            />);
        });
        group.group('/{id:i}', group => {
            group.route('/settings', res => {
                loadService(<Settings
                    id={Number(res.segment(2))}
                />);
            });
            group.group('/devices', group => {
                group.route('/', res => {
                    loadService(<ListDevices
                        companyId={Number(res.segment(2))}
                        page={Number(res.key('page')) || 1}
                        limit={Number(res.key('limit')) || 50}
                        orderBy={res.key('orderBy') || undefined}
                        sort={res.key('sort') || undefined}
                        status={Number(Location.param('status')) || null}
                    />);
                });
                group.route('/create', res => {
                    loadService(<CreateDevices
                        companyId={Number(res.segment(2))}
                    />);
                });
                group.route('/{id:i}', res => {
                    loadService(<DetailsDevices
                        companyId={Number(res.segment(2))}
                        id={Number(res.segment(4))}
                    />);
                });
            });
            group.group('/data-sources', group => {
                group.route('/', res => {
                    loadService(<ListDataSources
                        companyId={Number(res.segment(2))}
                        page={Number(res.key('page')) || 1}
                        limit={Number(res.key('limit')) || 50}
                        orderBy={res.key('orderBy') || undefined}
                        sort={res.key('sort') || undefined}
                    />);
                });
                group.route('/create/{id:i}', res => {
                    loadService(<CreateDataSources
                        companyId={Number(res.segment(2))}
                        createType={Number(res.segment(5))}
                    />);
                });
                group.route('/{id:i}', res => {
                    loadService(<DetailsDataSources
                        companyId={Number(res.segment(2))}
                        id={Number(res.segment(4))}
                    />);
                });
            });

            group.group('/sets', group => {
                group.route('/', res => {
                    loadService(<ListSets
                        companyId={Number(res.segment(2))}
                        page={Number(res.key('page')) || 1}
                        limit={Number(res.key('limit')) || 50}
                        orderBy={res.key('orderBy') || undefined}
                        sort={res.key('sort') || undefined}
                        // status={Number(Location.param('status')) || null}
                    />);
                });
                group.route('/create', res => {
                    loadService(<CreateSets
                        companyId={Number(res.segment(2))}
                    />);
                });
                group.route('/{id:i}', res => {
                    loadService(<DetailsSets
                        companyId={Number(res.segment(2))}
                        id={Number(res.segment(4))}
                    />);
                });
            });

            group.group('/logs', group => {
                group.route('/', res => {
                    loadService(<ListLogs
                        companyId={Number(res.segment(2))}
                        page={Number(res.key('page')) || 1}
                        limit={Number(res.key('limit')) || 50}
                        orderBy={res.key('orderBy') || undefined}
                        sort={res.key('sort') || undefined}
                        typeId={Number(Location.param('typeId')) || null}
                        severity={Number(Location.param('severity')) || null}
                        ip={Location.param('ip') || null}
                        rangeType={RangeSelector.stringToRangeType(res.key('rangeType'))}
                        dateFrom={res.key('dateFrom') ? Number(res.key('dateFrom')) : undefined}
                        dateTo={res.key('dateTo') ? Number(res.key('dateTo')) : undefined}
                    />);
                });
                group.route('/{id:i}', res => {
                    loadService(<DetailsLogs
                        companyId={Number(res.segment(2))}
                        id={Number(res.segment(4))}
                    />);
                });
            });
            group.group('/sensors', group => {

                group.route('/', res => {
                    loadService(<ListSensors
                        companyId={Number(res.segment(2))}
                        page={Number(res.key('page')) || 1}
                        limit={Number(res.key('limit')) || 50}
                        orderBy={res.key('orderBy') || undefined}
                        sort={res.key('sort') || undefined}
                        port={res.key('port') || undefined}
                        device={res.key('device') || undefined}
                    />);
                });
                group.route('/create', res => {
                    loadService(<CreateSensors
                        companyId={Number(res.segment(2))}
                    />);
                });
                group.route('/{id:i}', res => {
                    loadService(<DetailsSensors
                        companyId={Number(res.segment(2))}
                        id={Number(res.segment(4))}
                    />);
                });
            });

        }).middleware(new CompanyInstance());
        group.route('/create', () => {
            loadService(<Create />);
        });
    }).middleware(new LoggedIn(loadService)).name('companies');
}