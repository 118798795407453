import React from 'react';
import { FormField, IFormFieldProps } from '@Framework/Component/FormField';
import './SearchField.less';
import { OnClickOutside } from '@Framework/Factory';

interface IProps extends IFormFieldProps {
    id : string,
    onSearch : (keywords : string, page : number) => Promise<ISearchResult>,
    icon ?: string,
}
interface IState {
    searching : boolean,
    focused : boolean,
    keywords : string,
    options : ISearchOption[],
    pages : number,
    total : number,
    loading : boolean,
}
export interface ISearchResult {
    keywords : string,
    options : ISearchOption[],
    pages : number,
    total : number,
}
export interface ISearchOption {
    id : number | string,
    name : string | React.ReactNode,
}

export class SearchField extends FormField<IProps, IState> {

    protected readonly _formFieldClass : string = 'search-field';
    private isMount : boolean = false;

    public componentDidMount() : void {
        this.isMount = true;
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    constructor(props) {
        super(props);
        this.state = {
            searching: false,
            focused: false,
            keywords: '',
            options: [],
            pages: 0,
            total: 0,
            loading: false,
        };
    }

    protected renderField() : React.ReactElement {
        return (
            <div id={this.props.id}>
                <OnClickOutside topLevelId={this.props.id} onClick={() => this.setState({ focused: false })}>
                    <div className={`input-group ${this.props.size ? ` input-group-${this.props.size}` : ''}${this.props.store.isValid ? '' : ' is-invalid'}`}>
                        <div className="input-group-text">
                            {this.state.searching ? <span className="spinner-border spinner-border-sm" /> : <i className={`fa fa-${this.props.icon ? this.props.icon : 'search'}`} />}
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            value={this.props.store.value ? this.props.store.meta.name : this.state.keywords}
                            onChange={e => this.onType(e.target.value)}
                            onFocus={() => this.setState({ focused: true })}
                        />
                        {this.props.store.value && <button type="button" className="btn btn-secondary" onClick={() => this.clear()}><i className="fa fa-ban" /></button>}
                    </div>
                    {(this.state.focused && this.state.options.length > 0) &&
                        <ul className="dropdown-menu show" style={{ overflow: 'auto', maxHeight: '260px' }}>
                            {this.state.options.map(item => (
                                <li key={item.id} className="dropdown-item" onClick={() => this.onSelect(item)}>{item.name}</li>
                            ))}
                            {this.state.pages < this.state.total &&
                            <li className={`dropdown-item bg-info text-center${this.state.loading ? ' disabled' : ''}`} onClick={() => this.loadMore()}>
                                {this.state.loading ? <span className="spinner-border spinner-border-sm" /> : 'Load more'}
                            </li>
                            }
                        </ul>
                    }
                </OnClickOutside>
            </div>
        );
    }

    private onType(keywords : string) : void {
        if(this.props.store.value) this.props.store.clear();
        (async () => {
            this.setState({
                keywords,
                searching: keywords.length >= 2,
                pages: 0,
                total: 0,
            });
            if(keywords.length >= 2) {
                const res = await this.props.onSearch(keywords, 1);
                if(!this.isMount || res.keywords != this.state.keywords) return;
                this.setState({
                    searching: false,
                    options: res.options,
                    pages: res.pages,
                    total: res.total,
                });
            } else this.setState({ options: [] });
        })();
    }

    private loadMore() : void {
        if(this.state.loading) return;
        (async () => {
            this.setState({ loading: true });
            const res = await this.props.onSearch(this.state.keywords, this.state.pages + 1);
            if(!this.isMount || !this.state.loading || res.keywords != this.state.keywords) return;
            this.setState(state => ({
                options: [ ...state.options, ...res.options ],
                pages: res.pages,
                total: res.total,
                loading: false,
            }));
        })();
    }

    private onSelect(selected : ISearchOption) : void {
        this.props.store.fill(selected.id, selected);
        this.setState({
            keywords: '',
            options: [],
            pages: 0,
            total: 0,
            loading: false,
        });
    }

    private clear() : void {
        this.props.store.clear();
        this.setState({
            focused: false,
            keywords: '',
            searching: false,
            options: [],
            pages: 0,
            total: 0,
            loading: false,
        });
    }

}