import React from 'react';
import { MainLayout } from '@App/Layout/MainLayout/MainLayout';

interface IProps {}
interface IState {}

export class AccessDeny extends React.Component<IProps, IState> {

    public render() : React.ReactNode {
        return (
            <MainLayout service={'system-page'}>
                <div className="container-fluid my-5">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <div className="alert alert-danger">
                                <i className="fa fa-exclamation-triangle" /> Access denied
                            </div>
                        </div>
                    </div>
                </div>
            </MainLayout>
        );
    }

}