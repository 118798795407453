import { ERequestMethod, IModelOptions, Model, Reply } from '@Framework/Library/Gateway';
import { AuthModelReq, AuthModelRes } from '@App/Service/Auth/Model';
import { ERemoteResource } from '@App';
import { Session } from '@Framework/Core/Session';

export abstract class AuthModel extends Model {

    public static login(
        payload : AuthModelReq.Login.IPayload,
        options : IModelOptions = {},
    ) : Promise<Reply<AuthModelRes.Login.IPayload>> {
        return this.request<{}, AuthModelReq.Login.IPayloadOutput, AuthModelRes.Login.IPayload>({
            resource: ERemoteResource.Auth,
            path: 'signin',
            auth: false,
            method: ERequestMethod.POST,
            payload: AuthModelReq.Login.getPayload(payload),
            responseHandler: AuthModelRes.Login.getPayload,
        }, options);
    }

    public static refresh(
        options : IModelOptions = {},
    ) : Promise<Reply<AuthModelRes.Login.IPayload>> {
        return this.request<{}, AuthModelReq.Token.IPayloadOutput, AuthModelRes.Login.IPayload>({
            resource: ERemoteResource.Auth,
            path: 'refresh',
            auth: false,
            method: ERequestMethod.POST,
            payload: AuthModelReq.Token.getPayload({ token: Session.token.refresh }),
            responseHandler: AuthModelRes.Login.getPayload,
        }, options);
    }

    public static user(
        options : IModelOptions = {},
    ) : Promise<Reply<AuthModelRes.User.IPayload>> {
        return this.request<{}, {}, AuthModelRes.User.IPayload>({
            resource: ERemoteResource.Auth,
            path: 'user',
            responseHandler: AuthModelRes.User.getPayload,
        }, options);
    }

    public static setCompany(
        id : number,
        options : IModelOptions = {},
    ) : Promise<Reply<{}>> {
        return this.request<{}, {}, {}>({
            resource: ERemoteResource.Auth,
            path: `switchcompany/${id}`,
            method: ERequestMethod.POST,
        }, options);
    }

    public static logout(
        options : IModelOptions = {},
    ) : Promise<Reply<{}>> {
        return this.request<{}, AuthModelReq.Token.IPayload, {}>({
            resource: ERemoteResource.Auth,
            path: 'signout',
            payload: { token: Session.token.access },
            method: ERequestMethod.POST,
        }, options);
    }

    public static logoutUsers(
        payload : AuthModelReq.Logout.IPayload,
        options : IModelOptions = {},
    ) : Promise<Reply<{}>> {
        return this.request<{}, AuthModelReq.Token.IPayload, {}>({
            resource: ERemoteResource.Auth,
            path: 'signout',
            payload: AuthModelReq.Logout.getPayload(payload),
            method: ERequestMethod.POST,
        }, options);
    }

    public static expire(
        options : IModelOptions = {},
    ) : Promise<Reply<{}>> {
        return this.request<{}, AuthModelReq.Token.IPayload, {}>({
            resource: ERemoteResource.Auth,
            path: 'expire',
            payload: { token: Session.token.access },
            method: ERequestMethod.POST,
        }, options);
    }

}