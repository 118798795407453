import React from 'react';
import { MainLayout } from '@App/Layout';
import { Link } from '@Framework/Factory';
import { UserModel, UserModelStruct } from '@App/Service/Users/Model';
import { CreateFormStore } from '@App/Service/Companies/Create/CreateFormStore';
import { Form } from '@Framework/Library/Form';
import { InputField, SearchField, SelectField, TextField } from '@Framework/Component/FormField';
import { observer } from 'mobx-react';
import { Session } from '@Framework/Core/Session';
import { App } from '@Framework/Core/App';
import { ENotificationType } from '@Framework/Component/Notification';
import { CompaniesModel } from '@App/Service/Companies/Model';
import { Loading } from '@Framework/Component/Loading';

enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}
interface IProps {}
interface IState {
    status : EStatus,
    saving : boolean,
}

@observer
export class Create extends React.Component<IProps, IState> {

    private isMount : boolean = false;
    private form : CreateFormStore = new CreateFormStore();

    constructor(props) {
        super(props);
        this.state = {
            status: EStatus.Unloaded,
            saving: false,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.form.save();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        return (
            <MainLayout service="companies">
                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="col">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link url="/"><a><i className="fa fa-home" /> Home</a></Link></li>
                                    <li className="breadcrumb-item"><Link url="/companies"><a>Companies</a></Link></li>
                                    <li className="breadcrumb-item active">Create</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                {this.state.status != EStatus.Loaded &&
                    <div className="card-body">
                        {this.state.status == EStatus.Loading && <Loading />}
                        {this.state.status == EStatus.Failed && <div className="alert alert-danger"><i className="fa fa-exclamation-triangle" /> Loading error...</div>}
                    </div>
                }
                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="col">
                            <div className="card">
                                <div className="card-header"><h3 className="m-0">Create</h3></div>
                                <Form store={this.form} onSubmit={() => this.onSubmit()}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm">
                                                <div className="form-group">
                                                    <label>Name  <span className="text-danger">*</span></label>
                                                    <InputField disabled={this.state.saving} store={this.form.fields.name} />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Address</label>
                                                    <InputField disabled={this.state.saving} store={this.form.fields.address} />
                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <div className="form-group">
                                                    <label>Owner User <span className="text-danger">*</span></label>
                                                    <SearchField
                                                        id={'user-search'}
                                                        onSearch={async (keywords, page) => {
                                                            const res = await UserModel.search({
                                                                qstr: keywords,
                                                                page,
                                                                limit: 999,
                                                            });
                                                            return {
                                                                keywords,
                                                                options: res.success && res.payload
                                                                    ? res.payload.data.map(item => ({
                                                                        id: item.id,
                                                                        name: item.name,
                                                                    }))
                                                                    : [],
                                                                pages: 1,
                                                                total: 1,
                                                            };
                                                        }}
                                                        store={this.form.fields.ownerId}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {Session.user.role.id == 1 &&
                                            <div className="row">
                                                <div className="col-sm">
                                                    <div className="form-group">
                                                        <label>Notes</label>
                                                        <TextField disabled={this.state.saving} store={this.form.fields.notes} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="row">
                                            <div className="col-sm">
                                                <div className="form-group">
                                                    <label>Instance</label>
                                                    <InputField disabled={this.state.saving} store={this.form.fields.instance} placeholder="Auto generated" />
                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <div className="form-group">
                                                    <label>Instance port</label>
                                                    <InputField disabled={this.state.saving} store={this.form.fields.instancePort} placeholder="443" />
                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <div className="form-group">
                                                    <label>Instance 2</label>
                                                    <InputField disabled={this.state.saving} store={this.form.fields.instanceTwo} placeholder="not used" />
                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <div className="form-group">
                                                    <label>Instance 2 port</label>
                                                    <InputField disabled={this.state.saving} store={this.form.fields.instancePortTwo} placeholder="443" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col"/>
                                            <div className="col-auto">
                                                <button type="submit" disabled={this.state.saving} className="btn btn-primary">
                                                    {this.state.saving
                                                        ? <span className="spinner-border spinner-border-sm" />
                                                        : <i className="fa fa-check" />
                                                    } Create
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </MainLayout>
        );
    }

    private onSubmit() : void {
        this.form.validate();
        if(!this.form.isValid) return;
        this.setState({ saving: true });
        (async () => {
            const res = await CompaniesModel.create(this.form.getValues());
            if(!this.isMount) return;
            if(res.success) {
                this.form.save();
                App.notification({
                    type: ENotificationType.Success,
                    title: 'Success',
                    message: 'Successfully created.',
                });
                App.redirect(`/companies/${res.payload.data.id}`);
            }
            this.setState({ saving: true });
        })();
    }

}