import { DefaultStruct } from '@App/Structures';

export namespace DataSourcesModelReq {

    export namespace List {
        export interface IQuery extends DefaultStruct.IQuery {
            status ?: number,
        }
        export interface IQueryOutput extends DefaultStruct.IQueryOutput {
            status ?: number,
        }
        export function getQuery(query : IQuery) : IQueryOutput {
            const output : IQueryOutput = {};
            if(query.page) output.page = query.page;
            if(query.limit) output.limit = query.limit;
            if(query.orderBy) output.orderBy = query.orderBy;
            if(query.sort) output.sort = query.sort;
            if(query.status) output.status = query.status;
            return output;
        }
    }

    export namespace Create {
        export interface IPayload {
            type : number,
            active : number,
            hostname : string,
            port : number,
            username : string,
            password : string,
            authType : number,
            option1num : number,
            option2num : number,
            option3num : number,
            option1str : string,
            option2str : string,
            option3str : string,
            name : string,
        }
        export interface IPayloadOutput {
            type : number,
            active : number,
            hostname : string,
            port : number,
            username : string,
            password : string,
            authType : number,
            option1num : number,
            option2num : number,
            option3num : number,
            option1str : string,
            option2str : string,
            option3str : string,
            name : string,
        }
        export function getOutput(payload : IPayload) : IPayloadOutput {
            const data : IPayloadOutput = {
                name: payload.name || '',
                type: payload.type || null,
                hostname: payload.hostname || '',
                username: payload.username || '',
                password: payload.password || '',
                option1str: payload.option1str || '',
                option2str: payload.option2str || '',
                option3str: payload.option3str || '',
                active: payload.active ? 1 : 0,
                port: payload.port || null,
                authType: payload.authType || null,
                option1num: payload.option1num || null,
                option2num: payload.option2num || null,
                option3num: payload.option3num || null,
            };
            return data;
        }
    }

    export namespace Details {
        export interface IPayload {
            id : string,
            type : number,
            active : number,
            hostname : string,
            port : number,
            username : string,
            password : string,
            authType : number,
            option1num : number,
            option2num : number,
            option3num : number,
            option1str : string,
            option2str : string,
            option3str : string,
            name : string,
        }
        export interface IPayloadOutput {
            id : string,
            type : number,
            active : number,
            hostname : string,
            port : number,
            username : string,
            password : string,
            authType : number,
            option1num : number,
            option2num : number,
            option3num : number,
            option1str : string,
            option2str : string,
            option3str : string,
            name : string,
        }
        export function getOutput(payload : IPayload) : IPayloadOutput {
            const data : IPayloadOutput = {
                id: payload.id || '',
                name: payload.name || '',
                type: payload.type || 0,
                hostname: payload.hostname || '',
                username: payload.username || '',
                password: payload.password || '',
                option1str: payload.option1str || '',
                option2str: payload.option2str || '',
                option3str: payload.option3str || '',
                active: payload.active ? 1 : 0,
                port: payload.port || 0,
                authType: payload.authType || 0,
                option1num: payload.option1num || 0,
                option2num: payload.option2num || 0,
                option3num: payload.option3num || 0,
            };
            return data;
        }
    }

}