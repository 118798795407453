import React from 'react';
import moment from 'moment';
import { Locale } from '@Framework/Core/Locale';

interface IProps {
    displayed ?: number,
    onDisplayChanged : (displayed : number) => void,
}
interface IState {
    year : string,
}

export class CalendarControls extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            year: String(this.getDisplayed().year()),
        };
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(this.props.displayed != prevProps.displayed) {
            this.setState({ year: String(this.getDisplayed().year()) });
        }
    }

    public render() : React.ReactNode {
        const displayed = this.getDisplayed();
        return (
            <div className="row m-0">
                <div className="col-auto p-0">
                    <button type="button" className="btn btn-sm btn-outline-light" onClick={() => this.prevMonth()}>
                        <i className="fa fa-chevron-left" />
                    </button>
                </div>
                <div className="col p-0">
                    <select className="form-select form-select-sm" value={displayed.month()} onChange={e => this.onMonthChanged(e.target.value)}>
                        {Locale.Lang.months.full.map((name, index) => (
                            <option key={index} value={index}>{name}</option>
                        ))}
                    </select>
                </div>
                <div className="col p-0">
                    <input type="text" className="form-control form-control-sm" value={this.state.year} onChange={e => this.onYearChanged(e.target.value)} onBlur={() => this.onBlurYear()} />
                </div>
                <div className="col-auto p-0">
                    <button type="button" className="btn btn-sm btn-outline-light" onClick={() => this.nextMonth()}>
                        <i className="fa fa-chevron-right" />
                    </button>
                </div>
            </div>
        );
    }

    private prevMonth() : void {
        this.props.onDisplayChanged(this.getDisplayed().subtract(1, 'month').unix());
    }

    private nextMonth() : void {
        this.props.onDisplayChanged(this.getDisplayed().add(1, 'month').unix());
    }

    private onMonthChanged(month : string) : void {
        this.props.onDisplayChanged(this.getDisplayed().month(Number(month)).unix());
    }

    private onYearChanged(year : string) : void {
        const value = year.trim().replace(/\D/, '');
        if(value.length == 4) {
            this.props.onDisplayChanged(this.getDisplayed().year(Number(value)).unix());
        } else {
            this.setState({ year: value });
        }
    }

    private onBlurYear() : void {
        if(this.state.year.length == 4) return;
        this.setState({ year: String(this.getDisplayed().year()) });
    }

    private getDisplayed() : moment.Moment {
        const displayed = this.props.displayed
            ? moment.unix(this.props.displayed)
            : this.props.displayed
                ? moment.unix(this.props.displayed)
                : moment();
        displayed.date(1);
        return displayed;
    }

}