import { ELanguage, ILanguage, Languages } from '@Framework/Core/Language';

interface IConfig {
    locale ?: ELocale,
    language ?: ELanguage,
}
export enum ELocale {
    EU,
    US,
}

export abstract class Locale {

    private static _formats : ELocale = ELocale.EU;
    private static _translation : ELanguage = ELanguage.EN;

    public static get formats() : ELocale {
        return Locale._formats;
    }

    public static get translation() : ELanguage {
        return Locale._translation;
    }

    public static get Lang() : ILanguage {
        return Languages[this._translation];
    }

    public static configure(options : IConfig) : void {
        if(options.locale) Locale._formats = options.locale;
        if(options.language) Locale._translation = options.language;
    }

}