import React from 'react';
import { MainLayout } from '@App/Layout';
import { Link } from '@Framework/Factory';
import { UserModel } from '@App/Service/Users/Model';
import { CreateFormStore } from '@App/Service/Users/Create/CreateFormStore';
import { Form } from '@Framework/Library/Form';
import { CheckField, InputField, SearchField, SelectField, SwitchField } from '@Framework/Component/FormField';
import { observer } from 'mobx-react';
import { Session } from '@Framework/Core/Session';
import { App } from '@Framework/Core/App';
import { ENotificationType } from '@Framework/Component/Notification';
import { CompaniesModel } from '@App/Service/Companies/Model';

interface IProps {}
interface IState {
    saving : boolean,
    openPassword : boolean,
}

@observer
export class Create extends React.Component<IProps, IState> {

    private isMount : boolean = false;
    private form : CreateFormStore = new CreateFormStore();

    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            openPassword: false,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.form.save();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        return (
            <MainLayout service="users">
                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="col">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link url="/"><a><i className="fa fa-home" /> Home</a></Link></li>
                                    <li className="breadcrumb-item"><Link url="/users"><a>Users</a></Link></li>
                                    <li className="breadcrumb-item active">Create</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="col">
                            <div className="card">
                                <div className="card-header"><h3 className="m-0">Create</h3></div>
                                <Form store={this.form} onSubmit={() => this.onSubmit()}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Name <span className="text-danger">*</span></label>
                                                    <InputField disabled={this.state.saving} store={this.form.fields.name} />
                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <div className="form-group">
                                                    <label>Email <span className="text-danger">*</span></label>
                                                    <InputField disabled={this.state.saving} store={this.form.fields.email} />
                                                </div>
                                            </div>
                                        </div>
                                        {Session.user.role.id == 1 &&
                                            <div className="row">
                                                <div className="col-sm">
                                                    <div className="form-group">
                                                        <label>Is active</label>
                                                        <SwitchField disabled={this.state.saving} store={this.form.fields.isActive} wide={true} />
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="form-group">
                                                        <label>Alert Daily</label>
                                                        <SwitchField disabled={this.state.saving} store={this.form.fields.alertDaily} wide={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="row">
                                            <div className="col-sm">
                                                <div className="form-group">
                                                    <label>Phone</label>
                                                    <InputField disabled={this.state.saving} store={this.form.fields.phone} />
                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <div className="form-group">
                                                    <label>Super User</label>
                                                    <SwitchField disabled={this.state.saving} store={this.form.fields.superuser} wide={true} />
                                                </div>
                                            </div>
                                        </div>
                                        {!this.form.fields.superuser.getValues() &&
                                            <div className="row pb-3">
                                                <div className="col">
                                                    {Session.user.role.id == 1 &&
                                                        <div className="row pb-2">
                                                            <div className="col"><h6>Companies</h6></div>
                                                            <div className="col-auto">
                                                                <button type="button" className="btn btn-success btn-sm" onClick={e => this.form.fields.companies.append()}>
                                                                    <i className="fa fa-plus"/> Add
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="row">
                                                        <div className="col">
                                                            {this.form.fields.companies.items.map((item, index) => (
                                                                <div key={item.id} className="card mb-1">
                                                                    <div className="card-body p-0 pt-2 px-3">
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <div className="form-group">
                                                                                    <label>Company <span className="text-danger">*</span></label>
                                                                                    <SearchField
                                                                                        id={'companies-search'}
                                                                                        onSearch={async (keywords, page) => {
                                                                                            const res = await CompaniesModel.search({
                                                                                                qstr: keywords,
                                                                                                page,
                                                                                                limit: 999,
                                                                                            });
                                                                                            return {
                                                                                                keywords,
                                                                                                options: res.success && res.payload
                                                                                                    ? res.payload.data.map(item => ({
                                                                                                        id: item.id,
                                                                                                        name: item.name,
                                                                                                    }))
                                                                                                    : [],
                                                                                                pages: 1,
                                                                                                total: 1,
                                                                                            };
                                                                                        }}
                                                                                        store={item.fields.id}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col">
                                                                                <div className="form-group">
                                                                                    <label>Role <span className="text-danger">*</span></label>
                                                                                    <SelectField disabled={this.state.saving} store={item.fields.role}>
                                                                                        <option></option>
                                                                                        <option value="3">User</option>
                                                                                        <option value="2">Admin</option>
                                                                                    </SelectField>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-auto">
                                                                                <button type="button" className="btn btn-danger mt-4" onClick={e => this.form.fields.companies.remove(index)}>
                                                                                    <i className="fa fa-times"/>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="row pb-3">
                                            <div className="col-sm">
                                                <div className="form-group">
                                                    <CheckField store={this.form.fields.sendLink} /> Send password link to use
                                                </div>
                                            </div>
                                        </div>
                                        {!this.form.fields.sendLink.getValues() &&
                                            <div className="row">
                                                <div className="col-sm">
                                                    <div className="form-group">
                                                        <label>Password <span className="text-danger">*</span></label>
                                                        <InputField type="password" disabled={this.state.saving} store={this.form.fields.password}/>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="form-group">
                                                        <label>Confirm password <span className="text-danger">*</span></label>
                                                        <InputField type="password" disabled={this.state.saving} store={this.form.fields.passwordConfirm}/>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col"/>
                                            <div className="col-auto">
                                                <button type="submit" disabled={this.state.saving} className="btn btn-primary">
                                                    {this.state.saving
                                                        ? <span className="spinner-border spinner-border-sm" />
                                                        : <i className="fa fa-check" />
                                                    } Create
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </MainLayout>
        );
    }

    private onSubmit() : void {
        this.form.validate();
        if(!this.form.isValid) return;
        this.setState({ saving: true });
        (async () => {
            const res = await UserModel.create(this.form.getValues());
            if(!this.isMount) return;
            if(res.success) {
                this.form.save();
                App.notification({
                    type: ENotificationType.Success,
                    title: 'Success',
                    message: 'Successfully created.',
                });
                App.redirect(`/users/${res.payload.data.id}`);
            }
            this.setState({ saving: false });
        })();
    }

}