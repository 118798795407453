import { IModelOptions, Model, Reply } from '@Framework/Library/Gateway';
import { DefaultStruct } from '@App/Structures';
import { LogsModelRes } from '@App/Service/Companies/Details/Logs/Model/LogsModel.res';
import { LogsModelReq } from '@App/Service/Companies/Details/Logs/Model/LogsModel.req';

export abstract class LogsModel extends Model {

    public static list(
        query : DefaultStruct.IQuery = {},
        options : IModelOptions = {},
    ) : Promise<Reply<LogsModelRes.List.IPayload>> {
        return this.request<DefaultStruct.IQuery, {}, LogsModelRes.List.IPayload>({
            path: 'logs',
            query: LogsModelReq.List.getQuery(query),
            responseHandler: LogsModelRes.List.getPayload,
        }, options);
    }

    public static details(
        id : number,
        options : IModelOptions = {},
    ) : Promise<Reply<LogsModelRes.Details.IPayload>> {
        return this.request<{}, {}, LogsModelRes.Details.IPayload>({
            path: `logs/${id}`,
            responseHandler: LogsModelRes.Details.getPayload,
        }, options);
    }

}