import React from 'react';
import { observer } from 'mobx-react';
import { Form } from '@Framework/Library/Form';
import { FiltersFormStore } from '@App/Service/Companies/Details/Devices/List/FiltersFormStore';
import { SelectField } from '@Framework/Component/FormField';
import { Location } from '@Framework/Core/Location';
import { App } from '@Framework/Core/App';
import { DefaultStruct } from '@App/Structures';

interface IProps {
    status : number,
    id : number,
    onChange ?: Function,
}
interface IState {}

@observer
export class FiltersForm extends React.Component<IProps, IState> {

    private readonly form : FiltersFormStore = new FiltersFormStore();

    public componentDidMount() : void {
        this.form.fill(this.props);
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(
            this.props.status != prevProps.status
        ) this.form.fill(this.props);
    }

    public render() : React.ReactNode {
        return (
            <Form store={this.form} unsafe={true} onSubmit={() => this.onSubmit()}>
                <div className="form-group">
                    <label>Status</label>
                    <SelectField store={this.form.fields.status} size="sm">
                        <option value="">Available</option>
                        <option value="1">Pending</option>
                        <option value="2">Deleted</option>
                    </SelectField>
                </div>
                <div className="btn-group btn-group-sm w-100">
                    <button type="submit" className="btn btn-primary">
                        <i className="fa fa-check" /> Apply
                    </button>
                    <button type="button" className="btn btn-secondary" onClick={() => this.onClear()}>
                        <i className="fa fa-ban" /> Clear
                    </button>
                </div>
            </Form>
        );
    }

    private onSubmit() : void {
        const filters = this.form.getValues();
        const query = { ...Location.params };
        for(const key in filters) {
            if(filters[key] === null || (key == 'port' && !filters[key])) {
                delete query[key];
            } else query[key] = filters[key];
        }
        query.page = '1';
        const newQuery = Location.buildQuery(query);
        if(this.props.onChange) this.props.onChange(query);
        App.redirect(`/companies/${this.props.id}/devices${newQuery ? `?${newQuery}` : ''}`);
    }

    private onClear() : void {
        const filters = this.form.getValues();
        const query = { ...Location.params };
        for(const key in filters) delete query[key];
        query.page = '1';
        const newQuery = Location.buildQuery(query);
        if(this.props.onChange) this.props.onChange(query);
        App.redirect(`/companies/${this.props.id}/devices${newQuery ? `?${newQuery}` : ''}`);
    }

}