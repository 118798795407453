export interface IMember {
    value ?: string,
    isValid : boolean,
    isSaved : boolean,
    validate() : IMember,
    restore() : IMember,
    save() : IMember,
    fill(values : any) : IMember,
    clear() : IMember,
    getValues() : any,
}