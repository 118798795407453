import { makeObservable, observable, computed, action } from 'mobx';
import { Form, IMember } from '@Framework/Library/Form';

type TAddItem<TItem> = () => TItem;

interface IListItem<TItem> {
    member : TItem,
    isSaved ?: boolean,
    isDeleted ?: boolean,
    order ?: number,
}

export class List<TItem extends IMember = any, TValues = any> implements IMember {

    protected readonly _id : number = Form.UniqueID;
    protected readonly _addItem : TAddItem<TItem>;
    @observable protected _items : IListItem<TItem>[] = [];

    public get id() : number {
        return this._id;
    }

    public get items() : TItem[] {
        const items = [];
        for(let i = 0; i < this._items.length; i++) {
            if(this._items[i].isDeleted) continue;
            items.push(this._items[i].member);
        }
        return items;
    }

    @computed public get isValid() : boolean {
        for(let i = 0; i < this._items.length; i++) {
            if(this._items[i].isDeleted) continue;
            if(!this._items[i].member.isValid) return false;
        }
        return true;
    }

    @computed public get isSaved() : boolean {
        for(let i = 0; i < this._items.length; i++) {
            if(!this._items[i].isSaved) return false;
            if(this._items[i].isDeleted) return false;
            if(this._items[i].order != i) return false;
            if(!this._items[i].member.isSaved) return false;
        }
        return true;
    }

    constructor(addItem : TAddItem<TItem> = null, isSuper : boolean = false) {
        this._addItem = addItem;
        if(!isSuper) makeObservable(this);
    }

    public validate() : List<TItem, TValues> {
        for(let i = 0; i < this._items.length; i++) {
            if(this._items[i].isDeleted) continue;
            this._items[i].member.validate();
        }
        return this;
    }

    @action public restore() : List<TItem, TValues> {
        const items = [];
        for(let i = 0; i < this._items.length; i++) {
            const item = this._items[i];
            if(!item.isSaved) continue;
            item.isDeleted = false;
            item.member.restore();
            items.push(item);
        }
        items.sort((a, b) => {
            if(a.order > b.order) return 1;
            if(a.order < b.order) return -1;
            return 0;
        });
        this._items = items;
        return this;
    }

    @action public save() : List<TItem, TValues> {
        const items = [];
        let order = -1;
        for(let i = 0; i < this._items.length; i++) {
            const item = this._items[i];
            if(item.isDeleted) continue;
            item.isSaved = true;
            item.order = ++order;
            item.member.save();
            items.push(item);
        }
        this._items = items;
        return this;
    }

    @action public prepend(values : TValues = null) : List<TItem, TValues> {
        if(this._addItem) this._items.unshift({ member: this._addItem() });
        if(values) this._items[0].member.fill(values);
        return this;
    }

    @action public append(values : TValues = null) : List<TItem, TValues> {
        if(this._addItem) this._items.push({ member: this._addItem() });
        if(values) this._items[this._items.length - 1].member.fill(values);
        return this;
    }

    @action public insertAt(index : number, values : TValues = null) : List<TItem, TValues> {
        let realIndex = index;
        for(let i = 0; i <= realIndex; i++) {
            if(this._items[i].isDeleted) realIndex++;
        }
        this._items.splice(realIndex, 0, { member: this._addItem() });
        if(values) this._items[realIndex].member.fill(values);
        return this;
    }

    @action public swap(a : number, b : number) : List<TItem, TValues> {
        if(a != b) {
            let realA = a;
            let realB = b;
            for(let i = 0; i <= realA; i++) {
                if(this._items[i].isDeleted) realA++;
            }
            for(let i = 0; i <= realB; i++) {
                if(this._items[i].isDeleted) realB++;
            }
            [ this._items[realA], this._items[realB] ] = [ this._items[realB], this._items[realA] ];
        }
        return this;
    }

    @action public remove(start : number, count : number = 1) : List<TItem, TValues> {
        let realStart = start;
        for(let i = 0; i <= realStart; i++) {
            if(this._items[i].isDeleted) realStart++;
        }
        const items = [];
        let deleted = 0;
        for(let i = 0; i < this._items.length; i++) {
            const item = this._items[i];
            if(i < realStart || item.isDeleted || deleted >= count) {
                items.push(item);
                continue;
            } else if(item.isSaved) {
                item.isDeleted = true;
                items.push(item);
            }
            deleted++;
        }
        this._items = items;
        return this;
    }

    @action public fill(values : TValues[]) : List<TItem, TValues> {
        const items = [];
        for(let i = 0; i < values.length; i++) {
            items.push({ member: this._addItem() });
            items[i].member.fill(values[i]);
        }
        for(let i = 0; i < this._items.length; i++) {
            const item = this._items[i];
            if(!item.isSaved) continue;
            item.isDeleted = true;
            items.push(item);
        }
        this._items = items;
        return this;
    }

    @action public clear() : List<TItem, TValues> {
        return this;
    }

    public getValues() : TValues[] {
        const values = [];
        for(let i = 0; i < this._items.length; i++) {
            const item = this._items[i];
            if(item.isDeleted) continue;
            values.push(item.member.getValues());
        }
        return values;
    }

}