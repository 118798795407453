import React from 'react';
import { Session, UserContext } from '@Framework/Core/Session';
import { Loading } from '@Framework/Component/Loading';
import { App } from '@Framework/Core/App';
import '@App/Layout/MainLayout/UserBar/UserBar.less';
import { OnClickOutside } from '@Framework/Factory';
import { AuthModel } from '@App/Service/Auth/Model';
import { Location } from '@Framework/Core/Location';
import { Model } from '@Framework/Library/Gateway';

interface IProps {}
interface IState {
    openProfile : boolean,
    updating : boolean,
}

export class UserBar extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            openProfile: false,
            updating: false,
        };
    }

    public render() : React.ReactNode {
        return (
            <UserContext.Consumer>
                {user => (
                    <div id="user-bar">
                        <div className="row">
                            <div className="col px-0" />
                            <div className="col-auto">
                                <div id="user-profile" onClick={() => this.setState(state => ({ openProfile: !state.openProfile }))}>
                                    <div>
                                        {user.id > 0
                                            ? (
                                                <div className="text-end">
                                                    <div className="name">{user.name}</div>
                                                    <div className="role">{user.role.name}</div>
                                                </div>
                                            )
                                            : <Loading small={true} light={true} />
                                        }
                                    </div>
                                    <div>
                                        <div className="avatar" />
                                    </div>
                                    {this.state.openProfile &&
                                        <OnClickOutside topLevelId="user-profile" onClick={() => this.setState({ openProfile: false })}>
                                            <ul className="dropdown-menu show">
                                                <li className="dropdown-item" onClick={UserBar.logout}>Logout</li>
                                                {MODE !== 'production' && <li className="dropdown-item" onClick={UserBar.expire}>Expire (test)</li>}
                                            </ul>
                                        </OnClickOutside>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </UserContext.Consumer>
        );
    }

    private static logout() : void {
        (async () => {
            await AuthModel.logout();
            Session.destroy();
            App.redirect('/login');
        })();
    }

    private static expire() : void {
        (async () => {
            await AuthModel.expire();
            App.route();
        })();
    }

    private static getCompanyName(source : string) : string {
        if(source.length > 12) {
            return `${source.slice(0, 9)}...`;
        } else {
            return source;
        }
    }

}