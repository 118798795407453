export interface ILanguage {
    days : {
        short : string[],
        full : string[],
    },
    months : {
        short : string[],
        full : string[],
    },
    rules : {
        date : {
            invalidMessage : () => string,
            minMessage : (min : number) => string,
            maxMessage : (max : number) => string,
            lessMessage : (less : number) => string,
            greaterMessage : (greater : number) => string,
            inPastMessage : () => string,
            inFutureMessage : () => string,
        },
        email : {
            invalidMessage : () => string,
        },
        length : {
            equalMessage : (equal : number) => string,
            minMessage : (min : number) => string,
            maxMessage : (max : number) => string,
        },
        matches : {
            notMatchMessage : () => string,
        },
        number : {
            invalidMessage : () => string,
            noNegativeMessage : () => string,
            noDecimalMessage : () => string,
            minMessage : (min : number) => string,
            maxMessage : (max : number) => string,
            lessMessage : (less : number) => string,
            greaterMessage : (greater : number) => string,
        },
        pattern : {
            invalidMessage : () => string,
        },
        required : {
            requiredMessage : () => string,
        },
        url : {
            invalidMessage : () => string,
        },
    },
}