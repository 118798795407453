import React from 'react';
import { MainLayout } from '@App/Layout/MainLayout/MainLayout';
import { Loading } from '@Framework/Component/Loading';

interface IProps {}
interface IState {}

export class LoadingScreen extends React.Component<IProps, IState> {

    public render() : React.ReactNode {
        return (
            <MainLayout service={'system-page'}>
                <div className="container-fluid my-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 offset-md-3 text-center">
                            <Loading />
                        </div>
                    </div>
                </div>
            </MainLayout>
        );
    }

}