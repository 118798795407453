import React from 'react';
import { EModalSize, Link, Modal } from '@Framework/Factory';
import { Loading } from '@Framework/Component/Loading';
import { Pagination } from '@Framework/Component/Pagination';
import { DefaultStruct } from '@App/Structures';
import { App } from '@Framework/Core/App';
import { SortLink } from '@Framework/Component/SortLink';
import moment from 'moment';
import { Details, ETab } from '@App/Service/Companies/Details/Details';
import { DataSourcesModel } from '@App/Service/Companies/Details/DataSources/Model/DataSourcesModel';
import { DataSourcesModelStruct } from '@App/Service/Companies/Details/DataSources/Model/DataSourcesModel.struct';
import { FiltersForm } from '@App/Service/Companies/Details/Devices/List/FiltersForm';

enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}
interface IProps {
    companyId : number,
    page ?: number,
    limit ?: number,
    orderBy ?: string,
    sort ?: string,
    status ?: number,
}
interface IState {
    status : EStatus,
    data : DataSourcesModelStruct.IDataSourcesShort[],
    pagination : DefaultStruct.IPagination,
    createOpen : boolean,
    createType : string,
}

export class List extends React.Component<IProps, IState> {

    private isMount : boolean = false;

    constructor(props) {
        super(props);
        this.state = {
            status: EStatus.Unloaded,
            data: [],
            pagination: null,
            createOpen: false,
            createType: '1',
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.loadData();
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(
            this.props.page != prevProps.page ||
            this.props.limit != prevProps.limit ||
            this.props.orderBy != prevProps.orderBy ||
            this.props.sort != prevProps.sort ||
            this.props.status != prevProps.status
        ) this.loadData();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {

        const typeDataSource = function (typeData : string) : string {
            if(typeData == '1') return 'MQTT';
            if(typeData == '2') return 'Thingspeak';
            if(typeData == '3') return 'Tinycontrol';
            return 'secondary';
        };

        return (
            <Details
                id={this.props.companyId}
                tab={ETab.DataSources}
                title={<h3 className="m-0">Data Sources</h3>}
                controls={
                    <>
                        <button type="button" className="btn btn-sm btn-primary" onClick={() => this.setState({ createOpen: true })} style={{ margin: '-10px 0' }}>
                            <i className="fa fa-plus"/> Add Data Sources
                        </button>
                        {/*<Link url={`companies/${this.props.companyId}/data-sources/create`}><a className="btn btn-sm btn-primary"><i className="fa fa-plus"/> Add*/}
                        {/*    Data Source</a></Link>*/}
                    </>
                }
                breadcrumb={
                    <>
                        <li className="breadcrumb-item">Data Sources</li>
                    </>
                }
            >
                {this.state.status != EStatus.Loaded &&
                    <div className="card-body">
                        {this.state.status == EStatus.Loading && <Loading />}
                        {this.state.status == EStatus.Failed && <div className="alert alert-danger"><i className="fa fa-exclamation-triangle" /> Loading error...</div>}
                    </div>
                }
                {this.state.status == EStatus.Loaded &&
                    <>
                        <div className="card-body p-0 d-none d-md-block">
                            <div className="table-responsive">
                                <table className="table table-hover table-striped table-wide m-0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Active/not active</th>
                                            <th>Type</th>
                                            <th>Last used</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.data.map(item => (
                                            <tr key={item.id}>
                                                <td onClick={() => this.openDetails(item.id)}>
                                                    {item.name}
                                                </td>
                                                <td onClick={() => this.openDetails(item.id)}>
                                                    <span className={`badge bg-${item.active ? 'success' : 'danger'}`}>{item.active ? 'Active' : 'Not active'}</span>
                                                </td>
                                                <td onClick={() => this.openDetails(item.id)}>
                                                    <span>{typeDataSource(String(item.type))}</span>
                                                </td>
                                                <td onClick={() => this.openDetails(item.id)}>
                                                    {moment.unix(item.tmLast)
                                                        .fromNow()}
                                                </td>
                                                <td className="text-end">
                                                    <Link url={`companies/${this.props.companyId}/data-sources/${item.id}`}>
                                                        <a className="btn btn-xs btn-primary">
                                                            <i className="fa fa-eye"/> Details
                                                        </a>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-footer">
                            <Pagination limit={this.props.limit} {...this.state.pagination} />
                        </div>
                    </>
                }
                {this.state.createOpen &&
                    <Modal
                        size={EModalSize.SM}
                        onClickOutside={() => this.setState({ createOpen: false })}
                    >
                        <div className="modal-header">
                            <h5>Select type</h5>
                            <button type="button" className="btn btn-light btn-sm" onClick={() => this.setState({ createOpen: false })}>
                                <i className="fa fa-times"/>
                            </button>
                        </div>
                        <div className="modal-body">
                            <select name="typeData" className="form-select-sm" onChange={e => this.setState({ createType: e.target.value })}>
                                <option value="1">MQTT</option>
                                <option value="2">Thingspeak</option>
                                <option value="3">Tinycontrol</option>
                            </select>
                        </div>
                        <div className="modal-footer">
                            <div className="col">
                                <button type="button" className="btn btn-light btn-sm" onClick={() => this.setState({ createOpen: false })}>
                                    Cancel
                                </button>
                            </div>
                            <div className="col-auto"></div>
                            <div className="col-auto">
                                <Link url={`companies/${this.props.companyId}/data-sources/create/${this.state.createType}`}><a className="btn btn-sm btn-primary">Create</a></Link>
                            </div>
                        </div>
                    </Modal>
                }
            </Details>
        );
    }

    private loadData() : void {
        (async () => {
            this.setState({ status: EStatus.Loading });
            const res = await DataSourcesModel.list(this.props);
            if(!this.isMount) return;
            if(res.success && res.payload) {
                this.setState({
                    status: EStatus.Loaded,
                    data: res.payload.data,
                    pagination: res.payload.pagination,
                });
            } else this.setState({ status: EStatus.Failed });
        })();
    }

    private openDetails(id : string) : void {
        App.redirect(`companies/${this.props.companyId}/data-sources/${id}`);
    }

}