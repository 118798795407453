import { DefaultStruct } from '@App/Structures';
import { UserModelStruct } from '@App/Service/Users/Model/UserModel.struct';

export namespace UserModelRes {

    export namespace List {
        export interface IPayload {
            data : UserModelStruct.IUserShort[],
            pagination : DefaultStruct.IPagination,
        }
        export interface IPayloadSource {
            data : UserModelStruct.IUserShortSource[],
            status : number,
            paginator : DefaultStruct.IPaginationSource,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            return {
                data: source.data.map(item => ({
                    companies: item.companies.map(company => ({
                        id: Number(company.id),
                        role: Number(company.role),
                        name: company.name,
                    })),
                    email: item.email || '',
                    id: Number(item.id) || 0,
                    name: item.name,
                    superuser: item.superuser == '1',
                    role: {
                        id: Number(item.role),
                        name: item.role == '1'
                            ? 'SuperAdmin'
                            : item.role == '2'
                                ? 'Admin'
                                : 'User',
                    },
                    isActive: item.status == '1',
                    tm: Number(item.tm),
                })),
                pagination: {
                    limit: Number(source.paginator.limit),
                    page: Number(source.paginator.page),
                    pages: Number(source.paginator.pages),
                    total: Number(source.paginator.total),
                },
            };
        }
    }

    export namespace Search {
        export interface IPayload {
            data : UserModelStruct.IUserSearch[],
            // pagination : DefaultStruct.IPagination,
        }
        export interface IPayloadSource {
            data : UserModelStruct.IUserSearchSource[],
            status : number,
            // paginator : DefaultStruct.IPaginationSource,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            return {
                data: source.data.map(item => ({
                    id: Number(item.id) || 0,
                    name: item.name,
                    email: item.email || '',
                })),
                // pagination: {
                //     limit: Number(source.paginator.limit),
                //     page: Number(source.paginator.page),
                //     pages: Number(source.paginator.pages),
                //     total: Number(source.paginator.total),
                // },
            };
        }
    }

    export namespace Details {
        export interface IPayload {
            data : UserModelStruct.IUser,
        }
        export interface IPayloadSource {
            data : UserModelStruct.IUserSource,
            status : number,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            return {
                data: {
                    alertDaily: source.data.alert_daily == '1',
                    superuser: source.data.superuser == '1',
                    companies: source.data.companies.map(item => ({
                        id: Number(item.id),
                        role: Number(item.role),
                        name: item.name,
                    })),
                    email: source.data.email || '',
                    id: Number(source.data.id) || 0,
                    phone: source.data.phone,
                    name: source.data.fullname,
                    resetHashTm: Number(source.data.resethash_tm),
                    role: {
                        id: Number(source.data.role),
                        name: source.data.role == '1'
                            ? 'SuperAdmin'
                            : source.data.role == '2'
                                ? 'Admin'
                                : 'User',
                    },
                    isActive: source.data.status == '1',
                    tm: Number(source.data.tm),
                    tmA: Number(source.data.tm_a),
                },
            };
        }
    }

}