import React from 'react';
import { MainLayout } from '@App/Layout';
import { Link } from '@Framework/Factory';
import { Loading } from '@Framework/Component/Loading';
import { observer } from 'mobx-react';
import { LogsModelStruct } from '@App/Service/Logs/Model/LogsModel.struct';
import { LogsModel } from '@App/Service/Logs/Model/LogsModel';
import { CompaniesModel, CompaniesModelStruct } from '@App/Service/Companies/Model';
import { UserModel, UserModelStruct } from '@App/Service/Users/Model';
import moment from 'moment';
import { Format } from '@App/Config/Format';

enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}
enum EAction {
    Idle,
    Saving,
    DeleteConfirmation,
    Deleting,
}
interface IProps {
    id : number,
}
interface IState {
    status : EStatus,
    action : EAction,
    data : LogsModelStruct.ILogsShort,
    dataCompany : CompaniesModelStruct.ICompanyShort,
    dataUser : UserModelStruct.IUserShort,
}

@observer
export class Details extends React.Component<IProps, IState> {

    private isMount : boolean = false;

    constructor(props) {
        super(props);
        this.state = {
            status: EStatus.Unloaded,
            action: EAction.Idle,
            data: null,
            dataCompany: null,
            dataUser: null,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.loadData();
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(this.props.id != prevProps.id) this.loadData();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        return (
            <MainLayout service="logs">
                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="col">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link url="/"><a><i className="fa fa-home" /> Home</a></Link></li>
                                    <li className="breadcrumb-item"><Link url="/logs"><a>Logs</a></Link></li>
                                    <li className="breadcrumb-item active">
                                        {this.state.status == EStatus.Loaded
                                            ? this.state.data.id
                                            : <><span className="spinner-border spinner-border-sm text-primary" /> Details</>
                                        }
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="col">
                            <div className="card">
                                <div className="card-body">
                                    {this.state.status == EStatus.Loading && <Loading label="Loading chart..." />}
                                    {this.state.status == EStatus.Failed && <div className="alert alert-danger"><i className="fa fa-exclamation-triangle" /> Stats loading error</div>}
                                    {this.state.status == EStatus.Loaded &&
                                        <>
                                            {this.state.dataCompany &&
                                                <><strong>Company name: </strong> {this.state.dataCompany.name}<br /></>
                                            }
                                            {this.state.dataUser &&
                                                <><strong>User name: </strong>{this.state.dataUser.name}<br /></>
                                            }

                                            <strong>Updated: </strong>{moment.unix(this.state.data.tm).format(`${Format.Date.YN} ${Format.Time.M}`)}<br />
                                            <strong>Ip address: </strong>{this.state.data.ip}<br />
                                            <strong>Type: </strong>{this.state.data.type.name}<br />
                                            <strong>Severity: </strong>{this.state.data.severity}<br />
                                            <strong>Text: </strong>{this.state.data.txt}<br />
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MainLayout>
        );
    }

    private loadData() : void {
        (async () => {
            this.setState({ status: EStatus.Loading });
            const res = await LogsModel.details(this.props.id);
            const res2 = ((res.payload || {}).data || {}).companyId
                ? await CompaniesModel.details(res.payload.data.companyId)
                : null;
            const res3 = ((res.payload || {}).data || {}).userId
                ? await UserModel.details(res.payload.data.userId)
                : null;
            if(!this.isMount) return;
            if(
                (!res.success || !res.payload) ||
                (res2 && (!res2.success || !res2.payload)) ||
                (res3 && (!res3.success || !res3.payload))
            ) {
                this.setState({ status: EStatus.Failed });
            } else {
                this.setState({
                    status: EStatus.Loaded,
                    data: res.payload.data,
                    dataCompany: res2 ? res2.payload.data : null,
                    dataUser: res3 ? res3.payload.data : null,
                });
            }
        })();
    }

}