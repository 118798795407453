import { ERequestMethod, IModelOptions, Model, Reply } from '@Framework/Library/Gateway';
import { CompaniesModelReq, CompaniesModelRes } from '@App/Service/Companies/Model';
import { ERemoteResource } from '@App';
import { DefaultStruct } from '@App/Structures';
import { UserModelReq, UserModelRes } from '@App/Service/Users/Model';

export abstract class CompaniesModel extends Model {

    public static list(
        query : DefaultStruct.IQuery = {},
        options : IModelOptions = {},
    ) : Promise<Reply<CompaniesModelRes.List.IPayload>> {
        return this.request<DefaultStruct.IQuery, {}, CompaniesModelRes.List.IPayload>({
            resource: ERemoteResource.Auth,
            path: 'companies',
            query: CompaniesModelReq.List.getQuery(query),
            responseHandler: CompaniesModelRes.List.getPayload,
        }, options);
    }

    public static create(
        payload : CompaniesModelReq.Create.IPayload,
        options : IModelOptions = {},
    ) : Promise<Reply<any>> {
        return this.request<{}, CompaniesModelReq.Create.IPayloadOutput, any>({
            resource: ERemoteResource.Auth,
            path: 'companies/create',
            method: ERequestMethod.POST,
            payload: CompaniesModelReq.Create.getOutput(payload),
        }, options);
    }

    public static details(
        id : number,
        options : IModelOptions = {},
    ) : Promise<Reply<CompaniesModelRes.Details.IPayload>> {
        return this.request<{}, {}, CompaniesModelRes.Details.IPayload>({
            resource: ERemoteResource.Auth,
            path: `companies/${id}`,
            responseHandler: CompaniesModelRes.Details.getPayload,
        }, options);
    }

    public static update(
        id : number,
        payload : CompaniesModelReq.Details.IPayload,
        options : IModelOptions = {},
    ) : Promise<Reply<CompaniesModelRes.Details.IPayload>> {
        return this.request<{}, CompaniesModelReq.Details.IPayloadOutput, CompaniesModelRes.Details.IPayload>({
            resource: ERemoteResource.Auth,
            path: `companies/${id}`,
            method: ERequestMethod.POST,
            payload: CompaniesModelReq.Details.getOutput(payload),
        }, options);
    }

    public static delete(
        id : number,
        options : IModelOptions = {},
    ) : Promise<Reply<any>> {
        return this.request<{}, {}, {}>({
            resource: ERemoteResource.Auth,
            path: `companies/${id}`,
            method: ERequestMethod.DELETE,
        }, options);
    }

    public static search(
        query : DefaultStruct.IQuery = {},
        options : IModelOptions = {},
    ) : Promise<Reply<CompaniesModelRes.Search.IPayload>> {
        return this.request<DefaultStruct.IQuery, {}, CompaniesModelRes.Search.IPayload>({
            resource: ERemoteResource.Auth,
            path: 'search/companies',
            query: CompaniesModelReq.List.getQuery(query),
            responseHandler: CompaniesModelRes.Search.getPayload,
        }, options);
    }

}