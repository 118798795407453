import { DefaultStruct } from '@App/Structures';

export namespace DevicesModelReq {

    export namespace List {
        export interface IQuery extends DefaultStruct.IQuery {
            status ?: number,
        }
        export interface IQueryOutput extends DefaultStruct.IQueryOutput {
            status ?: number,
            qstr ?: string,
        }
        export function getQuery(query : IQuery) : IQueryOutput {
            const output : IQueryOutput = {};
            if(query.page) output.page = query.page;
            if(query.qstr) output.qstr = query.qstr;
            if(query.limit) output.limit = query.limit;
            if(query.orderBy) output.orderBy = query.orderBy;
            if(query.sort) output.sort = query.sort;
            if(query.status) output.status = query.status;
            return output;
        }
    }

    export namespace Create {
        export interface IPayload {
            uid : string,
            name : string,
            notes : string,
            active : boolean,
            priority : number,
            groupId : number,
            locationId : number,
        }
        export interface IPayloadOutput {
            uid : string,
            name : string,
            notes : string,
            active : string,
            priority : string,
            group_id ?: string,
            location_id ?: string,
        }
        export function getOutput(payload : IPayload) : IPayloadOutput {
            const data : IPayloadOutput = {
                uid: payload.uid || '',
                name: payload.name || '',
                notes: payload.notes || '',
                active: payload.active ? '1' : '0',
                priority: String(payload.priority) || '3',
                group_id: String(payload.groupId) || null,
                location_id: String(payload.locationId) || null,
            };
            return data;
        }
    }

    export namespace Details {
        export interface IPayload {
            uid : string,
            name : string,
            notes : string,
            active : boolean,
            priority : number,
            groupId : number,
            locationId : number,
        }
        export interface IPayloadOutput {
            uid : string,
            name : string,
            notes : string,
            active : string,
            priority : string,
            group_id ?: string,
            location_id ?: string,
        }
        export function getOutput(payload : IPayload) : IPayloadOutput {
            const data : IPayloadOutput = {
                uid: payload.uid || '',
                name: payload.name || '',
                notes: payload.notes || '',
                active: payload.active ? '1' : '0',
                priority: String(payload.priority) || '3',
                group_id: String(payload.groupId) || null,
                location_id: String(payload.locationId) || null,
            };
            return data;
        }
    }

}