import React from 'react';
import { Map } from 'subway-router';
import { TLoadService } from '@Framework/Core/App';
import { LoggedIn } from '@App/Middleware';
import { Location } from '@Framework/Core/Location';
import { List, Details, Create } from '@App/Service/Users';

export function register(map : Map, loadService : TLoadService) {
    map.group('/users', group => {
        group.route('/', res => {
            loadService(<List
                page={Number(res.key('page')) || 1}
                limit={Number(res.key('limit')) || 50}
                orderBy={res.key('orderBy') || undefined}
                sort={res.key('sort') || undefined}
                companyId={Number(Location.param('companyId')) || null}
            />);
        });
        group.route('/{id:i}', res => {
            loadService(<Details id={Number(res.segment(2))} />);
        });
        group.route('/create', () => {
            loadService(<Create />);
        });
    }).name('users').middleware(new LoggedIn(loadService));
}