import React from 'react';
import { Details, ETab } from '@App/Service/Companies/Details/Details';
import { EModalSize, Modal } from '@Framework/Factory';
import { UserModel, UserModelStruct } from '@App/Service/Users/Model';
import { SettingsFormStore } from '@App/Service/Companies/Details/Settings/SettingsFormStore';
import { Form } from '@Framework/Library/Form';
import { Loading } from '@Framework/Component/Loading';
import { InputField, SearchField, SelectField, TextField } from '@Framework/Component/FormField';
import { observer } from 'mobx-react';
import { Session } from '@Framework/Core/Session';
import { App } from '@Framework/Core/App';
import { ENotificationType } from '@Framework/Component/Notification';
import { CompaniesModel, CompaniesModelStruct } from '@App/Service/Companies/Model';

enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}
enum EAction {
    Idle,
    Saving,
    DeleteConfirmation,
    Deleting,
}
interface IProps {
    id : number,
}
interface IState {
    status : EStatus,
    action : EAction,
    data : CompaniesModelStruct.ICompany,
}

@observer
export class Settings extends React.Component<IProps, IState> {

    private isMount : boolean = false;
    private form : SettingsFormStore = new SettingsFormStore();

    constructor(props) {
        super(props);
        this.state = {
            status: EStatus.Unloaded,
            action: EAction.Idle,
            data: null,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.loadData();
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(this.props.id != prevProps.id) this.loadData();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        return (
            <Details
                id={this.props.id}
                tab={ETab.Settings}
                title={<h3 className="m-0">Company Settings</h3>}
                breadcrumb={
                    <>
                        <li className="breadcrumb-item">Company Settings</li>
                    </>
                }
            >
                <Form store={this.form} onSubmit={() => this.onSubmit()}>
                    <div className="card-body">
                        {this.state.status == EStatus.Loading && <Loading label="Loading company..." />}
                        {this.state.status == EStatus.Failed && <div className="alert alert-danger"><i className="fa fa-exclamation-triangle" /> Stats loading error</div>}
                        {this.state.status == EStatus.Loaded &&
                            <>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <label>Name <span className="text-danger">*</span></label>
                                                <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.name} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Address</label>
                                                <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.address} />
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <label>Owner User</label>
                                                <SearchField
                                                    id={'user-search'}
                                                    onSearch={async (keywords, page) => {
                                                        const res = await UserModel.search({
                                                            qstr: keywords,
                                                            page,
                                                            limit: 999,
                                                        });
                                                        return {
                                                            keywords,
                                                            options: res.success && res.payload
                                                                ? res.payload.data.map(item => ({
                                                                    id: item.id,
                                                                    name: item.name,
                                                                }))
                                                                : [],
                                                            pages: 1,
                                                            total: 1,
                                                        };
                                                    }}
                                                    store={this.form.fields.ownerId}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {Session.user.role.id == 1 &&
                                        <div className="row">
                                            <div className="col-sm">
                                                <div className="form-group">
                                                    <label>Notes</label>
                                                    <TextField disabled={this.state.action != EAction.Idle} store={this.form.fields.notes} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <label>Instance</label>
                                                <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.instance} placeholder="Auto generated" />
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <label>Instance port</label>
                                                <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.instancePort} placeholder="443" />
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <label>Instance 2</label>
                                                <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.instanceTwo} placeholder="not used" />
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <label>Instance 2 port</label>
                                                <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.instancePortTwo} placeholder="443" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    {this.state.status == EStatus.Loaded &&
                        <div className="card-footer">
                            <div className="row">
                                <div className="col-auto">
                                    <button
                                        type="button"
                                        disabled={this.state.action != EAction.Idle}
                                        className="btn btn-danger"
                                        onClick={() => this.setState({
                                            action: EAction.DeleteConfirmation,
                                        })}
                                    >
                                        {this.state.action == EAction.Deleting
                                            ? <span className="spinner-border spinner-border-sm" />
                                            : <i className="fa fa-trash" />
                                        } Delete
                                    </button>
                                </div>
                                <div className="col"/>
                                <div className="col-auto">
                                    <button type="submit" disabled={this.state.action != EAction.Idle} className="btn btn-primary">
                                        {this.state.action == EAction.Saving
                                            ? <span className="spinner-border spinner-border-sm" />
                                            : <i className="fa fa-check" />
                                        } Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </Form>
                {this.state.action == EAction.DeleteConfirmation &&
                    <Modal
                        size={EModalSize.SM}
                        onClickOutside={() => this.setState({ action: EAction.Idle })}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">Delete</h5>
                            <button type="button" className="btn btn-light btn-sm" onClick={() => this.setState({ action: EAction.Idle })}>
                                <i className="fa fa-times"/>
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => this.setState({ action: EAction.Idle })}><i className="fa fa-ban" /> Cancel</button>
                            <button type="button" className="btn btn-danger" onClick={() => this.onDelete()}><i className="fa fa-trash" /> Delete</button>
                        </div>
                    </Modal>
                }
            </Details>
        );
    }

    private loadData() : void {
        (async () => {
            this.setState({ status: EStatus.Loading });
            const res = await CompaniesModel.details(this.props.id);
            if(!this.isMount) return;
            if(res.success && res.payload) {
                this.setState({
                    status: EStatus.Loaded,
                    data: res.payload.data,
                });
                this.form.fill({
                    ...res.payload.data,
                }).save();
                this.form.fields.ownerId.meta.name = res.payload.data.ownerName;
            } else this.setState({ status: EStatus.Failed });
        })();
    }

    private onSubmit() : void {
        this.form.validate();
        if(!this.form.isValid) return;
        this.setState({ action: EAction.Saving });
        (async () => {
            const res = await CompaniesModel.update(this.props.id, this.form.getValues());
            if(!this.isMount) return;
            if(res.success) {
                this.form.save();
                App.notification({
                    type: ENotificationType.Success,
                    title: 'Success',
                    message: 'Successfully saved.',
                });
            }
            this.setState({ action: EAction.Idle });
        })();
    }

    private onDelete() : void {
        this.setState({ action: EAction.Deleting });
        (async () => {
            const res = await CompaniesModel.delete(this.props.id);
            if(!this.isMount) return;
            if(res.success) {
                App.notification({
                    type: ENotificationType.Success,
                    title: 'Success',
                    message: 'Successfully deleted.',
                });
                App.redirect('/companies');
            }
            this.setState({ action: EAction.Idle });
        })();
    }

}