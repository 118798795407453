import React from 'react';
import { IMiddleware, Request, TOnLoad } from 'subway-router';
import { Session } from '@Framework/Core/Session';
import { App } from '@Framework/Core/App';
import { LoadingScreen } from '@App/SystemPage';
import { Model } from '@Framework/Library/Gateway';
import { CompaniesModel } from '@App/Service/Companies/Model';

export class CompanyInstance implements IMiddleware {

    public async onResolving(onLoad : TOnLoad, request : Request) : Promise<TOnLoad> {
        if(Session.isActive) {
            App.setView(<LoadingScreen />);
            const res = await CompaniesModel.details(Number(request.segment(2)));
            if(res.success) {
                Model.configure({
                    resources: [{
                        origins: [
                            MAIN_API_HOST
                                .replace('{instance}', String(res.payload.data.instance))
                                .replace('{port}', res.payload.data.instancePort != 443 ? `:${String(res.payload.data.instancePort)}` : ''),
                            ...res.payload.data.instanceTwo
                                ? [
                                    MAIN_API_HOST
                                        .replace('{instance}', String(res.payload.data.instanceTwo))
                                        .replace('{port}', res.payload.data.instancePortTwo != 443 ? `:${String(res.payload.data.instancePortTwo)}` : ''),
                                ]
                                : [],
                        ],
                        defaults: {
                            auth: true,
                            hybridStatusCode: true,
                        },
                    }, {
                        origins: AUTH_API_HOSTS,
                        defaults: {
                            auth: true,
                            hybridStatusCode: true,
                        },
                    }],
                });
            }
        }
        return onLoad;
    }

}