import { SensorsModelStruct } from '@App/Service/Companies/Details/Sensors/Model/SensorsModel.struct';
import { DefaultStruct } from '@App/Structures';
import { sensorIcons } from '@App/Assets/SensorIcons';

export namespace SensorsModelRes {

    export namespace List {
        export interface IPayload {
            data : SensorsModelStruct.ISensor[],
            groups : DefaultStruct.IOption[],
            locations : DefaultStruct.IOption[],
            ports : string[],
            pagination : DefaultStruct.IPagination,
        }
        interface IPayloadSource {
            data : SensorsModelStruct.ISensorSource[],
            groups : DefaultStruct.IOptionSource[],
            locations : DefaultStruct.IOptionSource[],
            ports : string[],
            paginator : DefaultStruct.IPaginationSource,
            status : number,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            return {
                data: source.data.map(item => ({
                    isActive: item.active == '1',
                    alertEmail: item.alert_email == '1',
                    alertEmailTime: Number(item.alert_email_time) || 0,
                    alertMax: item.alert_min !== null ? Number(item.alert_max) : null,
                    alertMin: item.alert_min !== null ? Number(item.alert_min) : null,
                    alertSMS: item.alert_sms == '1',
                    alertSMSTime: Number(item.alert_sms_time) || 0,
                    alerts: Number(item.alerts) || 0,
                    companyId: Number(item.company_id) || 0,
                    dataEnd: Number(item.data_end) || 0,
                    dataMinStep: Number(item.data_minstep) || 0,
                    dataHistStep: Number(item.data_histstep) || 0,
                    dataStart: Number(item.data_start) || 0,
                    isDeleted: item.deleted == '1',
                    deviceId: Number(item.device_id) || 0,
                    deviceName: item.device_name,
                    groupId: Number(item.group_id) || 0,
                    group: item.group_id
                        ? {
                            id: Number(item.group_id),
                            name: (source.groups.find(g => Number(g.id) == Number(item.group_id)) || {}).name || '',
                        }
                        : null,
                    idx: Number(item.idx) || 0,
                    locationId: Number(item.location_id) || 0,
                    location: item.location_id
                        ? {
                            id: Number(item.location_id),
                            name: (source.locations.find(l => Number(l.id) == Number(item.location_id)) || {}).name || '',
                        }
                        : null,
                    name: item.name || '',
                    notes: item.notes || '',
                    port: item.port || '',
                    priorityId: Number(item.priority) || 0,
                    priority: Number(item.priority)
                        ? SensorsModelStruct.sensorPriorities.find(p => p.id == Number(item.priority))
                        : null,
                    tm: Number(item.tm) || 0,
                    tmA: Number(item.tm_a) || 0,
                    value: Number(item.value) || 0,
                    valueVisual: item.value_visual || '',
                    valueCor: Number(item.value_cor) || 0,
                    valueTypeId: Number(item.value_type) || 0,
                    valueType: Number(item.value_type)
                        ? DefaultStruct.Sensors.getValueType(Number(item.value_type))
                        : null,
                    iconId: Number(item.icon_id) || 0,
                    icon: (!item.icon_id || item.icon_id == '0')
                        ? null
                        : { id: Number(item.icon_id) || 0, src: sensorIcons[`s${Number(item.icon_id)}`] },

                    valueConfigTypeId: Number(item.value_config_type_id),
                    valueConfigTemplateId: Number(item.value_config_template_id),
                    valueConfigName: item.value_config_name || '',
                    valueConfigMin: Number(item.value_config_min) || 0,
                    valueConfigMax: Number(item.value_config_max) || 0,
                    valueConfigExt: item.value_config_ext || '',
                    valueConfigThousands: item.value_config_thousands || '',
                    valueConfigFormat: item.value_config_format || '',
                    valueConfigFormula: item.value_config_formula || '',
                    valueConfigConvert: item.value_config_convert || '',
                })),
                groups: (source.groups || []).map(g => ({ id: Number(g.id), name: g.name || '' })),
                locations: (source.locations || []).map(l => ({ id: Number(l.id), name: l.name || '' })),
                ports: source.ports || [],
                pagination: {
                    limit: Number(source.paginator.limit) || 25,
                    page: Number(source.paginator.page) || 1,
                    pages: Number(source.paginator.pages) || 1,
                    total: Number(source.paginator.total) || 0,
                },
            };
        }
    }

    export namespace Details {
        export interface IPayload {
            data : SensorsModelStruct.ISensor,
        }
        interface IPayloadSource {
            data : SensorsModelStruct.ISensorSource,
            status : number,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            return {
                data: {
                    isActive: source.data.active == '1',
                    alertEmail: source.data.alert_email == '1',
                    alertEmailTime: Number(source.data.alert_email_time) || 0,
                    alertMax: source.data.alert_min !== null ? Number(source.data.alert_max) : null,
                    alertMin: source.data.alert_min !== null ? Number(source.data.alert_min) : null,
                    alertSMS: source.data.alert_sms == '1',
                    alertSMSTime: Number(source.data.alert_sms_time) || 0,
                    alerts: Number(source.data.alerts) || 0,
                    companyId: Number(source.data.company_id) || 0,
                    dataEnd: Number(source.data.data_end) || 0,
                    dataMinStep: Number(source.data.data_minstep) || 0,
                    dataHistStep: Number(source.data.data_histstep) || 0,
                    dataStart: Number(source.data.data_start) || 0,
                    isDeleted: source.data.deleted == '1',
                    deviceId: Number(source.data.device_id) || 0,
                    deviceName: source.data.device_name,
                    groupId: Number(source.data.group_id) || 0,
                    group: (source.data.group_id && source.data.group)
                        ? {
                            id: Number(source.data.group.id) || 0,
                            name: source.data.group.name || '',
                        }
                        : null,
                    idx: Number(source.data.idx) || 0,
                    locationId: Number(source.data.location_id) || 0,
                    location: (source.data.location_id && source.data.location)
                        ? {
                            id: Number(source.data.location.id) || 0,
                            name: source.data.location.name || '',
                        }
                        : null,
                    name: source.data.name || '',
                    notes: source.data.notes || '',
                    port: source.data.port || '',
                    priorityId: Number(source.data.priority) || 0,
                    priority: Number(source.data.priority)
                        ? SensorsModelStruct.sensorPriorities.find(p => p.id == Number(source.data.priority))
                        : null,
                    tm: Number(source.data.tm) || 0,
                    tmA: Number(source.data.tm_a) || 0,
                    value: Number(source.data.value) || 0,
                    valueVisual: source.data.value_visual || '',
                    valueCor: Number(source.data.value_cor) || 0,
                    valueTypeId: Number(source.data.value_type) || 0,
                    valueType: Number(source.data.value_type)
                        ? DefaultStruct.Sensors.getValueType(Number(source.data.value_type))
                        : null,
                    iconId: Number(source.data.icon_id) || 0,
                    icon: (!source.data.icon_id || source.data.icon_id == '0')
                        ? null
                        : { id: Number(source.data.icon_id) || 0, src: sensorIcons[`s${Number(source.data.icon_id)}`] },

                    valueConfigTypeId: Number(source.data.value_config_type_id),
                    valueConfigTemplateId: Number(source.data.value_config_template_id),
                    valueConfigName: source.data.value_config_name || '',
                    valueConfigMin: Number(source.data.value_config_min) || 0,
                    valueConfigMax: Number(source.data.value_config_max) || 0,
                    valueConfigExt: source.data.value_config_ext || '',
                    valueConfigThousands: source.data.value_config_thousands || '',
                    valueConfigFormat: source.data.value_config_format || '',
                    valueConfigFormula: source.data.value_config_formula || '',
                    valueConfigConvert: source.data.value_config_convert || '',
                },
            };
        }
    }

    export namespace Relations {
        export interface IPayload {
            data : DefaultStruct.IOption[],
        }
        interface IPayloadSource {
            data : {
                id : string,
                name : string,
                notes : string,
            }[],
            status : number,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            return {
                data: source.data.map(item => ({
                    id: Number(item.id) || 0,
                    name: item.name || '',
                })),
            };
        }
    }

}