import { DefaultStruct } from '@App/Structures';
import { ERangeType, RangeSelector } from '@App/Component/RangeSelector';

export namespace SetsModelReq {

    export namespace List {
        export interface IQuery extends DefaultStruct.IQuery {
            locationId ?: number,
            groupId ?: number,
            port ?: string,
            device ?: string,
            alerts ?: number,
            priority ?: number,
        }
        export interface IQueryOutput extends DefaultStruct.IQueryOutput {
            location_id ?: number,
            group_id ?: number,
            port_id ?: string,
            device_id ?: string,
            alerts ?: number,
            priority ?: number,
        }
        export function getQuery(query : IQuery) : IQueryOutput {
            const output : IQueryOutput = {};
            if(query.page) output.page = query.page;
            if(query.limit) output.limit = query.limit;
            if(query.orderBy) output.orderBy = query.orderBy;
            if(query.sort) output.sort = query.sort;
            if(query.locationId) output.location_id = query.locationId;
            if(query.groupId) output.group_id = query.groupId;
            if(query.port) output.port_id = query.port;
            if(query.device) output.device_id = query.device;
            if(query.alerts !== null) output.alerts = query.alerts;
            if(query.priority) output.priority = query.priority;
            return output;
        }
    }

    export namespace Create {
        export interface IPayload {
            name : string,
            iconId : number,
            sensors : number[],
        }
        export interface IPayloadOutput {
            name : string,
            icon_id : number,
            orderno : number,
            sensors : {
                sensor_id : number,
                orderno : number,
            }[],
        }
        export function getOutput(payload : IPayload) : IPayloadOutput {
            return {
                name: payload.name || '',
                icon_id: Number(payload.iconId) || 0,
                orderno: 0,
                sensors: (payload.sensors || []).map((item, index) => ({
                    sensor_id: item,
                    orderno: index + 1,
                })),
            };
        }
    }

    export namespace Update {
        export interface IPayload {
            name : string,
            iconId : number,
            orderno : number,
            sensors : number[],
        }
        export interface IPayloadOutput {
            name : string,
            icon_id : number,
            orderno : number,
            sensors : {
                sensor_id : number,
                orderno : number,
            }[],
        }
        export function getOutput(payload : IPayload) : IPayloadOutput {
            return {
                name: payload.name || '',
                icon_id: Number(payload.iconId) || 0,
                orderno: payload.orderno || 0,
                sensors: (payload.sensors || []).map((item, index) => ({
                    sensor_id: item,
                    orderno: index + 1,
                })),
            };
        }
    }
}