import { DefaultStruct } from '@App/Structures';

export namespace SensorsModelStruct {

    export const sensorPriorities : DefaultStruct.IOption[] = [
        { id: 3, name: 'Normal' },
        { id: 1, name: 'Highest' },
        { id: 2, name: 'High' },
        { id: 4, name: 'Low' },
        { id: 5, name: 'Lowest' },
    ];

    export interface ISensor {
        isActive : boolean,
        alertEmail : boolean,
        alertEmailTime : number,
        alertMax : number,
        alertMin : number,
        alertSMS : boolean,
        alertSMSTime : number,
        alerts : number,
        companyId : number,
        dataEnd : number,
        dataHistStep : number,
        dataMinStep : number,
        dataStart : number,
        isDeleted : boolean,
        deviceId : number,
        deviceName : string,
        groupId : number,
        group : DefaultStruct.IOption,
        idx : number,
        locationId : number,
        location : DefaultStruct.IOption,
        name : string,
        notes : string,
        port : string,
        priorityId : number,
        priority : DefaultStruct.IOption,
        tm : number,
        tmA : number,
        value : number,
        valueVisual : string,
        valueCor : number,
        valueTypeId : number,
        valueType : DefaultStruct.Sensors.IValueType,
        iconId : number,
        icon : {
            id : number,
            src : string,
        },

        valueConfigTypeId : number,
        valueConfigTemplateId : number,
        valueConfigName : string,
        valueConfigMin : number,
        valueConfigMax : number,
        valueConfigExt : string,
        valueConfigThousands : string,
        valueConfigFormat : string,
        valueConfigFormula : string,
        valueConfigConvert : string,
    }
    export interface ISensorSource {
        active : string,
        alert_email : string,
        alert_email_time : string,
        alert_max : string,
        alert_min : string,
        alert_sms : string,
        alert_sms_time : string,
        alerts : string,
        company_id : string,
        data_end : string,
        data_histstep : string,
        data_minstep : string,
        data_start : string,
        deleted : string,
        device_id : string,
        device_name : string,
        group_id : string,
        group ?: DefaultStruct.IOption,
        idx : string,
        location_id : string,
        location ?: DefaultStruct.IOption,
        name : string,
        notes : string,
        port : string,
        priority : string,
        tm : string,
        tm_a : string,
        value : string,
        value_visual : string,
        value_cor : string,
        value_type : string,
        icon_id : string,

        value_config_type_id : string,
        value_config_template_id : string,
        value_config_name : string,
        value_config_min : string,
        value_config_max : string,
        value_config_ext : string,
        value_config_thousands : string,
        value_config_format : string,
        value_config_formula : string,
        value_config_convert : string,
    }

}