import React from 'react';
import { Modal } from '@Framework/Factory';

interface IProps {
    onAccept : Function,
    onCancel : Function,
}
interface IState {}

export class DiscardModal extends React.Component<IProps, IState> {

    public render() : React.ReactElement {
        return (
            <Modal
                class="modal-discard"
                onClickOutside={this.props.onCancel}
            >
                <div className="modal-header">
                    <h5 className="modal-title">Discard changes</h5>
                    <button type="button" className="btn btn-light btn-sm" onClick={() => this.props.onCancel()}>
                        <i className="fa fa-times"/>
                    </button>
                </div>
                <div className="modal-body">
                    <p>You have unsaved changes.</p>
                    <p>It will be lost if continue.</p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => this.props.onCancel()}>Back to editing</button>
                    <button type="button" className="btn btn-primary" onClick={() => this.props.onAccept()}>Discard changes</button>
                </div>
            </Modal>
        );
    }

}