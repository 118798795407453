import React from 'react';
import { FormField, IFormFieldProps } from '@Framework/Component/FormField';

interface IProps extends IFormFieldProps {
    children ?: React.ReactNode,
}

export class SelectField extends FormField<IProps> {

    protected renderField() : React.ReactElement {
        return (
            <select
                disabled={!!this.props.disabled}
                placeholder={this.props.placeholder || undefined}
                className={`form-select${this.props.size ? ` form-select-${this.props.size}` : ''}${this.props.store.isValid ? '' : ' is-invalid'}`}
                value={this.props.store.value}
                onChange={e => this.props.store.value = e.target.value}
                autoComplete="off"
            >
                {this.props.children}
            </select>
        );
    }

}