import React from 'react';
import { observer } from 'mobx-react';
import { Form } from '@Framework/Library/Form';
import { FiltersFormStore } from '@App/Service/Online/List/FiltersFormStore';
import { SearchField, SelectField } from '@Framework/Component/FormField';
import { Location } from '@Framework/Core/Location';
import { App } from '@Framework/Core/App';
import { EStatus } from '@App/Service/Companies/Details/Sensors/Details/Details';
import { CompaniesModel } from '@App/Service/Companies/Model';
import { UserModel } from '@App/Service/Users/Model';

interface IProps {
    companyId : number,
    userId : number,
    onChange ?: Function,
}
interface IState {}

@observer
export class FiltersForm extends React.Component<IProps, IState> {

    private readonly form : FiltersFormStore = new FiltersFormStore();

    public componentDidMount() : void {
        this.form.fill(this.props);

        this.form.fields.companyId.fill(this.props.companyId || '', { name: '' });
        if(this.props.companyId) this.loadSearchCompany();

        this.form.fields.userId.fill(this.props.userId || '', { name: '' });
        if(this.props.userId) this.loadSearchUser();
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(this.props.companyId != prevProps.companyId) {
            this.form.fields.companyId.fill(this.props.companyId || '', { name: '' });
        }
        if(this.props.companyId != prevProps.companyId && this.props.companyId) this.loadSearchCompany();

        if(this.props.userId != prevProps.userId) {
            this.form.fields.userId.fill(this.props.userId || '', { name: '' });
        }
        if(this.props.userId != prevProps.userId && this.props.userId) this.loadSearchUser();
    }

    public render() : React.ReactNode {
        return (
            <Form store={this.form} unsafe={true} onSubmit={() => this.onSubmit()}>
                <div className="form-group">
                    <label>Company</label>
                    <SearchField
                        id={'companies-search'}
                        onSearch={async (keywords, page) => {
                            const res = await CompaniesModel.search({
                                qstr: keywords,
                                page,
                                limit: 999,
                            });
                            return {
                                keywords,
                                options: res.success && res.payload
                                    ? res.payload.data.map(item => ({
                                        id: item.id,
                                        name: item.name,
                                    }))
                                    : [],
                                pages: 1,
                                total: 1,
                            };
                        }}
                        store={this.form.fields.companyId}
                    />
                </div>
                <div className="form-group">
                    <label>User</label>
                    <SearchField
                        id={'user-search'}
                        onSearch={async (keywords, page) => {
                            const res = await UserModel.search({
                                qstr: keywords,
                                page,
                                limit: 999,
                            });
                            return {
                                keywords,
                                options: res.success && res.payload
                                    ? res.payload.data.map(item => ({
                                        id: item.id,
                                        name: item.name,
                                    }))
                                    : [],
                                pages: 1,
                                total: 1,
                            };
                        }}
                        store={this.form.fields.userId}
                    />
                </div>
                <div className="btn-group btn-group-sm w-100">
                    <button type="submit" className="btn btn-primary">
                        <i className="fa fa-check" /> Apply
                    </button>
                    <button type="button" className="btn btn-secondary" onClick={() => this.onClear()}>
                        <i className="fa fa-ban" /> Clear
                    </button>
                </div>
            </Form>
        );
    }

    private loadSearchCompany() : void {
        (async () => {
            this.setState({ status: EStatus.Loading });
            const res = await CompaniesModel.details(Number(this.props.companyId));
            if(res.success && res.payload) {
                this.form.fields.companyId.meta.name = res.payload.data.name;
                this.setState({ status: EStatus.Loaded });
            } else this.setState({ status: EStatus.Failed });
        })();
    }

    private loadSearchUser() : void {
        (async () => {
            this.setState({ status: EStatus.Loading });
            const res = await UserModel.details(Number(this.props.userId));
            if(res.success && res.payload) {
                this.form.fields.userId.meta.name = res.payload.data.name;
                this.setState({ status: EStatus.Loaded });
            } else this.setState({ status: EStatus.Failed });
        })();
    }

    private onSubmit() : void {
        const filters = this.form.getValues();
        const query = { ...Location.params };
        for(const key in filters) {
            if(filters[key] === null || (key == 'port' && !filters[key])) {
                delete query[key];
            } else query[key] = filters[key];
        }
        query.page = '1';
        const newQuery = Location.buildQuery(query);
        if(this.props.onChange) this.props.onChange(query);
        App.redirect(`/online${newQuery ? `?${newQuery}` : ''}`);
    }

    private onClear() : void {
        const filters = this.form.getValues();
        const query = { ...Location.params };
        for(const key in filters) delete query[key];
        query.page = '1';
        const newQuery = Location.buildQuery(query);
        if(this.props.onChange) this.props.onChange(query);
        App.redirect(`/online${newQuery ? `?${newQuery}` : ''}`);
    }

}