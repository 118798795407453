import React from 'react';
import { MainMenu } from '@App/Layout/MainLayout/MainMenu/MainMenu';
import { Link } from '@Framework/Factory';
import { UserBar } from '@App/Layout/MainLayout/UserBar/UserBar';
import logo from '@App/logo.png';
import '@App/Layout/MainLayout/MainLayout.less';

interface IProps {
    children : React.ReactNode | React.ReactNode[],
    service : string,
}
interface IState {
    menuOpen : boolean,
}

export class MainLayout extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
        };
    }

    public render() : React.ReactNode {
        return (
            <div id="main-layout">
                <div id="header">
                    <div className="header-row">
                        <div className="container-fluid">
                            <div className="row align-items-center header-row">
                                <div className="col-12 col-sm">
                                    <div className="row align-items-center header-row">
                                        <div className="col">
                                            <Link url="/">
                                                <a id="logo"><img src={logo} alt="S"/> <span>Sensors</span></a>
                                            </Link>
                                        </div>
                                        <div className="col-auto d-block d-md-none">
                                            <button type="button" className="btn btn-outline-primary" onClick={() => this.setState(state => ({ menuOpen: !state.menuOpen }))}>
                                                <i className="fa fa-bars" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-sm-auto">
                                    <UserBar />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`header-row${this.state.menuOpen ? ' open' : ''}`} id="menu-row">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col">
                                    <MainMenu active={this.props.service} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="body">
                    {this.props.children}
                </div>
                <div id="footer">
                    <div className="container-fluid">
                        <div className="row py-3">
                            <div className="col">
                                {COPYRIGHT}
                            </div>
                            <div className="col text-end">
                                v{VERSION}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}