import React from 'react';

interface IProps {
    children : React.ReactNode,
    topLevelId : string,
    onClick : (event : MouseEvent) => void,
}
interface IState {}

export class OnClickOutside extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);
        this.onClickOutside = this.onClickOutside.bind(this);
    }

    public componentDidMount() {
        document.addEventListener('click', this.onClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener('click', this.onClickOutside);
    }

    public render() : React.ReactNode {
        return this.props.children;
    }

    private onClickOutside(event : MouseEvent) : void {
        if(
            (event.target as HTMLElement).id == this.props.topLevelId ||
            (event.target as HTMLElement).closest(`#${this.props.topLevelId}`)
        ) return;
        if(this.props.onClick) this.props.onClick(event);
    }

}