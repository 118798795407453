import { TRuleType, IRuleResult } from '@Framework/Library/Form/Rule';
import { Locale } from '@Framework/Core/Locale';

interface IProps {
    min ?: number,
    max ?: number,
    equal ?: number,
    minMessage ?: string,
    maxMessage ?: string,
    equalMessage ?: string,
}

export function ruleLength(props : IProps = {}) : TRuleType {
    const lang = Locale.Lang.rules.length;
    return function (value : string) : IRuleResult {
        if(value.length < 1) return { isValid: true };
        if(props.hasOwnProperty('equal') && value.length !== props.equal) {
            return {
                isValid: false,
                message: props.equalMessage || lang.equalMessage(props.equal),
            };
        }
        if(props.hasOwnProperty('min') && value.length < props.min) {
            return {
                isValid: false,
                message: props.minMessage || lang.minMessage(props.min),
            };
        }
        if(props.hasOwnProperty('max') && value.length > props.max) {
            return {
                isValid: false,
                message: props.maxMessage || lang.maxMessage(props.max),
            };
        }
        return { isValid: true };
    };
}