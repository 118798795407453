import React from 'react';
import { FormField, IFormFieldProps } from '@Framework/Component/FormField';

interface IProps extends IFormFieldProps {
    id ?: string,
    value : string,
}

export class RadioField extends FormField<IProps> {

    protected readonly _defaultInline : boolean = true;

    protected renderField() : React.ReactElement {
        return (
            <input
                id={this.props.id}
                type="radio"
                readOnly={!!this.props.readonly}
                disabled={!!this.props.disabled}
                className={`form-check-input ${this.props.store.isValid ? '' : ' is-invalid'}`}
                checked={this.props.store.value == this.props.value}
                onChange={() => this.props.store.value = this.props.value}
                autoComplete="off"
            />
        );
    }

}