import React from 'react';
import { FormField, IFormFieldProps } from '@Framework/Component/FormField';

interface IProps extends IFormFieldProps {
    labels ?: string[2],
    values ?: string[2],
    colors ?: string[2],
    wide ?: boolean,
}

export class SwitchField extends FormField<IProps> {

    protected renderField() : React.ReactElement {
        const labels = this.props.labels ? this.props.labels : [ 'On', 'Off' ];
        const values = this.props.values ? this.props.values : [ '1', '0' ];
        const colors = this.props.colors ? this.props.colors : [ 'success', 'danger' ];
        return (
            <div className={`btn-group${this.props.size ? ` btn-group-${this.props.size}` : ''}${this.props.wide ? ' d-flex' : ''}`}>
                <button
                    type="button"
                    className={`btn btn-${this.props.store.value != values[0] ? 'outline-' : ''}${colors[0]}`}
                    disabled={this.props.disabled}
                    onClick={() => this.props.store.value = values[0]}
                >
                    {labels[0]}
                </button>
                <button
                    type="button"
                    className={`btn btn-${this.props.store.value != values[1] ? 'outline-' : ''}${colors[1]}`}
                    disabled={this.props.disabled}
                    onClick={() => this.props.store.value = values[1]}
                >
                    {labels[1]}
                </button>
            </div>
        );
    }

}