import { TRuleType, IRuleResult } from '@Framework/Library/Form/Rule';
import { Locale } from '@Framework/Core/Locale';

interface IProps {
    noNegative ?: boolean,
    noDecimal ?: boolean,
    allowPlus ?: boolean,
    min ?: number,
    max ?: number,
    less ?: number,
    greater ?: number,
    invalidMessage ?: string,
    noNegativeMessage ?: string,
    noDecimalMessage ?: string,
    minMessage ?: string,
    maxMessage ?: string,
    lessMessage ?: string,
    greaterMessage ?: string,
}

export function ruleNumber(props : IProps = {}) : TRuleType {
    const lang = Locale.Lang.rules.number;
    return function (value : string) : IRuleResult {
        if(value.length < 1) return { isValid: true };
        if(!value.match(/^[+-]?\d+(\.\d+)?$/) || (value[0] == '+' && !props.allowPlus)) {
            return {
                isValid: false,
                message: props.invalidMessage || lang.invalidMessage(),
            };
        }
        if(props.noNegative && value[0] == '-') {
            return {
                isValid: false,
                message: props.noNegativeMessage || lang.noNegativeMessage(),
            };
        }
        if(props.noDecimal && value.indexOf('.') >= 0) {
            return {
                isValid: false,
                message: props.noDecimalMessage || lang.noDecimalMessage(),
            };
        }
        if(props.hasOwnProperty('min') && Number(value) < props.min) {
            return {
                isValid: false,
                message: props.minMessage || lang.minMessage(props.min),
            };
        }
        if(props.hasOwnProperty('max') && Number(value) > props.max) {
            return {
                isValid: false,
                message: props.maxMessage || lang.maxMessage(props.max),
            };
        }
        if(props.hasOwnProperty('less') && Number(value) >= props.less) {
            return {
                isValid: false,
                message: props.lessMessage || lang.lessMessage(props.less),
            };
        }
        if(props.hasOwnProperty('greater') && Number(value) <= props.greater) {
            return {
                isValid: false,
                message: props.greaterMessage || lang.greaterMessage(props.greater),
            };
        }
        return { isValid: true };
    };
}