import { EFieldType, Field, Group, List, Rule } from '@Framework/Library/Form';
import { makeObservable } from 'mobx';

type TFields = {
    uid : Field,
    name : Field,
    active : Field,
    priority : Field,
    notes : Field,
    groupId : Field,
    locationId : Field,

}
interface IValues {
    uid : string,
    name : string,
    active : boolean,
    priority : number,
    notes : string,
    groupId : number,
    locationId : number,
}

export class CreateFormStore extends Group<TFields, IValues> {

    constructor() {
        super(null, true);

        this._fields = {
            uid: new Field({
                rules: [ Rule.required() ],
            }),
            name: new Field(),
            active: new Field({
                type: EFieldType.Boolean,
            }),
            priority: new Field(),
            notes: new Field(),
            groupId: new Field({
                rules: [ Rule.number() ],
            }),
            locationId: new Field({
                rules: [ Rule.number() ],
            }),
        };
        makeObservable(this);
    }

}