import { makeObservable, runInAction, observable, computed, action } from 'mobx';
import { Form, IMember, TRuleType } from '@Framework/Library/Form';

export interface IFieldProps {
    type ?: EFieldType,
    value ?: string | number | boolean,
    rules ?: TRuleType[],
}

export enum EFieldType {
    String,
    Number,
    Boolean,
}

interface ISavedState {
    value : string,
    isValid : boolean,
    error : string,
    meta : Record<string, any>,
}

export class Field implements IMember {

    protected readonly _id : number = Form.UniqueID;
    @observable protected _value : string = '';
    @observable protected _isValid : boolean = true;
    @observable protected _error : string = '';
    @observable protected _meta : Record<string, any> = {};
    @observable protected _savedState : ISavedState = { value: '', isValid: true, error: '', meta: {} };
    protected readonly _type : EFieldType = EFieldType.String;
    protected readonly _rules : TRuleType[] = [];

    public get id() : number {
        return this._id;
    }

    public get value() : string {
        return this._value;
    }

    public set value(value : string) {
        runInAction(() => {
            this._value = value;
            this.validate();
        });
    }

    public get meta() : Record<string, any> {
        return this._meta;
    }

    public set meta(meta : Record<string, any>) {
        this._meta = meta;
    }

    @computed public get isSaved() : boolean {
        return this._value == this._savedState.value;
    }

    public get isValid() : boolean {
        return this._isValid;
    }

    public get error() : string {
        return !this._isValid ? this._error : '';
    }

    constructor(props : IFieldProps = {}, isSuper : boolean = false) {
        this._type = props.type || EFieldType.String;
        this._value = this.valueToString(props.value || '');
        this._rules = props.rules || [];
        if(!isSuper) makeObservable(this);
    }

    @action public validate() : Field {
        for(let i = 0; i < this._rules.length; i++) {
            const result = this._rules[i](this._value);
            this._isValid = result.isValid;
            this._error = result.message || '';
            if(!result.isValid) break;
        }
        return this;
    }

    @action public restore() : Field {
        this._value = this._savedState.value;
        this._isValid = this._savedState.isValid;
        this._error = this._savedState.error;
        this._meta = this._savedState.meta;
        return this;
    }

    @action public save() : Field {
        this._savedState = {
            value: this._value,
            isValid: this._isValid,
            error: this._error,
            meta: this._meta,
        };
        return this;
    }

    @action public fill(value : string | number | boolean = '', meta : Record<string, any> = {}) : Field {
        this._value = this.valueToString(value);
        this._meta = meta;
        return this;
    }

    @action public clear() : Field {
        this._value = '';
        this._meta = {};
        return this;
    }

    public getValues() : string | number | boolean {
        if(this._type == EFieldType.Number) {
            return this._value ? Number(this._value) : null;
        } else if(this._type == EFieldType.Boolean) {
            return this._value && this._value != '0';
        } else return this._value;
    }

    private valueToString(value : string | number | boolean = '') {
        if(this._type == EFieldType.Number) {
            return value === null ? '' : String(value);
        } else if(this._type == EFieldType.Boolean) {
            return value && value != '0' ? '1' : '0';
        } else return value ? String(value) : '';
    }

}