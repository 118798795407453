export namespace UserModelStruct {

    export interface IUserShort {
        companies : {
            id : number,
            name : string,
            role : number,
        }[],
        email : string,
        id : number,
        name : string,
        superuser : boolean,
        role : {
            id : number,
            name : string,
        },
        isActive : boolean,
        tm : number,
    }
    export interface IUserShortSource {
        companies : {
            id : string,
            name : string,
            role : string,
        }[],
        email : string,
        superuser : string,
        id : string,
        name : string,
        role : string,
        status : string,
        tm : string,
    }
    export interface IUser extends IUserShort {
        alertDaily : boolean,
        name : string,
        phone : string,
        resetHashTm : number,
        tmA : number,
    }
    export interface IUserSource extends IUserShortSource {
        alert_daily : string,
        fullname : string,
        phone : string,
        resethash_tm : string,
        tm_a : string,
    }
    export interface IUserSearch {
        id : number,
        name : string,
        email : string,
    }
    export interface IUserSearchSource {
        id : number,
        name : string,
        email : string,
    }

}