import { Reply, TResponseHandler, EResponseType } from '@Framework/Library/Gateway/Reply';
import { ERequestMethod } from '@Framework/Library/Gateway/Inquiry';
import { Gateway } from '@Framework/Library/Gateway/Gateway';
import { ERemoteResource } from '@App';

export interface IModelProps<TQuery, TPayload, TResponse> {
    resource ?: ERemoteResource,
    path : string,
    query ?: TQuery,
    method ?: ERequestMethod,
    headers ?: Record<string, string>,
    auth ?: boolean,
    payload ?: TPayload,
    responseType ?: EResponseType,
    responseHandler ?: TResponseHandler<TResponse>,
    useCustomStatus ?: boolean,
}
export interface IRemoteResource {
    origins : string[],
    defaults ?: IDefaultProps,
}
export interface IModelOptions {
    onError ?: TOnError,
    onStart ?: Function,
    onEnd ?: Function,
}
interface IDefaultProps {
    headers ?: Record<string, string>,
    auth ?: boolean,
    responseType ?: EResponseType,
    hybridStatusCode ?: boolean,
}
interface IConfig {
    resources ?: IRemoteResource[],
    defaultResource ?: ERemoteResource,
    getAuthToken ?: () => string,
    defaultHeaders ?: Record<string, string>,
    defaultErrorHandler ?: (res : Reply) => void,
}

type TErrorHandler = (res : Reply) => void;
type TOnError = (res : Reply, defaultHandler : TErrorHandler) => void;

export abstract class Model {

    protected static _resources : IRemoteResource[] = [];
    protected static _defaultResource : ERemoteResource = 0;
    protected static _defaultHeaders : Record<string, string> = {};
    protected static _getAuthToken : () => string = function () { return ''; };
    protected static _defaultErrorHandler : (res : Reply) => void = function () {};

    public static configure(options : IConfig) : void {
        if(options.resources) Model._resources = options.resources;
        if(options.defaultResource) Model._defaultResource = options.defaultResource;
        if(options.getAuthToken) Model._getAuthToken = options.getAuthToken;
        if(options.defaultHeaders) Model._defaultHeaders = options.defaultHeaders;
        if(options.defaultErrorHandler) Model._defaultErrorHandler = options.defaultErrorHandler;
    }

    protected static async request<TQuery = any, TPayload = any, TResponse = any>(
        props : IModelProps<TQuery, TPayload, TResponse>,
        options : IModelOptions = {},
    ) : Promise<Reply<TResponse>> {
        const resource = this._resources[props.resource !== undefined ? props.resource : Model._defaultResource];
        const headers = { ...resource.defaults.headers ? resource.defaults.headers : this._defaultHeaders };
        if(props.auth !== undefined ? props.auth : resource.defaults.auth) {
            headers['Authorization'] = Model._getAuthToken();
        }
        if(props.headers) {
            for(const key in props.headers) {
                if(!props.headers.hasOwnProperty(key)) continue;
                headers[key] = props[key];
            }
        }

        for(let i = 0; i < resource.origins.length; i++) {
            const host = resource.origins[i];
            const gateway = new Gateway<TPayload, TResponse>({
                host,
                path: props.path,
                query: props.query ? Model.queryStringify(props.query) : undefined,
                method: props.method || undefined,
                headers,
                payload: props.payload || undefined,
                responseType: props.responseType !== undefined
                    ? props.responseType
                    : resource.defaults.responseType || undefined,
                responseHandler: props.responseHandler || undefined,
                useCustomStatus: props.useCustomStatus !== undefined
                    ? props.useCustomStatus
                    : resource.defaults.hybridStatusCode || undefined,
            });

            if(options.onStart) options.onStart();
            await gateway.exec();
            if(options.onEnd) options.onEnd();

            if(resource.origins.length > 1 && !gateway.response.success && gateway.response.status == 0) continue;

            if(!gateway.response.success) {
                if(options.onError) {
                    options.onError(gateway.response, Model._defaultErrorHandler);
                } else Model._defaultErrorHandler(gateway.response);
            }

            return gateway.response;
        }
    }

    protected static queryStringify(query : Record<string, any>) : Record<string, string> {
        const res : Record<string, string> = {};
        for(const key in query) {
            if(query[key] === undefined) continue;
            res[key] = String(typeof query[key] == 'boolean' ? Number(query[key]) : query[key]);
        }
        return res;
    }

}