import React from 'react';
import { Loading } from '@Framework/Component/Loading';
import { observer } from 'mobx-react';
import { Details as CompanyDetails, ETab } from '@App/Service/Companies/Details/Details';
import { EModalSize, Link, Modal } from '@Framework/Factory';
import { SetsModel } from '@App/Service/Companies/Details/Sets/Model/SetsModel';
import { SetsModelStruct } from '@App/Service/Companies/Details/Sets/Model/SetsModel.struct';
import { DropdownField, InputField, SearchField } from '@Framework/Component/FormField';
import { Form } from '@Framework/Library/Form';
import { App } from '@Framework/Core/App';
import { ENotificationType } from '@Framework/Component/Notification';
import { CreateFormStore } from '@App/Service/Companies/Details/Sets/Create/CreateFormStore';
import { DetailsFormStore } from '@App/Service/Companies/Details/Sets/Details/DetailsFormStore';
import { SensorsModel } from '@App/Service/Companies/Details/Sensors/Model/SensorsModel';
import { sensorIcons } from '@App/Assets/SensorIcons';
import { CompaniesModel } from '@App/Service/Companies/Model';

enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}
enum EAction {
    Idle,
    Saving,
    DeleteConfirmation,
    Deleting,
}
interface IProps {
    id : number,
    companyId : number,
}
interface IState {
    status : EStatus,
    action : EAction,
    data : SetsModelStruct.IDetails,
    moveTag : number,
}

@observer
export class Details extends React.Component<IProps, IState> {

    private isMount : boolean = false;
    private form : DetailsFormStore = new DetailsFormStore();

    constructor(props) {
        super(props);
        this.state = {
            status: EStatus.Unloaded,
            action: EAction.Idle,
            data: null,
            moveTag: null,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.loadData();
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(this.props.id != prevProps.id) this.loadData();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        return (
            <CompanyDetails
                id={this.props.companyId}
                tab={ETab.Sets}
                title={<h3 className="m-0">Details Set</h3>}
                breadcrumb={
                    <>
                        <li className="breadcrumb-item">
                            <Link url={`companies/${this.props.companyId}/sets`}><a>Sets</a></Link>
                        </li>
                        <li className="breadcrumb-item">Details Set</li>
                    </>
                }
            >
                <div className="card-body">
                    {this.state.status == EStatus.Loading && <Loading label="Loading chart..." />}
                    {this.state.status == EStatus.Failed && <div className="alert alert-danger"><i className="fa fa-exclamation-triangle" /> Stats loading error</div>}
                    {this.state.status == EStatus.Loaded &&
                        <>
                            <Form store={this.form} onSubmit={() => this.onSubmit()}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Name of the set <span className="text-danger">*</span></label>
                                                <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.name} />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label>Icon <span className="text-danger">*</span></label>
                                                <DropdownField
                                                    id="iconId"
                                                    disabled={this.state.action != EAction.Idle}
                                                    store={this.form.fields.iconId}
                                                    options={Object.keys(sensorIcons).map((item, index) => ({
                                                        value: String(index + 1),
                                                        option: <><img src={sensorIcons[item]} height={22} alt=""/></>,
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <div className="col">
                                            <div className="row mb-2">
                                                <div className="col"><p>Set Sensors</p></div>
                                                <div className="col-auto">
                                                    <button type="button" className="btn btn-warning btn-sm" onClick={() => this.form.fields.sensors.append()}>
                                                        <i className="fa fa-plus"/> Add
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    {this.form.fields.sensors.items.map((item, index) => (
                                                        <div key={item.id} className="card">
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-auto">
                                                                        <div className="btn-group">
                                                                            <button type="button" className="btn btn-secondary" disabled={this.form.fields.sensors.items.length < 2 || index < 1} onClick={() => this.form.fields.sensors.swap(index, index - 1)}>
                                                                                <i className="fa fa-chevron-up" />
                                                                            </button>
                                                                            <button type="button" className="btn btn-secondary" disabled={this.form.fields.sensors.items.length < 2 || index >= this.form.fields.sensors.items.length - 1} onClick={() => this.form.fields.sensors.swap(index, index + 1)}>
                                                                                <i className="fa fa-chevron-down" />
                                                                            </button>
                                                                            <button type="button" className={`btn btn-${this.state.moveTag == index ? 'info' : 'secondary'}`} disabled={this.form.fields.sensors.items.length < 2} onClick={() => this.move(index)}>
                                                                                <i className="fa fa-sort" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="form-group">
                                                                            <SearchField
                                                                                id={'sensors-search'}
                                                                                onSearch={async (keywords, page) => {
                                                                                    const res = await SensorsModel.list({
                                                                                        qstr: keywords,
                                                                                        page,
                                                                                        limit: 10,
                                                                                    });
                                                                                    return {
                                                                                        keywords,
                                                                                        options: res.success && res.payload
                                                                                            ? res.payload.data.map(item => ({
                                                                                                id: item.idx,
                                                                                                name: item.name,
                                                                                            }))
                                                                                            : [],
                                                                                        pages: res.success && res.payload
                                                                                            ? res.payload.pagination.page
                                                                                            : 1,
                                                                                        total: res.success && res.payload
                                                                                            ? res.payload.pagination.pages
                                                                                            : 1,
                                                                                    };
                                                                                }}
                                                                                store={item}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <button type="button" className="btn btn-danger" onClick={() => this.form.fields.sensors.remove(index)}>
                                                                            <i className="fa fa-times"/>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <button
                                                type="button"
                                                disabled={this.state.action != EAction.Idle}
                                                className="btn btn-danger"
                                                onClick={() => this.setState({
                                                    action: EAction.DeleteConfirmation,
                                                })}
                                            >
                                                {this.state.action == EAction.Deleting
                                                    ? <span className="spinner-border spinner-border-sm" />
                                                    : <i className="fa fa-trash" />
                                                } Delete
                                            </button>
                                        </div>
                                        <div className="col" />
                                        <div className="col-auto">
                                            {!this.form.isValid && <div className="form-notice text-danger"><i className="fa fa-exclamation-circle" /> Validation errors</div>}
                                            {!this.form.isSaved && <div className="form-notice text-warning"><i className="fa fa-exclamation-triangle" /> Unsaved changes</div>}
                                        </div>
                                        <div className="col-auto">
                                            <button type="submit" disabled={this.state.action != EAction.Idle} className="btn btn-primary">
                                                {this.state.action == EAction.Saving
                                                    ? <span className="spinner-border spinner-border-sm" />
                                                    : <i className="fa fa-check" />
                                                } Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                            {this.state.action == EAction.DeleteConfirmation &&
                                <Modal
                                    size={EModalSize.SM}
                                    onClickOutside={() => this.setState({ action: EAction.Idle })}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title">Delete</h5>
                                        <button type="button" className="btn btn-light btn-sm" onClick={() => this.setState({ action: EAction.Idle })}>
                                            <i className="fa fa-times"/>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        Are you sure?
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={() => this.setState({ action: EAction.Idle })}><i className="fa fa-ban" /> Cancel</button>
                                        <button type="button" className="btn btn-danger" onClick={() => this.onDelete()}><i className="fa fa-trash" /> Delete</button>
                                    </div>
                                </Modal>
                            }
                        </>
                    }
                </div>
            </CompanyDetails>

        );
    }

    private loadData() : void {
        (async () => {
            this.setState({ status: EStatus.Loading });
            const sets = await SetsModel.details(this.props.id);
            if(!this.isMount) return;
            if(sets.success && sets.payload) {
                this.form.fill({
                    name: sets.payload.data.name,
                    orderno: sets.payload.data.orderno,
                    iconId: sets.payload.data.iconId,
                    sensors: sets.payload.data.sensors.map(item => item.id),
                });
                this.form.fields.sensors.items.map((field, index) => {
                    field.meta.name = sets.payload.data.sensors[index].name;
                });
                this.form.save();
                this.setState({
                    status: EStatus.Loaded,
                    data: sets.payload.data,
                });
            } else this.setState({ status: EStatus.Failed });
        })();
    }

    public move(index) : void {
        if(this.state.moveTag == null) {
            this.setState({ moveTag: index });
        } else if(this.state.moveTag == index) {
            this.setState({ moveTag: null });
        } else {
            this.form.fields.sensors.swap(this.state.moveTag, index);
            this.setState({ moveTag: null });
        }
    }

    private onSubmit() : void {
        this.form.validate();
        if(!this.form.isValid) return;
        this.setState({ action: EAction.Saving });
        (async () => {
            const res = await SetsModel.update(this.props.id, this.form.getValues());
            if(!this.isMount) return;
            if(res.success) {
                this.form.save();
                App.notification({
                    type: ENotificationType.Success,
                    title: 'Success',
                    message: 'Successfully created.',
                });
                App.redirect(`/companies/${this.props.companyId}/sets/${res.payload.data.id}`);
            }
            this.setState({ action: EAction.Idle });
        })();
    }

    private onDelete() : void {
        this.setState({ action: EAction.Deleting });
        (async () => {
            const res = await SetsModel.delete(this.props.id);
            if(!this.isMount) return;
            if(res.success) {
                App.notification({
                    type: ENotificationType.Success,
                    title: 'Success',
                    message: 'Successfully deleted.',
                });
                App.redirect(`companies/${this.props.companyId}/sets`);
            }
            this.setState({ action: EAction.Idle });
        })();
    }

}