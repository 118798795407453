import React from 'react';
import { Map } from 'subway-router';
import { TLoadService } from '@Framework/Core/App';
import { LoggedOut } from '@App/Middleware';
import { Login } from '@App/Service/Auth';

export function register(map : Map, loadService : TLoadService) {
    map.group('/auth', group => {
        group.route('/login', () => {
            loadService(<Login />);
        });
    }).name('auth').middleware(new LoggedOut());
}