import React from 'react';
import ReactDOM from 'react-dom';
import '@Framework/Factory/Modal/modal.less';

interface IProps {
    children : React.ReactNode,
    id ?: string,
    class ?: string,
    size ?: EModalSize,
    onClickOutside ?: Function,
}
interface IState {}

export enum EModalSize {
    SM,
    MD,
    LG,
    XL
}

export class Modal extends React.Component<IProps, IState> {

    private element : Element;
    private readonly container : Element;

    constructor(props, state) {
        super(props, state);
        this.container = document.createElement('div');
    }

    public componentDidMount() : void {
        document.getElementById('modal-root').appendChild(this.container);
    }

    public componentWillUnmount() : void {
        document.getElementById('modal-root').removeChild(this.container);
    }

    public render() : React.ReactNode {
        let sizeClass = '';
        if(this.props.size == EModalSize.SM) {
            sizeClass = ' modal-sm';
        } else if(this.props.size == EModalSize.LG) {
            sizeClass = ' modal-lg';
        } else if(this.props.size == EModalSize.XL) {
            sizeClass = ' modal-xl';
        }
        return ReactDOM.createPortal(
            <div
                {...this.props.id ? { id: this.props.id } : {}}
                className={`modal${this.props.class ? ` ${this.props.class}` : ''}`}
                onClick={e => this.onClick(e)}
            >
                <div ref={node => this.element = node} className={`modal-dialog${sizeClass}`}>
                    <div className="modal-content">
                        {this.props.children}
                    </div>
                </div>
            </div>,
            this.container,
        );
    }

    private onClick(event : React.MouseEvent<HTMLElement>) : void {
        if(event.target == this.element || (event.target as HTMLElement).closest('.modal-dialog')) return;
        if(this.props.onClickOutside) this.props.onClickOutside();
    }

}