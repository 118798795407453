import React from 'react';
import { configure } from 'mobx';
import { Model } from '@Framework/Library/Gateway';
import { IBaseToken, Session } from '@Framework/Core/Session';
import { App } from '@Framework/Core/App';
import { ENotificationType, INotification } from '@Framework/Component/Notification';
import { AccessDeny, RefreshScreen } from '@App/SystemPage';
import { ELocale, Locale } from '@Framework/Core/Locale';
import { ELanguage } from '@Framework/Core/Language';
import { Login } from '@App/Service/Auth';
import { AuthModel } from '@App/Service/Auth/Model';
import { getFingerprint } from '@Framework/Library/Fingerprint';

// configure mobx
configure({
    computedRequiresReaction: false,
});

// configure model
export enum ERemoteResource {
    Main,
    Auth,
}
Model.configure({
    resources: [{
        origins: [],
        defaults: {
            auth: true,
            hybridStatusCode: true,
        },
    }, {
        origins: AUTH_API_HOSTS,
        defaults: {
            auth: true,
            hybridStatusCode: true,
        },
    }],
    defaultHeaders: {
        'Content-Type': 'application/json',
        Accept: 'application/json, text/plain, */*',
        clientid: getFingerprint(),
    },
    getAuthToken() {
        return `Bearer ${Session.token.access}`;
    },
    defaultErrorHandler(res) {
        const data : INotification = {
            type: ENotificationType.Error,
            message: '',
        };
        const logout = () => {
            Session.destroy();
            Session.setUser(null);
            App.setView(<Login />);
            data.type = ENotificationType.Warning;
            data.title = 'Authentication';
            data.message = 'Your session has expired. Please login again.';
            App.notification(data);
        };
        if(res.status == 0) {
            data.message = 'Service temporary unavailable.';
            App.notification(data);
        } else if(res.status == 401) {
            logout();
        } else if(res.status == 402) {
            if(!Session.isRefreshable) {
                logout();
                return;
            }
            (async () => {
                App.setView(<RefreshScreen />);
                const res = await AuthModel.refresh({
                    onError: () => logout(),
                });
                if(res.success) {
                    Session.update({
                        access: res.payload.token.access,
                        refresh: res.payload.token.refresh,
                        expiresAt: res.payload.token.expiresAt,
                    });
                    App.route();
                }
            })();
        } else if(res.status == 400) {
            if(res.failure.message) {
                data.message = res.failure.message;
            } else {
                data.message = 'Server error.';
            }
            App.notification(data);
        } else if(res.status == 403) {
            App.setView(<AccessDeny />);
            data.message = 'Access denied.';
            App.notification(data);
        } else if(res.status == 404) {
            data.message = 'Requested content was not found.';
            App.notification(data);
        } else if(res.status == 422) {
            if(res.failure.message) {
                data.message = res.failure.message;
            } else if(res.failure.errors && Object.keys(res.failure.errors).length > 0) {
                data.message = Object.keys(res.failure.errors).map(key => res.failure.errors[key]).join('\n');
            } else data.message = 'Validation failed.';
            App.notification(data);
        } else {
            data.message = 'Server error.';
            App.notification(data);
        }
    },
});

// configure session
export interface IToken extends IBaseToken {
    access : string,
    refresh ?: string,
    expiresAt : number,
}
export interface IUser {
    id : number,
    name : string,
    email : string,
    companyId : number,
    role : {
        id : number,
        name : string,
    },
    companies : {
        id : number,
        name : string,
    }[],
}
Session.configure({
    expiresAt(token : IToken) : number {
        return token.expiresAt;
    },
    onUpdateToken(token : Record<string, any>) : IToken {
        return {
            access: token ? token.access || '' : '',
            refresh: token ? token.refresh || '' : '',
            expiresAt: token ? Number(token.expiresAt) || 0 : 0,
        };
    },
    onUpdateUser(user : Record<string, any>) : IUser {
        return {
            id: user ? Number(user.id) : 0,
            name: user ? String(user.name) : '',
            email: user ? String(user.email) : '',
            companyId: user ? Number(user.companyId) : 0,
            role: user ? user.role : {},
            companies: user ? user.companies : [],
        };
    },
    prefix: SESSION_PREFIX,
    domain: SESSION_DOMAIN,
    secure: SESSION_SECURE,
    maxAge: SESSION_MAX_AGE,
});

// localization
Locale.configure({
    locale: ELocale.EU,
    language: ELanguage.EN,
});