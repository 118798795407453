const us = [ 'en-US', 'en-UK', 'US', 'UK' ].indexOf(navigator.language) >= 0;
export const Format = {
    Date: {
        MN: us ? 'MM/D' : 'DD.MM',
        MS: us ? 'MMM D' : 'DD MMM',
        MF: us ? 'MMMM D' : 'DD MMMM',
        YN: us ? 'M/D/YYYY' : 'DD.MM.YYYY',
        YS: us ? 'MMM D, YYYY' : 'DD MMM YYYY',
        YF: us ? 'MMMM D, YYYY' : 'DD MMMM YYYY',
    },
    Time: {
        M: us ? 'h:mm A' : 'HH:mm',
        S: us ? 'h:mm:ss A' : 'HH:mm:ss',
    },
};