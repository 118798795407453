import React from 'react';
import { FormField, IFormFieldProps } from '@Framework/Component/FormField';
import { OnClickOutside } from '@Framework/Factory';

interface IProps extends IFormFieldProps {
    id : string,
    options : {
        value : string,
        option : React.ReactNode,
    }[],
}
interface IState {
    opened : boolean,
}

export class DropdownField extends FormField<IProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            opened: false,
        };
    }

    protected renderField() : React.ReactElement {
        const selected = this.props.options.find(item => item.value == this.props.store.value) || null;
        return (
            <OnClickOutside topLevelId={this.props.id} onClick={() => this.setState({ opened: false })}>
                <div className="dropdown" id={this.props.id}>
                    <button
                        type="button"
                        className={`btn btn-outline-secondary dropdown-toggle ${this.props.size ? ` btn-${this.props.size}` : ''}${this.props.store.isValid ? '' : ' is-invalid'}`}
                        disabled={!!this.props.disabled}
                        onClick={() => this.setState(state => ({ opened: !state.opened }))}
                    >
                        {selected ? selected.option : this.props.placeholder}
                    </button>
                    {this.state.opened &&
                        <ul className="dropdown-menu show dropdown-icon">
                            {this.props.options.map((item, index) => (
                                <li key={index} onClick={() => this.onSelect(item.value)}>
                                    <span className="dropdown-item">{item.option}</span>
                                </li>
                            ))}
                        </ul>
                    }
                </div>
            </OnClickOutside>
        );
    }

    private onSelect(value : string) : void {
        this.setState({ opened: false });
        this.props.store.value = value;
    }

}