import React from 'react';
import '@Framework/Component/Tooltip/tooltip.less';
import { OnClickOutside } from '@Framework/Factory';

export enum EPosition {
    Top,
    Left,
    Right,
    Bottom,
    TopLeft,
    TopRight,
    BottomLeft,
    BottomRight,
}
interface IProps {
    children : React.ReactNode,
    content : React.ReactNode,
    position ?: EPosition,
    toggle ?: boolean,
}
interface IState {
    display : boolean,
}

export class Tooltip extends React.Component<IProps, IState> {

    private readonly id : number;
    private static _uniqId : number = 0;

    private static get uniqId() : number {
        return ++Tooltip._uniqId;
    }

    constructor(props) {
        super(props);
        this.state = {
            display: false,
        };
        this.id = Tooltip.uniqId;
    }

    public render() : React.ReactNode {
        let subClass = 'bs-tooltip-top tooltip-top';
        if(this.props.position == EPosition.Left) {
            subClass = 'bs-tooltip-start tooltip-left';
        } else if(this.props.position == EPosition.Right) {
            subClass = 'bs-tooltip-end tooltip-right';
        } else if(this.props.position == EPosition.Bottom) {
            subClass = 'bs-tooltip-bottom tooltip-bottom';
        } else if(this.props.position == EPosition.TopLeft) {
            subClass = 'bs-tooltip-top tooltip-top-left';
        } else if(this.props.position == EPosition.TopRight) {
            subClass = 'bs-tooltip-top tooltip-top-right';
        } else if(this.props.position == EPosition.BottomLeft) {
            subClass = 'bs-tooltip-bottom tooltip-bottom-left';
        } else if(this.props.position == EPosition.BottomRight) {
            subClass = 'bs-tooltip-bottom tooltip-bottom-right';
        }
        return this.props.toggle ? this.toggleTooltip(subClass) : this.hoverTooltip(subClass);
    }

    private hoverTooltip(subClass : string) : React.ReactNode {
        return (
            <div
                className="tooltip-wrapper"
                onMouseEnter={() => this.setState({ display: true })}
                onMouseLeave={() => this.setState({ display: false })}
            >
                {this.state.display &&
                    <div className={`tooltip ${subClass}`}>
                        <div className="tooltip-arrow"></div>
                        <div className="tooltip-inner">{this.props.content}</div>
                    </div>
                }
                {this.props.children}
            </div>
        );
    }

    private toggleTooltip(subClass : string) : React.ReactNode {
        return (
            <div className="tooltip-wrapper" id={`tooltip-${this.id}`} onClick={() => this.setState(state => ({ display: !state.display }))}>
                {this.state.display &&
                    <OnClickOutside topLevelId={`tooltip-${this.id}`} onClick={() => this.setState({ display: false })}>
                        <div className={`tooltip ${subClass}`}>
                            <div className="tooltip-arrow"></div>
                            <div className="tooltip-inner">{this.props.content}</div>
                        </div>
                    </OnClickOutside>
                }
                {this.props.children}
            </div>
        );
    }

}