import { DefaultStruct } from '@App/Structures';
import { ERangeType, RangeSelector } from '@App/Component/RangeSelector';

export namespace LogsModelReq {

    export namespace List {
        export interface IQuery extends DefaultStruct.IQuery {
            typeId ?: number,
            severity ?: number,
            ip ?: string,
            dateRange ?: ERangeType,
            dateFrom ?: number,
            dateTo ?: number,
        }
        export interface IQueryOutput extends DefaultStruct.IQueryOutput {
            type_id ?: number,
            severity ?: number,
            ip ?: string,
            from ?: number,
            to ?: number,
        }
        export function getQuery(query : IQuery) : IQueryOutput {
            const output : IQueryOutput = {
                ...RangeSelector.getDateQuery(query),
            };
            if(query.page) output.page = query.page;
            if(query.limit) output.limit = query.limit;
            if(query.orderBy) output.orderBy = query.orderBy;
            if(query.sort) output.sort = query.sort;
            if(query.severity) output.severity = query.severity;
            if(query.typeId) output.type_id = query.typeId;
            if(query.ip) output.ip = query.ip;
            return output;
        }
    }

}