import React from 'react';
import { IMiddleware, Request, Route, TOnLoad } from 'subway-router';
import { Session } from '@Framework/Core/Session';
import { AuthModel } from '@App/Service/Auth/Model/AuthModel';
import { App } from '@Framework/Core/App';
import { LoadingScreen } from '@App/SystemPage';

export class Userdata implements IMiddleware {

    public async onResolving(onLoad : TOnLoad, request : Request, route : Route) : Promise<TOnLoad> {
        if(!route.inGroup('auth') && Session.isActive && Session.user && Session.user.id < 1) {
            App.setView(<LoadingScreen />);
            const res = await AuthModel.user();
            if(res.success) await new Promise(resolve => Session.setUser(res.payload, resolve));
        }
        return onLoad;
    }

}