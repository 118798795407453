import React from 'react';
import { AuthLayout } from '@App/Layout';
import { LoginFormStore } from '@App/Service/Auth/Login/LoginFormStore';
import { Form } from '@Framework/Library/Form';
import { CheckField, InputField } from '@Framework/Component/FormField';
import { AuthModel } from '@App/Service/Auth/Model';
import { App } from '@Framework/Core/App';
import { ENotificationType } from '@Framework/Component/Notification';
import { Session } from '@Framework/Core/Session';
import moment from 'moment';

interface IProps {}
interface IState {
    dispatch : boolean,
}

export class Login extends React.Component<IProps, IState> {

    private form : LoginFormStore = new LoginFormStore();

    constructor(props) {
        super(props);
        this.state = {
            dispatch: false,
        };
    }

    public render() : React.ReactNode {
        return (
            <AuthLayout>
                <div className="row">
                    <div className="col col-sm-10 col-md-8 col-lg-6 offset-sm-1 offset-md-2 offset-lg-3">
                        <div className="card">
                            <div className="card-body p-5">
                                <h1>Login</h1>
                                <Form store={this.form} unsafe={true} onSubmit={() => this.onSubmit()}>
                                    <div className="form-group">
                                        <label>Username</label>
                                        <InputField disabled={this.state.dispatch} placeholder="Email" store={this.form.fields.username} />
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <InputField type="password" disabled={this.state.dispatch} placeholder="Password" store={this.form.fields.password} />
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <CheckField disabled={this.state.dispatch} store={this.form.fields.remember} /> Remember me
                                        </div>
                                        <div className="col-auto">
                                            <button type="submit" disabled={this.state.dispatch} className="btn btn-primary">
                                                {this.state.dispatch ? <span className="spinner-border spinner-border-sm"></span> : <i className="fa fa-sign-in" />} Login
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </AuthLayout>
        );
    }

    private onSubmit() : void {
        if(this.state.dispatch) return;
        this.form.validate();
        if(!this.form.isValid) return;
        (async () => {
            const values = this.form.getValues();
            this.setState({ dispatch: true });
            const res = await AuthModel.login(values, {
                onError: res => {
                    if(res.status == 0) {
                        App.notification({
                            type: ENotificationType.Error,
                            message: 'Service temporary unavailable.',
                        });
                    } else {
                        App.notification({
                            type: ENotificationType.Error,
                            message: 'Invalid credentials.',
                        });
                    }
                },
            });
            if(res.success) {
                Session.update({
                    access: res.payload.token.access,
                    refresh: values.remember ? res.payload.token.refresh : '',
                    expiresAt: values.remember ? moment().utc().unix() + SESSION_MAX_AGE : res.payload.token.expiresAt,
                });
                App.redirect('/');
            }
            this.setState({ dispatch: false });
        })();
    }

}