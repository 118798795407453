import React from 'react';
import { OnlineModel, OnlineModelStruct } from '@App/Service/Online/Model';
import { MainLayout } from '@App/Layout';
import { EModalSize, Link, Modal } from '@Framework/Factory';
import { Loading } from '@Framework/Component/Loading';
import { Pagination } from '@Framework/Component/Pagination';
import { DefaultStruct } from '@App/Structures';
import { App } from '@Framework/Core/App';
import { SortLink } from '@Framework/Component/SortLink';
import { FiltersForm } from '@App/Service/Online/List/FiltersForm';
import moment from 'moment/moment';
import { AuthModel } from '@App/Service/Auth/Model';
import { Session } from '@Framework/Core/Session';
import { DevicesModel } from '@App/Service/Companies/Details/Devices/Model/DevicesModel';
import { ENotificationType } from '@Framework/Component/Notification';

enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}
interface IProps {
    page ?: number,
    limit ?: number,
    orderBy ?: string,
    sort ?: string,
    companyId ?: number,
    userId ?: number,
}
interface IState {
    status : EStatus,
    data : OnlineModelStruct.IOnlineShort[],
    pagination : DefaultStruct.IPagination,
    filtersOpen : boolean,
}

export class List extends React.Component<IProps, IState> {

    private isMount : boolean = false;

    constructor(props) {
        super(props);
        this.state = {
            status: EStatus.Unloaded,
            data: [],
            pagination: null,
            filtersOpen: false,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.loadData();
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(
            this.props.page != prevProps.page ||
            this.props.limit != prevProps.limit ||
            this.props.orderBy != prevProps.orderBy ||
            this.props.sort != prevProps.sort ||
            this.props.companyId != prevProps.companyId ||
            this.props.userId != prevProps.userId
        ) this.loadData();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        let activeFilters = 0;
        if(this.props.companyId) activeFilters++;
        if(this.props.userId) activeFilters++;
        return (
            <MainLayout service="online">
                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="col">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link url="/"><a><i className="fa fa-home" /> Home</a></Link></li>
                                    <li className="breadcrumb-item active">Users</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="col">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col"><h3 className="m-0">Users</h3></div>
                                        <div className="col-auto d-lg-none">
                                            <button type="button" className="btn btn-primary btn-sm" onClick={() => this.setState({ filtersOpen: true })} style={{ margin: '-10px 0' }}>
                                                <i className="fa fa-cogs" /> Filters {activeFilters > 0 && <span className="badge bg-info">{activeFilters}</span>}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {this.state.status == EStatus.Loading && <Loading label="Loading..." />}
                                {this.state.status == EStatus.Failed && <div className="alert alert-danger"><i className="fa fa-exclamation-triangle" /> Stats loading error</div>}
                                {this.state.status == EStatus.Loaded &&
                                    <>
                                        <div className="card-body p-0 d-none d-md-block">
                                            <div className="table-responsive">
                                                <table className="table table-hover table-striped table-wide m-0">
                                                    <thead>
                                                        <tr>
                                                            <th><SortLink name="fullname">User Name</SortLink></th>
                                                            <th>Company</th>
                                                            <th><SortLink name="ip">Ip</SortLink></th>
                                                            <th><SortLink name="tm_u">last Action</SortLink></th>
                                                            <th>Device Type</th>
                                                            <th>System</th>
                                                            <th/>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.data.map(item => (
                                                            <tr key={item.token}>
                                                                <td>
                                                                    {item.userName}
                                                                </td>
                                                                <td>
                                                                    {item.companyName}
                                                                </td>
                                                                <td>
                                                                    {item.ipS}
                                                                </td>
                                                                <td>
                                                                    {moment.unix(item.tmU).fromNow()}
                                                                </td>
                                                                <td>
                                                                    {item.devType}
                                                                </td>
                                                                <td>
                                                                    {item.devBrand &&
                                                                        <>
                                                                            {item.devBrand}/
                                                                        </>
                                                                    }{item.devOs}
                                                                </td>
                                                                <td className="text-end">
                                                                    <button className="btn btn-xs btn-primary" onClick={() => this.logout(item.token)}>Logout</button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-body py-0 d-md-none">
                                            {this.state.data.map(item => (
                                                <div className="item-block" key={item.token}>
                                                    <div className="item-body">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span className="prop">Name: </span>{item.companyName}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span className="prop">Email: </span>{item.userName}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="card-footer">
                                            <Pagination limit={this.props.limit} { ...this.state.pagination } />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        {this.state.status == EStatus.Loaded &&
                            <div className="col-auto d-none d-lg-block" style={{ width: '220px' }}>
                                <div className="card">
                                    <div className="card-header">Filters {activeFilters > 0 && <span className="badge bg-info">{activeFilters}</span>}</div>
                                    <div className="card-body">
                                        <FiltersForm
                                            companyId={this.props.companyId}
                                            userId={this.props.userId}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {this.state.filtersOpen &&
                    <Modal
                        size={EModalSize.SM}
                        class="d-lg-none"
                        onClickOutside={() => this.setState({ filtersOpen: false })}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">Filters {activeFilters > 0 && <span className="badge bg-info">{activeFilters}</span>}</h5>
                            <button type="button" className="btn btn-light btn-sm" onClick={() => this.setState({ filtersOpen: false })}>
                                <i className="fa fa-times"/>
                            </button>
                        </div>
                        <div className="modal-body">
                            <FiltersForm
                                companyId={this.props.companyId}
                                userId={this.props.userId}
                                onChange={() => this.setState({ filtersOpen: false })}
                            />
                        </div>
                    </Modal>
                }
            </MainLayout>
        );
    }

    private loadData() : void {
        (async () => {
            this.setState({ status: EStatus.Loading });
            const res = await OnlineModel.list(this.props);
            console.log(res.payload);
            if(!this.isMount) return;
            if(res.success && res.payload) {
                this.setState({
                    status: EStatus.Loaded,
                    data: res.payload.data,
                    pagination: res.payload.pagination,
                });
            } else this.setState({ status: EStatus.Failed });
        })();
    }

    private logout(token : string) : void {
        (async () => {
            const res = await AuthModel.logoutUsers({ token });
            if(res.success) {
                App.notification({
                    type: ENotificationType.Success,
                    title: 'Success',
                    message: 'Successfully deleted.',
                });
                this.loadData();
            }
        })();
    }

}