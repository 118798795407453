import { EFieldType, Field, Group, Rule } from '@Framework/Library/Form';
import { makeObservable, override } from 'mobx';

type TFields = {
    name : Field,
    priorityId : Field,
    isActive : Field,
    groupId : Field,
    locationId : Field,
    port : Field,
    notes : Field,
    deviceId : Field,
    dataMinStep : Field,
    valueTypeId : Field,
    // iconId : Field,
    // valueConfigTypeId : Field,
    // valueConfigTemplateId : Field,
    // valueConfigName : Field,
    // valueConfigMin : Field,
    // valueConfigMax : Field,
    // valueConfigExt : Field,
    // valueConfigThousands : Field,
    // valueConfigFormat : Field,
    // valueConfigFormula : Field,
    // valueConfigConvert : Field,
};

interface IValues {
    name : string,
    priorityId : number,
    isActive : boolean,
    groupId : number,
    locationId : number,
    port : string,
    notes : string,
    deviceId : number,
    dataMinStep : number,
    valueTypeId : number,
    // iconId : number,
    // valueConfigTypeId : number,
    // valueConfigTemplateId : number,
    // valueConfigName : string,
    // valueConfigMin : number,
    // valueConfigMax : number,
    // valueConfigExt : string,
    // valueConfigThousands : string,
    // valueConfigFormat : string,
    // valueConfigFormula : string,
    // valueConfigConvert : string,
}

export class DetailsFormStore extends Group<TFields, IValues> {

    @override protected _fields = {
        name: new Field({
            rules: [ Rule.required() ],
        }),
        deviceId: new Field({
            type: EFieldType.Number,
            rules: [ Rule.required() ],
        }),
        priorityId: new Field({
            type: EFieldType.Number,
        }),
        isActive: new Field({
            type: EFieldType.Boolean,
        }),
        groupId: new Field({
            type: EFieldType.Number,
        }),
        valueTypeId: new Field({
            type: EFieldType.Number,
        }),
        locationId: new Field({
            type: EFieldType.Number,
        }),
        port: new Field({
            rules: [ Rule.required() ],
        }),
        notes: new Field(),
        dataMinStep: new Field({
            type: EFieldType.Number,
            rules: [
                Rule.number({ noDecimal: true }),
            ],
        }),
        // iconId: new Field({
        //     rules: [ Rule.required() ],
        // }),
        // valueConfigTypeId: new Field({
        //     type: EFieldType.Number,
        // }),
        // valueConfigTemplateId: new Field({
        //     type: EFieldType.Number,
        // }),
        // valueConfigName: new Field({
        //     rules: [ Rule.required() ],
        // }),
        // valueConfigMin: new Field({
        //     type: EFieldType.Number,
        // }),
        // valueConfigMax: new Field({
        //     type: EFieldType.Number,
        // }),
        // valueConfigExt: new Field(),
        // valueConfigThousands: new Field(),
        // valueConfigFormat: new Field(),
        // valueConfigFormula: new Field(),
        // valueConfigConvert: new Field(),
    };

    constructor() {
        super(null, true);
        makeObservable(this);
    }

}