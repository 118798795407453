import { App } from '@Framework/Core/App';
import { Userdata } from '@App/Middleware';

// setup services
App.init(function (map) {
    map.middleware(new Userdata());
    App.registerService(require('@App/Service/Auth/routes').register);
    App.registerService(require('@App/Service/Dashboard/routes').register);
    App.registerService(require('@App/Service/Users/routes').register);
    App.registerService(require('@App/Service/Companies/routes').register);
    App.registerService(require('@App/Service/Logs/routes').register);
    App.registerService(require('@App/Service/Online/routes').register);
});