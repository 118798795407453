import { TRuleType, IRuleResult } from '@Framework/Library/Form/Rule';
import moment from 'moment';
import { Locale } from '@Framework/Core/Locale';

interface IProps {
    type ?: ERuleDateMode,
    min ?: number,
    max ?: number,
    less ?: number,
    greater ?: number,
    inPast ?: boolean,
    inFuture ?: boolean,
    invalidMessage ?: string,
    minMessage ?: string,
    maxMessage ?: string,
    lessMessage ?: string,
    greaterMessage ?: string,
    inPastMessage ?: string,
    inFutureMessage ?: string,
}

export enum ERuleDateMode {
    Date,
    Time,
    DateTime,
}

export function ruleDate(props : IProps = {}) : TRuleType {
    const lang = Locale.Lang.rules.date;
    if(!props.hasOwnProperty('type')) props.type = ERuleDateMode.Date;
    return function (value : string) : IRuleResult {
        if(value.length < 1) return { isValid: true };
        const pattern = props.type == ERuleDateMode.Time
            ? /^\d{2}(:\d{2}){1,2}$/
            : props.type == ERuleDateMode.DateTime
                ? /^\d{4}-\d{2}-\d{2} \d{2}(:\d{2}){1,2}$/
                : /^\d{4}-\d{2}-\d{2}$/;
        if(!value.match(pattern)) {
            return {
                isValid: false,
                message: props.invalidMessage || lang.invalidMessage(),
            };
        }
        const timestamp = moment.utc(value).unix();
        if(props.hasOwnProperty('min') && timestamp < moment.utc(props.min).unix()) {
            return {
                isValid: false,
                message: props.minMessage || lang.minMessage(props.min),
            };
        }
        if(props.hasOwnProperty('max') && timestamp > moment.utc(props.max).unix()) {
            return {
                isValid: false,
                message: props.maxMessage || lang.maxMessage(props.max),
            };
        }
        if(props.hasOwnProperty('less') && timestamp >= moment.utc(props.less).unix()) {
            return {
                isValid: false,
                message: props.lessMessage || lang.lessMessage(props.less),
            };
        }
        if(props.hasOwnProperty('greater') && timestamp <= moment.utc(props.greater).unix()) {
            return {
                isValid: false,
                message: props.greaterMessage || lang.greaterMessage(props.greater),
            };
        }
        if(props.inPast && timestamp >= moment.utc().unix()) {
            return {
                isValid: false,
                message: props.lessMessage || 'Should be in past.',
            };
        }
        if(props.inFuture && timestamp <= moment.utc().unix()) {
            return {
                isValid: false,
                message: props.lessMessage || 'Should be in future.',
            };
        }
        return { isValid: true };
    };
}