export namespace OnlineModelStruct {

    export interface IOnlineShort {
        client : string,
        token : string,
        companyId : number,
        companyName : string,
        userId : number,
        userName : string,
        ip : string,
        ipS : string,
        tmA : number,
        tmU : number,
        tmE : number,
        devType : string,
        devOs : string,
        devBrand : string,
        devModel : string,
        devBrowser : string,
        source : string,
    }
    export interface IOnlineShortSource {
        client : string,
        token : string,
        company_id : string,
        company_name : string,
        user_id : string,
        user_name : string,
        ip : string,
        ip_s : string,
        tm_a : string,
        tm_u : string,
        tm_e : string,
        dev_type : string,
        dev_os : string,
        dev_brand : string,
        dev_model : string,
        dev_browser : string,
        source : string,
    }
}