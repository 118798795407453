import { makeObservable, observable, computed } from 'mobx';
import { Form, IMember } from '@Framework/Library/Form';

type TSetFields<TFields> = () => TFields;

export class Group<TFields extends { [key : string] : IMember } = any, TValues = any> implements IMember {

    protected readonly _id : number = Form.UniqueID;
    @observable protected _fields : TFields;

    public get id() : number {
        return this._id;
    }

    public get fields() : TFields {
        return this._fields;
    }

    @computed public get isValid() : boolean {
        for(const key in this._fields) {
            if(!this._fields.hasOwnProperty(key)) continue;
            if(!this._fields[key].isValid) return false;
        }
        return true;
    }

    @computed public get isSaved() : boolean {
        for(const key in this._fields) {
            if(!this._fields.hasOwnProperty(key)) continue;
            if(!this._fields[key].isSaved) return false;
        }
        return true;
    }

    constructor(setFields : TSetFields<TFields> = null, isSuper : boolean = false) {
        this._fields = setFields ? setFields() : {} as TFields;
        if(!isSuper) makeObservable(this);
    }

    public validate() : Group<TFields, TValues> {
        for(const key in this._fields) {
            if(!this._fields.hasOwnProperty(key)) continue;
            this._fields[key].validate();
        }
        return this;
    }

    public restore() : Group<TFields, TValues> {
        for(const key in this._fields) {
            if(!this._fields.hasOwnProperty(key)) continue;
            this._fields[key].restore();
        }
        return this;
    }

    public save() : Group<TFields, TValues> {
        for(const key in this._fields) {
            if(!this._fields.hasOwnProperty(key)) continue;
            this._fields[key].save();
        }
        return this;
    }

    public fill(values : TValues) : Group<TFields, TValues> {
        for(const key in values) {
            if(!values.hasOwnProperty(key) || !this._fields.hasOwnProperty(key)) continue;
            this._fields[key].fill(values[key]);
        }
        return this;
    }

    public clear() : Group<TFields, TValues> {
        return this;
    }

    public getValues() : TValues {
        const values : any = {};
        for(const key in this._fields) {
            if(!this._fields.hasOwnProperty(key)) continue;
            values[key] = this._fields[key].getValues();
        }
        return values;
    }

}