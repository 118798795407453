import React from 'react';
import { FormField, IFormFieldProps } from '@Framework/Component/FormField';

interface IProps extends IFormFieldProps {
    id ?: string,
}

export class CheckField extends FormField<IProps> {

    protected readonly _defaultInline : boolean = true;

    protected renderField() : React.ReactElement {
        return (
            <input
                id={this.props.id}
                type="checkbox"
                readOnly={!!this.props.readonly}
                disabled={!!this.props.disabled}
                className={`form-check-input ${this.props.store.isValid ? '' : ' is-invalid'}`}
                checked={this.props.store.value == '1'}
                onChange={e => this.props.store.value = e.target.checked ? '1' : '0'}
                autoComplete="off"
            />
        );
    }

}