import React from 'react';
import { CreateFormStore } from '@App/Service/Companies/Details/DataSources/Create/CreateFormStore';
import { Form } from '@Framework/Library/Form';
import { InputField, SelectField, SwitchField, TextField } from '@Framework/Component/FormField';
import { observer } from 'mobx-react';
import { App } from '@Framework/Core/App';
import { ENotificationType } from '@Framework/Component/Notification';
import { Details, ETab } from '@App/Service/Companies/Details/Details';
import { Link } from '@Framework/Factory';
import { DefaultStruct } from '@App/Structures';
import { DataSourcesModel } from '@App/Service/Companies/Details/DataSources/Model/DataSourcesModel';

export enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}

interface IProps {
    companyId : number;
    createType : number;
}
interface IState {
    saving : boolean,
    status : EStatus,
    groups : DefaultStruct.IOption[],
    locations : DefaultStruct.IOption[],
    openPassword : boolean,
}

@observer
export class Create extends React.Component<IProps, IState> {

    private isMount : boolean = false;
    private form : CreateFormStore = new CreateFormStore();

    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            status: EStatus.Unloaded,
            groups: [],
            locations: [],
            openPassword: false,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.form.save();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        return (
            <Details
                id={this.props.companyId}
                tab={ETab.DataSources}
                title={<h3 className="m-0">Add Data Sources</h3>}
                breadcrumb={
                    <>
                        <li className="breadcrumb-item">
                            <Link url={`companies/${this.props.companyId}/devices`}><a>Data Sources</a></Link>
                        </li>
                        <li className="breadcrumb-item">Add Data Sources</li>
                    </>
                }
            >
                {this.props.createType == 1 &&
                    <Form store={this.form} onSubmit={() => this.onSubmit()}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Name <span className="text-danger">*</span></label>
                                        <InputField disabled={this.state.saving} store={this.form.fields.name}/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Active</label>
                                        <SwitchField disabled={this.state.saving} store={this.form.fields.active} wide={true}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Host Name</label>
                                        <InputField disabled={this.state.saving} store={this.form.fields.hostname}/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Port</label>
                                        <InputField disabled={this.state.saving} store={this.form.fields.port}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>User Name</label>
                                        <InputField disabled={this.state.saving} store={this.form.fields.username}/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Password</label>
                                        <InputField disabled={this.state.saving} store={this.form.fields.password}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>SSL</label>
                                        <SwitchField disabled={this.state.saving} store={this.form.fields.option1num} wide={true}/>
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label>Version</label>
                                        <SelectField disabled={this.state.saving} store={this.form.fields.option2num}>
                                            <option value="3">3</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </SelectField>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="row align-items-center">
                                <div className="col"/>
                                <div className="col-auto">
                                    {!this.form.isValid && <div className="form-notice text-danger">
                                        <i className="fa fa-exclamation-circle"/> Validation errors</div>}
                                    {!this.form.isSaved && <div className="form-notice text-warning">
                                        <i className="fa fa-exclamation-triangle"/> Unsaved changes</div>}
                                </div>
                                <div className="col-auto">
                                    <button type="submit" disabled={this.state.saving} className="btn btn-primary">
                                        {this.state.saving
                                            ? <span className="spinner-border spinner-border-sm"/>
                                            : <i className="fa fa-check"/>
                                        } Create
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                }
                {this.props.createType != 1 &&
                    <Form store={this.form} onSubmit={() => this.onSubmit()}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Name <span className="text-danger">*</span></label>
                                        <InputField disabled={this.state.saving} store={this.form.fields.name}/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Active</label>
                                        <SwitchField disabled={this.state.saving} store={this.form.fields.active} wide={true}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>API Key</label>
                                        <InputField disabled={this.state.saving} store={this.form.fields.option1str}/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>SSL</label>
                                        <SwitchField disabled={this.state.saving} store={this.form.fields.option1num} wide={true}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="row align-items-center">
                                <div className="col"/>
                                <div className="col-auto">
                                    {!this.form.isValid && <div className="form-notice text-danger">
                                        <i className="fa fa-exclamation-circle"/> Validation errors</div>}
                                    {!this.form.isSaved && <div className="form-notice text-warning">
                                        <i className="fa fa-exclamation-triangle"/> Unsaved changes</div>}
                                </div>
                                <div className="col-auto">
                                    <button type="submit" disabled={this.state.saving} className="btn btn-primary">
                                        {this.state.saving
                                            ? <span className="spinner-border spinner-border-sm"/>
                                            : <i className="fa fa-check"/>
                                        } Create
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                }
            </Details>
        );
    }

    private onSubmit() : void {
        this.form.validate();
        if(!this.form.isValid) return;
        this.setState({ saving: true });
        (async () => {
            const res = await DataSourcesModel.create({ ...this.form.getValues(), type: this.props.createType });
            if(!this.isMount) return;
            if(res.success) {
                this.form.save();
                App.notification({
                    type: ENotificationType.Success,
                    title: 'Success',
                    message: 'Successfully created.',
                });
                App.redirect(`companies/${this.props.companyId}/data-sources`);
            }
            this.setState({ saving: true });
        })();
    }

}