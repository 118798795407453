import { TRuleType, IRuleResult } from '@Framework/Library/Form/Rule';
import { Field } from '@Framework/Library/Form';
import { Locale } from '@Framework/Core/Locale';

interface IProps {
    field : Field,
    message ?: string
}

export function ruleMatches(props : IProps) : TRuleType {
    const lang = Locale.Lang.rules.matches;
    return function (value : string) : IRuleResult {
        if(value != props.field.value) {
            return {
                isValid: false,
                message: props.message || lang.notMatchMessage(),
            };
        }
        return { isValid: true };
    };
}