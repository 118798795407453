import { Inquiry, ERequestMethod } from '@Framework/Library/Gateway/Inquiry';
import { Reply, EResponseType, TResponseHandler } from '@Framework/Library/Gateway/Reply';

interface IProps<TPayload, TResponse> {
    host : string,
    path : string,
    query ?: Record<string, string>,
    method ?: ERequestMethod,
    headers ?: Record<string, string>,
    payload ?: TPayload,
    responseType ?: EResponseType,
    responseHandler ?: TResponseHandler<TResponse>,
    useCustomStatus ?: boolean,
}

export class Gateway<TRequestPayload = any, TResponsePayload = any> {

    private readonly _inquiry : Inquiry<TRequestPayload>;
    private readonly _reply : Reply<TResponsePayload>;

    public get request() : Inquiry<TRequestPayload> {
        return this._inquiry;
    }

    public get response() : Reply<TResponsePayload> {
        return this._reply;
    }

    constructor(props : IProps<TRequestPayload, TResponsePayload>) {
        this._inquiry = new Inquiry<TRequestPayload>(props);
        this._reply = new Reply<TResponsePayload>(props);
    }

    public exec() : Promise<Reply<TResponsePayload>> {
        return new Promise<Reply<TResponsePayload>>((resolve, reject) => {
            fetch(this._inquiry.url, {
                method: this._inquiry.method,
                headers: this._inquiry.headers,
                body: this._inquiry.payload,
            }).catch(error => {
                this._reply.write({
                    success: false,
                    status: 0,
                    failure: { message: error.toString() },
                }).then(() => resolve(this._reply));
            }).then(res => {
                this._reply.write(res || null)
                    .catch(error => reject(error))
                    .then(() => resolve(this._reply));
            });
        });
    }

}