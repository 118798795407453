export { List } from '@App/Service/Companies/List/List';
export { Create } from '@App/Service/Companies/Create/Create';
export { Details } from '@App/Service/Companies/Details/Details';
export { Settings } from '@App/Service/Companies/Details/Settings/Settings';
export { List as ListDevices } from '@App/Service/Companies/Details/Devices/List/List';
export { Create as CreateDevices } from '@App/Service/Companies/Details/Devices/Create/Create';
export { Details as DetailsDevices } from '@App/Service/Companies/Details/Devices/Details/Details';
export { List as ListDataSources } from '@App/Service/Companies/Details/DataSources/List/List';
export { Create as CreateDataSources } from '@App/Service/Companies/Details/DataSources/Create/Create';
export { Details as DetailsDataSources } from '@App/Service/Companies/Details/DataSources/Details/Details';
export { List as ListLogs } from '@App/Service/Companies/Details/Logs/List/List';
export { Details as DetailsLogs } from '@App/Service/Companies/Details/Logs/Details/Details';
export { List as ListSensors } from '@App/Service/Companies/Details/Sensors/List/List';
export { Create as CreateSensors } from '@App/Service/Companies/Details/Sensors/Create/Create';
export { Details as DetailsSensors } from '@App/Service/Companies/Details/Sensors/Details/Details';
export { List as ListSets } from '@App/Service/Companies/Details/Sets/List/List';
export { Details as DetailsSets } from '@App/Service/Companies/Details/Sets/Details/Details';
export { Create as CreateSets } from '@App/Service/Companies/Details/Sets/Create/Create';
// export { Create as CreateSensors } from '@App/Service/Companies/Details/Sensors/Create/Create';
// export { Details as DetailsSensors } from '@App/Service/Companies/Details/Sensors/Details/Details';