import React from 'react';
import { EModalSize, Link, Modal } from '@Framework/Factory';
import { Loading } from '@Framework/Component/Loading';
import { Pagination } from '@Framework/Component/Pagination';
import { DefaultStruct } from '@App/Structures';
import { App } from '@Framework/Core/App';
import { SortLink } from '@Framework/Component/SortLink';
import { FiltersForm } from '@App/Service/Companies/Details/Devices/List/FiltersForm';
import { DevicesModel } from '@App/Service/Companies/Details/Devices/Model/DevicesModel';
import { DevicesModelStruct } from '@App/Service/Companies/Details/Devices/Model/DevicesModel.struct';
import moment from 'moment';
import { Format } from '@App/Config/Format';
import { Details, ETab } from '@App/Service/Companies/Details/Details';

enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}
interface IProps {
    companyId : number,
    page ?: number,
    limit ?: number,
    orderBy ?: string,
    sort ?: string,
    status ?: number,
}
interface IState {
    status : EStatus,
    data : DevicesModelStruct.IDevicesShort[],
    pagination : DefaultStruct.IPagination,
    filtersOpen : boolean,
}

export class List extends React.Component<IProps, IState> {

    private isMount : boolean = false;

    constructor(props) {
        super(props);
        this.state = {
            status: EStatus.Unloaded,
            data: [],
            pagination: null,
            filtersOpen: false,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.loadData();
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(
            this.props.page != prevProps.page ||
            this.props.limit != prevProps.limit ||
            this.props.orderBy != prevProps.orderBy ||
            this.props.sort != prevProps.sort ||
            this.props.status != prevProps.status
        ) this.loadData();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        let activeFilters = 0;
        if(this.props.status) activeFilters++;

        const statusDevice = function (status : string) : string {
            if(status == 'online') return 'success';
            if(status == 'offline') return 'info';
            return 'secondary';
        };

        return (
            <Details
                id={this.props.companyId}
                tab={ETab.Devices}
                title={<h3 className="m-0">Devices</h3>}
                controls={
                    <>
                        <button type="button" className="btn btn-outline-primary btn-sm d-lg-none " onClick={() => this.setState({ filtersOpen: true })} style={{ margin: '-10px 0' }}>
                            <i className="fa fa-cogs" /> Filters {activeFilters > 0 && <span className="badge bg-info">{activeFilters}</span>}
                        </button>
                        {' '}
                        <Link url={`companies/${this.props.companyId}/devices/create`}><a className="btn btn-sm btn-primary"><i className="fa fa-plus" /> Add Devices</a></Link>
                    </>
                }
                rightSide={
                    this.state.status == EStatus.Loaded
                        ? <div className="col-auto d-none d-lg-block" style={{ width: '220px' }}>
                            <div className="card">
                                <div className="card-header">Filters {activeFilters > 0 && <span className="badge bg-info">{activeFilters}</span>}</div>
                                <div className="card-body">
                                    <FiltersForm
                                        status={this.props.status}
                                        id={this.props.companyId}
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                }
                breadcrumb={
                    <>
                        <li className="breadcrumb-item">Devices</li>
                    </>
                }
            >
                {this.state.status != EStatus.Loaded &&
                    <div className="card-body">
                        {this.state.status == EStatus.Loading && <Loading />}
                        {this.state.status == EStatus.Failed && <div className="alert alert-danger"><i className="fa fa-exclamation-triangle" /> Loading error...</div>}
                    </div>
                }
                {this.state.status == EStatus.Loaded &&
                    <>
                        <div className="card-body p-0 d-none d-md-block">
                            <div className="table-responsive">
                                {!this.props.status &&
                                    <table className="table table-hover table-striped table-wide m-0">
                                        <thead>
                                            <tr>
                                                <th><SortLink name="device_id">Device Id</SortLink></th>
                                                <th><SortLink name="status">Status</SortLink></th>
                                                <th>Sensors</th>
                                                <th><SortLink name="alerts">Alerts</SortLink></th>
                                                <th><SortLink name="updated">Updated</SortLink></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.data.map(item => (
                                                <tr key={item.deviceId}>
                                                    <td onClick={() => this.openDetails(item.deviceId)}>
                                                        {item.name ? item.name : item.uid}
                                                    </td>
                                                    <td onClick={() => this.openDetails(item.deviceId)}>
                                                        <span className={`badge bg-${statusDevice(item.statusText)}`}>{item.statusText}</span>
                                                    </td>
                                                    <td onClick={() => this.openDetails(item.deviceId)}>
                                                        {item.ports}/{item.portsActive}
                                                    </td>
                                                    <td onClick={() => this.openDetails(item.deviceId)}>
                                                        <span className={`badge bg-${item.alerts ? 'danger' : 'secondary'}`}>{item.alerts}</span>
                                                    </td>
                                                    <td onClick={() => this.openDetails(item.deviceId)}>
                                                        {moment.unix(item.time).fromNow()}
                                                    </td>
                                                    <td className="text-end">
                                                        <Link url={`companies/${this.props.companyId}/devices/${item.deviceId}`}>
                                                            <a className="btn btn-xs btn-primary">
                                                                <i className="fa fa-eye"/> Details
                                                            </a>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                }
                                {this.props.status == 1 &&
                                    <table className="table table-hover table-striped table-wide m-0">
                                        <thead>
                                            <tr>
                                                <th><SortLink name="uid">UID</SortLink></th>
                                                <th><SortLink name="">Date</SortLink></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.data.map(item => (
                                                <tr key={item.deviceId}>
                                                    <td>
                                                        {item.uid}
                                                    </td>
                                                    <td>
                                                        {moment.unix(item.time).format(`${Format.Date.YN} ${Format.Time.M}`)}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                }
                                {this.props.status == 2 &&
                                    <table className="table table-hover table-striped table-wide m-0">
                                        <thead>
                                            <tr>
                                                <th><SortLink name="uid">UID</SortLink></th>
                                                <th><SortLink name="name">Name</SortLink></th>
                                                <th>Notes</th>
                                                <th><SortLink name="status">Status</SortLink></th>
                                                <th className="text-end"><SortLink name="updated">Updated</SortLink></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.data.map(item => (
                                                <tr key={item.deviceId}>
                                                    <td>
                                                        {item.uid}
                                                    </td>
                                                    <td>
                                                        {item.name}
                                                    </td>
                                                    <td>
                                                        {item.notes}
                                                    </td>
                                                    <td>
                                                        <span className={`badge bg-${statusDevice(item.statusText)}`}>{item.statusText}</span>
                                                    </td>
                                                    <td className="text-end">
                                                        {moment.unix(item.time).fromNow()}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                        <div className="card-footer">
                            <Pagination limit={this.props.limit} { ...this.state.pagination } />
                        </div>
                    </>
                }
                {this.state.filtersOpen &&
                    <Modal
                        size={EModalSize.SM}
                        class="d-lg-none"
                        onClickOutside={() => this.setState({ filtersOpen: false })}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">Filters {activeFilters > 0 && <span className="badge bg-info">{activeFilters}</span>}</h5>
                            <button type="button" className="btn btn-light btn-sm" onClick={() => this.setState({ filtersOpen: false })}>
                                <i className="fa fa-times"/>
                            </button>
                        </div>
                        <div className="modal-body">
                            <FiltersForm
                                status={this.props.status}
                                id={this.props.companyId}
                                onChange={() => this.setState({ filtersOpen: false })}
                            />
                        </div>
                    </Modal>
                }
            </Details>
        );
    }

    private loadData() : void {
        (async () => {
            this.setState({ status: EStatus.Loading });
            const res = await DevicesModel.list(this.props);
            if(!this.isMount) return;
            if(res.success && res.payload) {
                this.setState({
                    status: EStatus.Loaded,
                    data: res.payload.data,
                    pagination: res.payload.pagination,
                });
            } else this.setState({ status: EStatus.Failed });
        })();
    }

    private openDetails(id : string) : void {
        App.redirect(`companies/${this.props.companyId}/devices/${id}`);
    }

}