import React from 'react';
import { Link } from '@Framework/Factory';
import { Location } from '@Framework/Core/Location';

interface Props {
    limit ?: number,
    pages ?: number,
    page ?: number,
    url ?: string,
    limits ?: number[],
    buttonClasses ?: IButtons,
}
interface State {
    openLimitPopup : boolean,
}
interface IButtons {
    firstLast ?: string,
    prevNext ?: string,
    numbers ?: string,
    current ?: string,
    limit ?: string,
}

export class Pagination extends React.Component<Props, State> {

    public static buttonClasses : IButtons = {};
    public static limits : number[] = [];

    constructor(props) {
        super(props);
        this.state = {
            openLimitPopup: false,
        };
    }

    render() {
        const page = this.props.page || 1;
        const pages = this.props.pages || 1;
        const limits = this.props.limits || Pagination.limits;
        const buttonClasses = {
            firstLast: (this.props.buttonClasses || {}).firstLast || Pagination.buttonClasses.firstLast || 'btn-light',
            prevNext: (this.props.buttonClasses || {}).prevNext || Pagination.buttonClasses.prevNext || 'btn-light',
            numbers: (this.props.buttonClasses || {}).numbers || Pagination.buttonClasses.numbers || 'btn-light',
            current: (this.props.buttonClasses || {}).current || Pagination.buttonClasses.current || 'btn-light',
            limit: (this.props.buttonClasses || {}).limit || Pagination.buttonClasses.limit || 'btn-secondary',
        };
        return (
            <div id="pagination">
                <div className="row align-items-center">
                    <div className="col text-center">
                        <div className="btn-group">
                            {page > 4 &&
                                <Link url={this.getLink(1)}>
                                    <a className={`btn ${buttonClasses.firstLast}`}>1</a>
                                </Link>
                            }
                            {page > 1 &&
                                <Link url={this.getLink(page - 1)}>
                                    <a className={`btn ${buttonClasses.prevNext}`}><i className="fa fa-chevron-left" /></a>
                                </Link>
                            }
                            {page > 1 && new Array(page > 3 ? 3 : page - 1).fill(0).map((p, i, a) => (
                                <Link key={i} url={this.getLink(page - a.length + i)}>
                                    <a className={`btn ${buttonClasses.numbers}`}>{page - a.length + i}</a>
                                </Link>
                            ))}
                            <button type="button" disabled={true} className={`btn ${buttonClasses.current}`}>{page}</button>
                            {page < pages && new Array(pages - page > 3 ? 3 : pages - page).fill(0).map((p, i) => (
                                <Link key={i} url={this.getLink(page + 1 + i)}>
                                    <a className={`btn ${buttonClasses.numbers}`}>{page + 1 + i}</a>
                                </Link>
                            ))}
                            {page < pages &&
                                <Link url={this.getLink(page + 1)}>
                                    <a className={`btn ${buttonClasses.prevNext}`}><i className="fa fa-chevron-right" /></a>
                                </Link>
                            }
                            {page < pages - 3 &&
                                <Link url={this.getLink(pages)}>
                                    <a className={`btn ${buttonClasses.firstLast}`}>{pages}</a>
                                </Link>
                            }
                        </div>
                    </div>
                    {limits &&
                        <div className="col-auto">
                            <div className="dropdown" onClick={() => this.setState({ openLimitPopup: !this.state.openLimitPopup })}>
                                <button className={`btn ${buttonClasses.limit} dropdown-toggle`} type="button">
                                    {this.props.limit}
                                </button>
                                {!!this.state.openLimitPopup &&
                                    <ul className="dropdown-menu show" style={{ left: 'auto', right: '0', top: 0, bottom: 'auto', transform: 'translate(0, -100%)' }}>
                                        {limits.map(num => (
                                            <li key={num}><Link url={this.getLink(1, num)}><a className="dropdown-item">{num}</a></Link></li>
                                        ))}
                                    </ul>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }

    private getLink(page : number, limit ?: number) : string {
        const pathData = Location.parseUrl(this.props.url ? this.props.url : Location.url);
        pathData.params.page = String(page);
        if(limit) pathData.params.limit = String(limit);
        const newQuery = [];
        for(const key in pathData.params) newQuery.push(`${key}=${pathData.params[key]}`);
        return `/${pathData.segments.join('/')}?${newQuery.join('&')}`;
    }

}