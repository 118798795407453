import React from 'react';

interface IProps {
    startIndex : number,
    daysInMonth : number,
    prevMonthEndDay : number,
    today : number,
    active : number,
    onSelected ?: (day : number, month : number) => void,
}
interface IState {}

export class CalendarWeek extends React.Component<IProps, IState> {

    public render() : React.ReactNode {
        return (
            <div className="calendar-week">
                {new Array(7).fill(null).map((_, i) => {
                    const index = this.props.startIndex + i + 1;
                    const inMonth = Boolean(index > 0 && index <= this.props.daysInMonth);
                    const day = inMonth
                        ? index
                        : index <= 0
                            ? (this.props.prevMonthEndDay + this.props.startIndex) + i + 1
                            : index - this.props.daysInMonth;
                    return (
                        <div key={i} className={`${inMonth ? 'calendar-date' : 'calendar-out-date'}${index == this.props.today ? ' today' : ''}${index == this.props.active ? ' active' : ''}`} onClick={() => this.onSelect(day, inMonth ? 0 : index <= 0 ? -1 : 1)}>
                            {day}
                        </div>
                    );
                })}
            </div>
        );
    }

    private onSelect(day : number, month : number = 0) : void {
        if(this.props.onSelected) this.props.onSelected(day, month);
    }

}