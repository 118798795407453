import { DefaultStruct } from '@App/Structures';
import { OnlineModelStruct } from '@App/Service/Online/Model/OnlineModel.struct';

export namespace OnlineModelRes {

    export namespace List {
        export interface IPayload {
            data : OnlineModelStruct.IOnlineShort[],
            pagination : DefaultStruct.IPagination,
        }
        export interface IPayloadSource {
            data : OnlineModelStruct.IOnlineShortSource[],
            status : number,
            paginator : DefaultStruct.IPaginationSource,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            try {
                return {
                    data: source.data.map(item => ({
                        client: item.client || '',
                        token: item.token || '',
                        companyId: Number(item.company_id) || null,
                        companyName: item.company_name || '',
                        userId: Number(item.user_id) || null,
                        userName: item.user_name || '',
                        ip: item.ip || '',
                        ipS: item.ip_s || '',
                        tmA: Number(item.tm_a) || null,
                        tmU: Number(item.tm_u) || null,
                        tmE: Number(item.tm_e) || null,
                        devType: item.dev_type || '',
                        devOs: item.dev_os || '',
                        devBrand: item.dev_brand || '',
                        devModel: item.dev_model || '',
                        devBrowser: item.dev_browser || '',
                        source: item.source || '',
                    })),
                    pagination: {
                        limit: Number(source.paginator.limit),
                        page: Number(source.paginator.page),
                        pages: Number(source.paginator.pages),
                        total: Number(source.paginator.total),
                    },
                };
            } catch {
                return null;
            }
        }
    }

}