import React from 'react';
import { Provider } from 'mobx-react';
import { IMember } from '@Framework/Library/Form';

interface IProps {
    children : React.ReactNode,
    store : IMember,
    onSubmit ?: Function,
    unsafe ?: boolean,
}
interface IState {}

export class Form extends React.Component<IProps, IState> {

    private static _uniqueID : number = 0;
    private static _safeForms : Form[] = [];

    public static get UniqueID() : number {
        return ++Form._uniqueID;
    }

    public static get unsaved() : boolean {
        for(let i = 0; i < Form._safeForms.length; i++) {
            if(!Form._safeForms[i].props.store.isSaved) return true;
        }
        return false;
    }

    public componentDidMount() : void {
        if(!this.props.unsafe) Form._safeForms.push(this);
    }

    public componentWillUnmount() : void {
        if(!this.props.unsafe) {
            for(let i = 0; i < Form._safeForms.length; i++) {
                if(this != Form._safeForms[i]) continue;
                Form._safeForms.splice(i, 1);
                break;
            }
        }
    }

    public render() : React.ReactNode {
        return (
            <Provider form={this.props.store}>
                <form onSubmit={e => this.submitHandler(e)}>
                    {this.props.children}
                </form>
            </Provider>
        );
    }

    protected submitHandler(e : React.FormEvent) : void {
        e.preventDefault();
        if(this.props.onSubmit) this.props.onSubmit();
    }

    public static discard() : void {
        Form._safeForms = [];
    }

}