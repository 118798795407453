import React from 'react';
import { Map } from 'subway-router';
import { createRoot } from 'react-dom/client';
import { Session } from '@Framework/Core/Session';
import { Location } from '@Framework/Core/Location';
import { Form } from '@Framework/Library/Form';
import { DiscardModal } from '@Framework/Component/DiscardModal';
import { ENotificationType, INotification, Notification } from '@Framework/Component/Notification';
import { Loading } from '@Framework/Component/Loading';
import '@Framework/Core/App.less';

interface IProps {}
interface IState {
    view : React.ReactNode,
    notifications : ({ id : number } & INotification)[],
    discardForms : boolean,
    isCrashed : boolean,
}
interface INotificationProps {
    icon ?: string,
    type ?: ENotificationType,
    title ?: string,
    message : string | React.ReactElement,
}

export type TLoadService = (node : React.ReactNode) => void;
type TRegisterService = (router : Map, loadService : TLoadService) => void;
type TOnMount = (router : Map, loadService : TLoadService) => void;

// const GOOGLE_MAPS = {
//     ENABLED: GOOGLE_MAPS_API_KEY && GOOGLE_MAPS_LIBRARIES && GOOGLE_MAPS_LIBRARIES.length > 0,
//     API_KEY: GOOGLE_MAPS_API_KEY,
//     LIBRARIES: GOOGLE_MAPS_LIBRARIES,
// };

export class App extends React.Component<IProps, IState> {

    private static _instance : React.Component = null;
    private static _router : Map = null;
    private static _onMount : TOnMount = function () {};
    private static _onDiscard : Function = function () {};
    private static _uniqId : number = 0;

    constructor(props) {
        super(props);
        this.state = {
            view: null,
            notifications: [],
            discardForms: false,
            isCrashed: false,
        };
    }

    public componentDidMount() : void {
        App._router = new Map();
        App._instance = this;
        App._onMount(App._router, App.setView);
        window.addEventListener('popstate', App.onPopstate);
        window.addEventListener('beforeunload', App.onLeave);
        App._router.build();
        App.route();
    }

    public componentWillUnmount() : void {
        App._instance = null;
        App._router = null;
        window.removeEventListener('popstate', App.onPopstate);
        window.removeEventListener('beforeunload', App.onLeave);
    }

    public render() : React.ReactNode {
        if(this.state.isCrashed) {
            return (
                <div className="py-5">
                    <div className="alert alert-danger"><i className="fa fa-exclamation-triangle" /> Fatal error</div>
                </div>
            );
        }
        return (
            <Session>
                <div id="notifications">
                    {this.state.notifications.map(item => (
                        <Notification key={item.id} {...item} onClose={() => App.removeNotification(item.id)} />
                    ))}
                </div>
                {this.state.view ? this.state.view : <Loading />}
                {this.state.discardForms &&
                    <DiscardModal
                        onAccept={App.onDiscardAccept}
                        onCancel={App.onDiscardCancel}
                    />
                }
            </Session>
        );
    }

    public static init(onMount : TOnMount) : void {
        App._onMount = onMount;
        Location.updateUrl(window.location.href);
        Session.restore();
        createRoot(document.getElementById('root')).render(<App />);
    }

    public static registerService(loader : TRegisterService) : void {
        loader(App._router, App.setView);
    }

    public static setView(view : React.ReactNode) : void {
        App._instance.setState({ view });
    }

    public static route() : void {
        App._router.dispatch(Location.url);
    }

    public static refresh() : void {
        App._instance.setState({ view: null }, () => App.route());
    }

    public static redirect(url : string) : void {
        Location.updateUrl(url);
        App.route();
    }

    public static notification(notification : INotificationProps) : number {
        const id = ++App._uniqId;
        App._instance.setState((state : IState) => {
            const notifications = [ ...state.notifications ];
            notifications.push({
                id,
                icon: notification.icon,
                type: notification.type,
                title: notification.title,
                message: notification.message,
            });
            return { notifications };
        });
        setTimeout(() => App.removeNotification(id), 2500);
        return id;
    }

    public static removeNotification(id : number) : void {
        App._instance.setState((state : IState) => {
            const notifications = [ ...state.notifications ];
            for(let i = 0; i < notifications.length; i++) {
                if(notifications[i].id == id) {
                    notifications.splice(i, 1);
                    break;
                }
            }
            return { notifications };
        });
    }

    public static clearNotifications() : void {
        App._instance.setState({ notifications: [] });
    }

    public static discardForms(onDiscard : Function) : void {
        App._onDiscard = onDiscard;
        App._instance.setState({ discardForms: true });
    }

    private static onDiscardAccept() : void {
        App._instance.setState({ discardForms: false });
        if(App._onDiscard) App._onDiscard();
    }

    private static onDiscardCancel() : void {
        App._instance.setState({ discardForms: false });
    }

    private static onPopstate() : void {
        if(Location.onPopState(Form.unsaved, (onDiscard : Function) => {
            App.discardForms(onDiscard);
        })) App.route();
    }

    private static onLeave(e) : string {
        if(Form.unsaved) {
            const confirmationMessage = '\o/';
            (e || window.event).returnValue = confirmationMessage;
            return confirmationMessage;
        }
    }

    public static getDerivedStateFromError(error) {
        //@todo send error
        return { isCrashed: true };
    }

}