import { IMiddleware, TOnLoad } from 'subway-router';
import { Session } from '@Framework/Core/Session';
import { App } from '@Framework/Core/App';

export class LoggedOut implements IMiddleware {

    public onResolving(onLoad : TOnLoad) : TOnLoad {
        if(!Session.isActive) {
            return onLoad;
        } else {
            return LoggedOut.gotoMain;
        }
    }

    private static gotoMain() : void {
        App.redirect('/');
    }

}