import { ERequestMethod, IModelOptions, Model, Reply } from '@Framework/Library/Gateway';
import { ERemoteResource } from '@App';
import { DefaultStruct } from '@App/Structures';
import { DevicesModelRes } from '@App/Service/Companies/Details/Devices/Model/DevicesModel.res';
import { DevicesModelReq } from '@App/Service/Companies/Details/Devices/Model/DevicesModel.req';

export abstract class DevicesModel extends Model {

    public static list(
        query : DefaultStruct.IQuery = {},
        options : IModelOptions = {},
    ) : Promise<Reply<DevicesModelRes.List.IPayload>> {
        return this.request<DefaultStruct.IQuery, {}, DevicesModelRes.List.IPayload>({
            path: 'admin/devices',
            query: DevicesModelReq.List.getQuery(query),
            responseHandler: DevicesModelRes.List.getPayload,
        }, options);
    }

    public static details(
        id : number,
        options : IModelOptions = {},
    ) : Promise<Reply<DevicesModelRes.Details.IPayload>> {
        return this.request<{}, {}, DevicesModelRes.Details.IPayload>({
            path: `admin/devices/${id}`,
            responseHandler: DevicesModelRes.Details.getPayload,
        }, options);
    }

    public static update(
        id : number,
        payload : DevicesModelReq.Details.IPayload,
        options : IModelOptions = {},
    ) : Promise<Reply<DevicesModelRes.Details.IPayload>> {
        return this.request<{}, DevicesModelReq.Details.IPayloadOutput, DevicesModelRes.Details.IPayload>({
            path: `admin/devices/${id}`,
            method: ERequestMethod.POST,
            payload: DevicesModelReq.Details.getOutput(payload),
        }, options);
    }

    public static create(
        payload : DevicesModelReq.Create.IPayload,
        options : IModelOptions = {},
    ) : Promise<Reply<DevicesModelRes.Details.IPayload>> {
        return this.request<{}, DevicesModelReq.Create.IPayloadOutput, DevicesModelRes.Details.IPayload>({
            path: 'admin/devices/create',
            method: ERequestMethod.POST,
            payload: DevicesModelReq.Create.getOutput(payload),
        }, options);
    }

    public static delete(
        id : number,
        options : IModelOptions = {},
    ) : Promise<Reply<any>> {
        return this.request<{}, {}, {}>({
            path: `admin/devices/${id}`,
            method: ERequestMethod.DELETE,
        }, options);
    }

}