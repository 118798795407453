import { ruleRequired } from '@Framework/Library/Form/Rule/RuleRequired';
import { ruleLength } from '@Framework/Library/Form/Rule/RuleLength';
import { ruleNumber } from '@Framework/Library/Form/Rule/RuleNumber';
import { ruleEmail } from '@Framework/Library/Form/Rule/RuleEmail';
import { ruleURL } from '@Framework/Library/Form/Rule/RuleURL';
import { rulePattern } from '@Framework/Library/Form/Rule/RulePattern';
import { ruleDate, ERuleDateMode } from '@Framework/Library/Form/Rule/RuleDate';
import { ruleMatches } from '@Framework/Library/Form/Rule/RuleMatches';
import { ruleIf } from '@Framework/Library/Form/Rule/RuleIf';

export { TRuleType, IRuleResult } from '@Framework/Library/Form/Rule/Rule';
export { ERuleDateMode };
export const Rule = {
    required: ruleRequired,
    length: ruleLength,
    number: ruleNumber,
    email: ruleEmail,
    url: ruleURL,
    pattern: rulePattern,
    date: ruleDate,
    matches: ruleMatches,
    if: ruleIf,
};