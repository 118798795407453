import React from 'react';
import '@App/Layout/AuthLayout/AuthLayout.less';

interface IProps {
    children : React.ReactNode,
}
interface IState {}

export class AuthLayout extends React.Component<IProps, IState> {

    public render() : React.ReactNode {
        return (
            <div id="auth-layout">
                <div className="container-lg">
                    {this.props.children}
                </div>
            </div>
        );
    }

}