import React from 'react';
import { MainLayout } from '@App/Layout';
import { LogsModel } from '@App/Service/Logs/Model/LogsModel';
import { LogsModelStruct } from '@App/Service/Logs/Model/LogsModel.struct';
import { Loading } from '@Framework/Component/Loading';
import { Link } from '@Framework/Factory';
import moment from 'moment';
import { App } from '@Framework/Core/App';
import { UserModel, UserModelStruct } from '@App/Service/Users/Model';
import { CompaniesModel, CompaniesModelStruct } from '@App/Service/Companies/Model';
import { Format } from '@App/Config/Format';

enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}
interface IProps {}
interface IState {
    status : EStatus,
    dataLogs : LogsModelStruct.ILogsShort[],
    dataUser : UserModelStruct.IUserShort[],
    dataCompanies : CompaniesModelStruct.ICompanyShort[],
}

export class Dashboard extends React.Component<IProps, IState> {

    private isMount : boolean = false;

    constructor(props) {
        super(props);
        this.state = {
            status: EStatus.Unloaded,
            dataLogs: [],
            dataUser: [],
            dataCompanies: [],
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.loadData();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        const dataLogs = [ ...this.state.dataLogs ];

        return (
            <MainLayout service="dashboard">
                <div className="container-fluid my-3 mt-0">
                    <div className="row">
                        <div className="col-sm-6 mt-4">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="m-0">Companies</h6>
                                </div>
                                <div className="card-body">
                                    {this.state.status == EStatus.Loading && <div className="card-body"><Loading label="Loading companies..." /></div>}
                                    {this.state.status == EStatus.Failed && <div className="card-body"><div className="alert alert-danger"><i className="fa fa-exclamation-triangle" /> Events loading error</div></div>}
                                    {this.state.status == EStatus.Loaded &&
                                        <>
                                            <strong>Number of companies: </strong>
                                            <Link url={'/companies'}><a className="btn btn-xs btn-primary">{this.state.dataCompanies.length}</a></Link>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-4">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="m-0">Users</h6>
                                </div>
                                <div className="card-body">
                                    {this.state.status == EStatus.Loading && <div className="card-body"><Loading label="Loading users..." /></div>}
                                    {this.state.status == EStatus.Failed && <div className="card-body"><div className="alert alert-danger"><i className="fa fa-exclamation-triangle" /> Events loading error</div></div>}
                                    {this.state.status == EStatus.Loaded &&
                                        <>
                                            <strong>Number of users: </strong>
                                            <Link url={'/users'}><a className="btn btn-xs btn-primary">{this.state.dataUser.length}</a></Link>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="m-0">Last logs</h6>
                                </div>
                                {this.state.status == EStatus.Loading && <div className="card-body"><Loading label="Loading logs..." /></div>}
                                {this.state.status == EStatus.Failed && <div className="card-body"><div className="alert alert-danger"><i className="fa fa-exclamation-triangle" /> Events loading error</div></div>}
                                {this.state.status == EStatus.Loaded &&
                                    <>
                                        <div className="card-body p-0 d-none d-md-block">
                                            <div className="table-responsive">
                                                <table className="table table-hover table-wide m-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Text</th>
                                                            <th>Type</th>
                                                            <th/>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dataLogs.slice(0, 10).map(item => (
                                                            <tr key={item.id}>
                                                                <td onClick={() => Dashboard.openDetails(item.id)}>
                                                                    {moment.unix(item.tm).format(`${Format.Date.YN} ${Format.Time.M}`)}
                                                                </td>
                                                                <td onClick={() => Dashboard.openDetails(item.id)}>
                                                                    {item.txt}
                                                                </td>
                                                                <td onClick={() => Dashboard.openDetails(item.id)}>
                                                                    {item.type.name}
                                                                </td>
                                                                <td className="text-end">
                                                                    <Link url={`/logs/${item.id}`}>
                                                                        <a className="btn btn-xs btn-primary">
                                                                            <i className="fa fa-eye" /> Details
                                                                        </a>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-body py-0 d-lg-none">
                                            {dataLogs.reverse().slice(0, 10).map(item => (
                                                <div className="item-block" key={item.id} onClick={() => Dashboard.openDetails(item.id)}>
                                                    <div className="item-body">
                                                        <div className="row mb-1">
                                                            <div className="col">
                                                                <span className="badge bg-secondary">Created {moment.unix(item.tm).format('DD.MM.YYYY HH:mm:ss')}</span>
                                                            </div>
                                                            <div className="col-auto">
                                                                <div className="font-monospace">
                                                                    <span className="prop">Type:</span>
                                                                    {' '}
                                                                    <span className="badge bg-light">{item.type.name}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                {item.txt}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="item-controls text-end">
                                                        <Link url={`/sensors/${item.id}`}>
                                                            <a className="btn btn-xs btn-primary">
                                                                <i className="fa fa-eye" /> Details
                                                            </a>
                                                        </Link>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                }
                                <div className="card-footer text-end">
                                    <Link url={'/logs'}>
                                        <a className="btn btn-sm btn-outline-primary">
                                            <i className="fa fa-list" /> More events
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MainLayout>
        );
    }

    private loadData() : void {
        (async () => {
            this.setState({ status: EStatus.Loading });
            const res = await LogsModel.list(this.props);
            const res1 = await UserModel.list(this.props);
            const res2 = await CompaniesModel.list(this.props);

            if(!this.isMount) return;
            if(res.success && res.payload) {
                this.setState({
                    status: EStatus.Loaded,
                    dataLogs: res.payload.data,
                });
            } else this.setState({ status: EStatus.Failed });
            if(res1.success && res1.payload) {
                this.setState({
                    status: EStatus.Loaded,
                    dataUser: res1.payload.data,
                });
            } else this.setState({ status: EStatus.Failed });
            if(res2.success && res2.payload) {
                this.setState({
                    status: EStatus.Loaded,
                    dataCompanies: res2.payload.data,
                });
            } else this.setState({ status: EStatus.Failed });
        })();
    }

    private static openDetails(id : number) : void {
        App.redirect(`/logs/${id}`);
    }

}