import React from 'react';
import { Form } from '@Framework/Library/Form';
import {
    DropdownField,
    InputField,
    SearchField,
    SelectField,
    SwitchField,
    TextField,
} from '@Framework/Component/FormField';
import { observer } from 'mobx-react';
import { App } from '@Framework/Core/App';
import { ENotificationType } from '@Framework/Component/Notification';
import { DevicesModel } from '@App/Service/Companies/Details/Devices/Model/DevicesModel';
import { Details as CompanyDetails, ETab } from '@App/Service/Companies/Details/Details';
import { EModalSize, Link, Modal } from '@Framework/Factory';
import { SensorsModelStruct } from '@App/Service/Companies/Details/Sensors/Model/SensorsModel.struct';
import { SensorsModel } from '@App/Service/Companies/Details/Sensors/Model/SensorsModel';
import { SensorsModelRes } from '@App/Service/Companies/Details/Sensors/Model/SensorsModel.res';
import { Reply } from '@Framework/Library/Gateway';
import { DefaultStruct } from '@App/Structures';
import { DetailsFormStore } from '@App/Service/Companies/Details/Sensors/Details/DetailsFormStore';
import { sensorIcons } from '@App/Assets/SensorIcons';

export enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}

enum EAction {
    Idle,
    Saving,
    DeleteConfirmation,
    Deleting,
}

interface IProps {
    companyId : number;
    id : number;
}
interface IState {
    action : EAction,
    status : EStatus,
    groups : DefaultStruct.IOption[],
    locations : DefaultStruct.IOption[],
    data : SensorsModelStruct.ISensor,
    valueConfigOpen : boolean,
}

@observer
export class Details extends React.Component<IProps, IState> {

    private isMount : boolean = false;
    private form : DetailsFormStore = new DetailsFormStore();

    constructor(props) {
        super(props);
        this.state = {
            action: EAction.Idle,
            status: EStatus.Unloaded,
            groups: [],
            locations: [],
            data: null,
            valueConfigOpen: true,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.loadFields();
        this.loadData();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        return (
            <CompanyDetails
                id={this.props.companyId}
                tab={ETab.Sensors}
                title={<h3 className="m-0">Sensor Details</h3>}
                breadcrumb={
                    <>
                        <li className="breadcrumb-item">
                            <Link url={`companies/${this.props.companyId}/sensors`}><a>Sensors</a></Link>
                        </li>
                        <li className="breadcrumb-item">Sensor Details</li>
                    </>
                }
            >
                <Form store={this.form} onSubmit={() => this.onSubmit()}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Name <span className="text-danger">*</span></label>
                                    <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.name} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Port <span className="text-danger">*</span></label>
                                    <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.port} />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3">
                                <div className="form-group">
                                    <label>Priority</label>
                                    <SelectField disabled={this.state.action != EAction.Idle} store={this.form.fields.priorityId}>
                                        {SensorsModelStruct.sensorPriorities.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}
                                    </SelectField>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3">
                                <div className="form-group">
                                    <label>Active</label>
                                    <SwitchField disabled={this.state.action != EAction.Idle} store={this.form.fields.isActive} wide={true} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group">
                                    <label>Device <span className="text-danger">*</span></label>
                                    <div className="row m-0">
                                        <div className="col px-0">
                                            <SearchField
                                                id={'device-search'}
                                                onSearch={async (keywords, page) => {
                                                    const res = await DevicesModel.list({
                                                        qstr: keywords,
                                                        page,
                                                        limit: 10,
                                                    });
                                                    return {
                                                        keywords,
                                                        options: res.success && res.payload
                                                            ? res.payload.data.map(item => ({
                                                                id: item.deviceId,
                                                                name: item.name,
                                                            }))
                                                            : [],
                                                        pages: res.success && res.payload
                                                            ? res.payload.pagination.page
                                                            : 1,
                                                        total: res.success && res.payload
                                                            ? res.payload.pagination.pages
                                                            : 1,
                                                    };
                                                }}
                                                store={this.form.fields.deviceId}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <label>Group</label>
                                    <div className="row m-0">
                                        <div className="col px-0">
                                            <SelectField disabled={this.state.action != EAction.Idle} store={this.form.fields.groupId}>
                                                <option value=""></option>
                                                {this.state.groups.map(item => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))}
                                            </SelectField>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <label>Location</label>
                                    <div className="row m-0">
                                        <div className="col px-0">
                                            <SelectField disabled={this.state.action != EAction.Idle} store={this.form.fields.locationId}>
                                                <option value=""></option>
                                                {this.state.locations.map(item => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))}
                                            </SelectField>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <label>Min Step</label>
                                    <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.dataMinStep} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group m-0">
                                    <label>Notes</label>
                                    <TextField disabled={this.state.action != EAction.Idle} store={this.form.fields.notes} />
                                </div>
                            </div>
                        </div>

                        {/*<div className="row border-top mt-4">*/}
                        {/*    <div className="col">*/}
                        {/*        <h2 className="mt-3 mb-3" onClick={() => this.setState(state => ({ valueConfigOpen: !state.valueConfigOpen }))}>*/}
                        {/*            <button style={{ fontSize: '22px' }} className="accordion-button" type="button">*/}
                        {/*                Value Configuration*/}
                        {/*                {this.state.valueConfigOpen*/}
                        {/*                    ? <i className="fa fa-arrow-circle-down ps-3" aria-hidden="true"></i>*/}
                        {/*                    : <i className="fa fa-arrow-circle-right ps-3" aria-hidden="true"></i>*/}
                        {/*                }*/}
                        {/*            </button>*/}
                        {/*        </h2>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*{this.state.valueConfigOpen &&*/}
                        {/*    <>*/}
                        {/*        <div className="row">*/}
                        {/*            <div className="col">*/}
                        {/*                <div className="form-group">*/}
                        {/*                    <label>Name <span className="text-danger">*</span></label>*/}
                        {/*                    <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.valueConfigName} />*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="col">*/}
                        {/*                <div className="form-group">*/}
                        {/*                    <label>Min Value</label>*/}
                        {/*                    <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.valueConfigMin} />*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="col">*/}
                        {/*                <div className="form-group">*/}
                        {/*                    <label>Max Value</label>*/}
                        {/*                    <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.valueConfigMax} />*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="col">*/}
                        {/*                <div className="form-group">*/}
                        {/*                    <label>Icon <span className="text-danger">*</span></label>*/}
                        {/*                    <DropdownField*/}
                        {/*                        id="iconId"*/}
                        {/*                        disabled={this.state.action != EAction.Idle}*/}
                        {/*                        store={this.form.fields.iconId}*/}
                        {/*                        options={Object.keys(sensorIcons).map((item, index) => ({*/}
                        {/*                            value: String(index + 1),*/}
                        {/*                            option: <><img src={sensorIcons[item]} height={22} alt=""/></>,*/}
                        {/*                        }))}*/}
                        {/*                    />*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className="row">*/}
                        {/*            <div className="col">*/}
                        {/*                <div className="form-group">*/}
                        {/*                    <label>Thousands</label>*/}
                        {/*                    <SelectField disabled={this.state.action != EAction.Idle} store={this.form.fields.valueConfigThousands}>*/}
                        {/*                        <option value=",">,</option>*/}
                        {/*                        <option value="#"></option>*/}
                        {/*                        <option value=".">.</option>*/}
                        {/*                    </SelectField>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="col">*/}
                        {/*                <div className="form-group">*/}
                        {/*                    <label>Format</label>*/}
                        {/*                    <SelectField disabled={this.state.action != EAction.Idle} store={this.form.fields.valueConfigFormat}>*/}
                        {/*                        <option value="#.##">#.##</option>*/}
                        {/*                        <option value="#">#</option>*/}
                        {/*                        <option value="#.#">#.#</option>*/}
                        {/*                        <option value="#.###">#.###</option>*/}
                        {/*                    </SelectField>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="col">*/}
                        {/*                <div className="form-group">*/}
                        {/*                    <label>Value Convert</label>*/}
                        {/*                    <SelectField disabled={this.state.action != EAction.Idle} store={this.form.fields.valueConfigFormat}>*/}
                        {/*                        <option value=""></option>*/}
                        {/*                        <option value="time_hms" title="Show as time">Time (hms)</option>*/}
                        {/*                        <option value="size_gmkb" title="Giga/Mega/kilo">Size (GMk)</option>*/}
                        {/*                        <option value="size_gm" title="Giga/Mega">Size (GM)</option>*/}
                        {/*                    </SelectField>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="col">*/}
                        {/*                <div className="form-group">*/}
                        {/*                    <label>Formula</label>*/}
                        {/*                    <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.valueConfigFormula} />*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </>*/}
                        {/*}*/}

                    </div>
                    {this.state.status == EStatus.Loaded &&
                        <div className="card-footer">
                            <div className="row">
                                <div className="col-auto">
                                    <button
                                        type="button"
                                        disabled={this.state.action != EAction.Idle}
                                        className="btn btn-danger"
                                        onClick={() => this.setState({
                                            action: EAction.DeleteConfirmation,
                                        })}
                                    >
                                        {this.state.action == EAction.Deleting
                                            ? <span className="spinner-border spinner-border-sm" />
                                            : <i className="fa fa-trash" />
                                        } Delete
                                    </button>
                                </div>
                                <div className="col"/>
                                <div className="col-auto">
                                    <button type="submit" disabled={this.state.action != EAction.Idle} className="btn btn-primary">
                                        {this.state.action == EAction.Saving
                                            ? <span className="spinner-border spinner-border-sm" />
                                            : <i className="fa fa-check" />
                                        } Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </Form>
                {this.state.action == EAction.DeleteConfirmation &&
                    <Modal
                        size={EModalSize.SM}
                        onClickOutside={() => this.setState({ action: EAction.Idle })}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">Delete</h5>
                            <button type="button" className="btn btn-light btn-sm" onClick={() => this.setState({ action: EAction.Idle })}>
                                <i className="fa fa-times"/>
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => this.setState({ action: EAction.Idle })}><i className="fa fa-ban" /> Cancel</button>
                            <button type="button" className="btn btn-danger" onClick={() => this.onDelete()}><i className="fa fa-trash" /> Delete</button>
                        </div>
                    </Modal>
                }
            </CompanyDetails>
        );
    }

    private loadFields() : void {
        this.setState({ status: EStatus.Loading });
        let loaded = 0;
        const onLoaded = (fields : Reply<SensorsModelRes.Relations.IPayload>, field : 'groups' | 'locations') => {
            loaded++;
            if(!this.isMount) return;
            if(fields.success) {
                const keys = {};
                keys[field] = fields.payload.data || [];
                this.setState(state => ({
                    ...keys,
                    ...loaded >= 2 && state.status != EStatus.Failed ? { status: EStatus.Loaded } : null,
                }));
            } else this.setState({ status: EStatus.Failed });
        };
        SensorsModel
            .groups()
            .then(res => onLoaded(res, 'groups'));
        SensorsModel
            .locations()
            .then(res => onLoaded(res, 'locations'));
    }

    private loadData() : void {
        (async () => {
            this.setState({ status: EStatus.Loading });
            const res = await SensorsModel.details(this.props.id);
            if(!this.isMount) return;
            if(res.success && res.payload) {
                this.form.fill(res.payload.data).save();
                this.form.fields.deviceId.meta.name = res.payload.data.deviceName;
                this.setState({ status: EStatus.Loaded });
            } else this.setState({ status: EStatus.Failed });
        })();
    }

    private onSubmit() : void {
        this.form.validate();
        if(!this.form.isValid) return;
        this.setState({ action: EAction.Saving });
        (async () => {
            const res = await SensorsModel.update(this.props.id, this.form.getValues());
            if(!this.isMount) return;
            if(res.success) {
                this.form.save();
                App.notification({
                    type: ENotificationType.Success,
                    title: 'Success',
                    message: 'Successfully saved.',
                });
            }
            this.setState({ action: EAction.Idle });
        })();
    }

    private onDelete() : void {
        this.setState({ action: EAction.Deleting });
        (async () => {
            const res = await SensorsModel.delete(this.props.id);
            if(!this.isMount) return;
            if(res.success) {
                App.notification({
                    type: ENotificationType.Success,
                    title: 'Success',
                    message: 'Successfully deleted.',
                });
                App.redirect(`companies/${this.props.companyId}/sensors`);
            }
            this.setState({ action: EAction.Idle });
        })();
    }

}