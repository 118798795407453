import { ERequestMethod, IModelOptions, Model, Reply } from '@Framework/Library/Gateway';
import { ERemoteResource } from '@App';
import { DefaultStruct } from '@App/Structures';
import { DevicesModelRes } from '@App/Service/Companies/Details/Devices/Model/DevicesModel.res';
import { DevicesModelReq } from '@App/Service/Companies/Details/Devices/Model/DevicesModel.req';
import { DataSourcesModelRes } from '@App/Service/Companies/Details/DataSources/Model/DataSourcesModel.res';
import { DataSourcesModelReq } from '@App/Service/Companies/Details/DataSources/Model/DataSourcesModel.req';

export abstract class DataSourcesModel extends Model {

    public static list(
        query : DefaultStruct.IQuery = {},
        options : IModelOptions = {},
    ) : Promise<Reply<DataSourcesModelRes.List.IPayload>> {
        return this.request<DefaultStruct.IQuery, {}, DataSourcesModelRes.List.IPayload>({
            path: 'admin/datasources',
            query: DataSourcesModelReq.List.getQuery(query),
            responseHandler: DataSourcesModelRes.List.getPayload,
        }, options);
    }

    public static details(
        id : number,
        options : IModelOptions = {},
    ) : Promise<Reply<DataSourcesModelRes.Details.IPayload>> {
        return this.request<{}, {}, DataSourcesModelRes.Details.IPayload>({
            path: `admin/datasources/${id}`,
            responseHandler: DataSourcesModelRes.Details.getPayload,
        }, options);
    }

    public static update(
        id : number,
        payload : DataSourcesModelReq.Details.IPayload,
        options : IModelOptions = {},
    ) : Promise<Reply<DataSourcesModelRes.Details.IPayload>> {
        return this.request<{}, DataSourcesModelReq.Details.IPayloadOutput, DataSourcesModelRes.Details.IPayload>({
            path: `admin/datasources/${id}`,
            method: ERequestMethod.POST,
            payload: DataSourcesModelReq.Details.getOutput(payload),
        }, options);
    }

    public static create(
        payload : DataSourcesModelReq.Create.IPayload,
        options : IModelOptions = {},
    ) : Promise<Reply<DataSourcesModelRes.Details.IPayload>> {
        return this.request<{}, DataSourcesModelReq.Create.IPayloadOutput, DataSourcesModelRes.Details.IPayload>({
            path: 'admin/datasources/create',
            method: ERequestMethod.POST,
            payload: DataSourcesModelReq.Create.getOutput(payload),
        }, options);
    }

    public static delete(
        id : number,
        options : IModelOptions = {},
    ) : Promise<Reply<any>> {
        return this.request<{}, {}, {}>({
            path: `admin/datasources/${id}`,
            method: ERequestMethod.DELETE,
        }, options);
    }

}