import React from 'react';
import moment from 'moment';
import { IToken, IUser } from '@App';

interface IProps {
    children : React.ReactNode,
}
export interface IBaseToken {
    access : string,
    refresh ?: string,
}
interface IConfig {
    expiresAt : (token : IToken) => number,
    onUpdateToken : (token : any) => IToken,
    onUpdateUser : (user : any) => IUser,
    prefix : string,
    domain : string,
    secure : boolean,
    maxAge : number,
}

export const UserContext = React.createContext<IUser>(null);

export class Session extends React.Component<IProps, IUser> {

    private static _expiresAt : (token : IToken) => number = function () { return 0; };
    private static _onUpdateToken : (token : any) => IToken = function (t) { return t; };
    private static _onUpdateUser : (user : any) => IUser = function (u) { return u; };
    private static _prefix : string = '';
    private static _domain : string = '';
    private static _secure : boolean = false;
    private static _maxAge : number = 2592000;
    private static _instance : Session = null;
    private static _token : IToken = Session._onUpdateToken(null);

    public static get token() : IToken {
        return { ...Session._token };
    }

    public static get user() : IUser {
        return { ...Session._instance.state };
    }

    public static get loggedIn() : boolean {
        return !!Session.token.access;
    }

    public static get isExpired() : boolean {
        return Session._expiresAt(Session.token) < moment.utc().unix();
    }

    public static get isActive() : boolean {
        return Session.loggedIn && !Session.isExpired;
    }

    public static get isRefreshable() : boolean {
        return !!Session.token.refresh;
    }

    public componentDidMount() {
        Session._instance = this;
    }

    public componentWillUnmount() {
        Session._instance = null;
    }

    constructor(props) {
        super(props);
        this.state = Session._onUpdateUser(null);
    }

    public render() : React.ReactNode {
        return (
            <UserContext.Provider value={this.state}>
                {this.props.children}
            </UserContext.Provider>
        );
    }

    public static configure(options : IConfig) : void {
        Session._expiresAt = options.expiresAt;
        Session._onUpdateToken = options.onUpdateToken;
        Session._onUpdateUser = options.onUpdateUser;
        Session._prefix = options.prefix;
        Session._domain = options.domain;
        Session._secure = options.secure;
        Session._maxAge = options.maxAge;
    }

    public static restore() : void {
        const cookies = document.cookie.split(';');
        const data : Record<string, string> = {};
        for(let i = 0; i < cookies.length; i++) {
            const temp = cookies[i].split('=');
            const name = decodeURIComponent(temp[0]).trim();
            if(!name.match(new RegExp(`^${Session._prefix}_`))) continue;
            data[name.replace(new RegExp(`^${Session._prefix}_`), '')] = decodeURIComponent(temp[1]);
        }
        Session._token = Session._onUpdateToken(data.access ? data : null);
    }

    public static update(data : IToken) : void {
        Session._token = Session._onUpdateToken(data);
        const maxAge = Session.isRefreshable
            ? Session._maxAge
            : Session._expiresAt(Session.token) - moment().utc().unix();
        Session.updateCookie(maxAge);
    }

    public static destroy() : void {
        Session._token = Session._onUpdateToken(null);
        Session.updateCookie(-1);
    }

    public static setUser(user : IUser, onUpdated ?: Function) : void {
        Session._instance.setState(Session._onUpdateUser(user), () => onUpdated ? onUpdated() : null);
    }

    protected static updateCookie(age : number) : void {
        const domain = Session._domain ? ` domain=${Session._domain};` : '';
        const secure = Session._secure ? ' secure' : '';
        const settingsString = ` path=/;${domain} max-age=${age};${secure}`;
        for(const key in Session.token) {
            document.cookie = `${Session._prefix}_${key}=${Session.token[key]};${settingsString}`;
        }
    }

}