import React from 'react';
import { Link } from '@Framework/Factory';
import '@App/Layout/MainLayout/MainMenu/MainMenu.less';

interface IProps {
    active : string,
}
interface IState {}

export class MainMenu extends React.Component<IProps, IState> {

    public render() {
        return (
            <div id="main-menu">
                <ul>
                    <li className={this.props.active == 'dashboard' ? 'active' : ''}>
                        <Link url={'/dashboard'}>
                            <a><span><i className="fa fa-cube" /></span> <span className="d-none d-md-inline">Dashboard</span></a>
                        </Link>
                    </li>
                    <li className={this.props.active == 'companies' ? 'active' : ''}>
                        <Link url={'/companies'}>
                            <a><span><i className="fa fa-bank" /></span> <span className="d-none d-md-inline">Companies</span></a>
                        </Link>
                    </li>
                    <li className={this.props.active == 'users' ? 'active' : ''}>
                        <Link url={'/users'}>
                            <a><span><i className="fa fa-users" /></span> <span className="d-none d-md-inline">Users</span></a>
                        </Link>
                    </li>
                    <li className={this.props.active == 'online' ? 'active' : ''}>
                        <Link url={'/online'}>
                            <a><span><i className="fa fa-user-circle" /></span> <span className="d-none d-md-inline">Online</span></a>
                        </Link>
                    </li>
                    <li className={this.props.active == 'logs' ? 'active' : ''}>
                        <Link url={'/logs'}>
                            <a><span><i className="fa fa-list-alt" /></span> <span className="d-none d-md-inline">Logs</span></a>
                        </Link>
                    </li>
                </ul>
            </div>
        );
    }

}