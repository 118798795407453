import s1 from '@App/Assets/SensorIcons/sensor-icon-1.svg';
import s2 from '@App/Assets/SensorIcons/sensor-icon-2.svg';
import s3 from '@App/Assets/SensorIcons/sensor-icon-3.svg';
import s4 from '@App/Assets/SensorIcons/sensor-icon-4.svg';
import s5 from '@App/Assets/SensorIcons/sensor-icon-5.svg';
import s6 from '@App/Assets/SensorIcons/sensor-icon-6.svg';
import s7 from '@App/Assets/SensorIcons/sensor-icon-7.svg';
import s8 from '@App/Assets/SensorIcons/sensor-icon-8.svg';
import s9 from '@App/Assets/SensorIcons/sensor-icon-9.svg';
import s10 from '@App/Assets/SensorIcons/sensor-icon-10.svg';
import s11 from '@App/Assets/SensorIcons/sensor-icon-11.svg';
import s12 from '@App/Assets/SensorIcons/sensor-icon-12.svg';
import s13 from '@App/Assets/SensorIcons/sensor-icon-13.svg';
import s14 from '@App/Assets/SensorIcons/sensor-icon-14.svg';
import s15 from '@App/Assets/SensorIcons/sensor-icon-15.svg';
import s16 from '@App/Assets/SensorIcons/sensor-icon-16.svg';
import s17 from '@App/Assets/SensorIcons/sensor-icon-17.svg';
import s18 from '@App/Assets/SensorIcons/sensor-icon-18.svg';
import s19 from '@App/Assets/SensorIcons/sensor-icon-19.svg';
import s20 from '@App/Assets/SensorIcons/sensor-icon-20.svg';
import s21 from '@App/Assets/SensorIcons/sensor-icon-21.svg';
import s22 from '@App/Assets/SensorIcons/sensor-icon-22.svg';

export const sensorIcons = {
    s1,
    s2,
    s3,
    s4,
    s5,
    s6,
    s7,
    s8,
    s9,
    s10,
    s11,
    s12,
    s13,
    s14,
    s15,
    s16,
    s17,
    s18,
    s19,
    s20,
    s21,
    s22,
};