import React from 'react';
import { EModalSize, Link, Modal } from '@Framework/Factory';
import { DetailsFormStore } from '@App/Service/Companies/Details/DataSources/Details/DetailsFormStore';
import { Form } from '@Framework/Library/Form';
import { Loading } from '@Framework/Component/Loading';
import { InputField, SelectField, SwitchField, TextField } from '@Framework/Component/FormField';
import { observer } from 'mobx-react';
import { App } from '@Framework/Core/App';
import { ENotificationType } from '@Framework/Component/Notification';
import { DevicesModelStruct } from '@App/Service/Companies/Details/Devices/Model/DevicesModel.struct';
import { DevicesModel } from '@App/Service/Companies/Details/Devices/Model/DevicesModel';
import { Details as CompanyDetails, ETab } from '@App/Service/Companies/Details/Details';
import { Reply } from '@Framework/Library/Gateway';
import { SensorsModelRes } from '@App/Service/Companies/Details/Sensors/Model/SensorsModel.res';
import { SensorsModel } from '@App/Service/Companies/Details/Sensors/Model/SensorsModel';
import { DefaultStruct } from '@App/Structures';
import { DataSourcesModelStruct } from '@App/Service/Companies/Details/DataSources/Model/DataSourcesModel.struct';
import { DataSourcesModel } from '@App/Service/Companies/Details/DataSources/Model/DataSourcesModel';

enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}
enum EAction {
    Idle,
    Saving,
    DeleteConfirmation,
    Deleting,
}
interface IProps {
    id : number,
    companyId : number,
}
interface IState {
    status : EStatus,
    groups : DefaultStruct.IOption[],
    locations : DefaultStruct.IOption[],
    action : EAction,
    data : DataSourcesModelStruct.IDataSourcesShort,
}

@observer
export class Details extends React.Component<IProps, IState> {

    private isMount : boolean = false;
    private form : DetailsFormStore = new DetailsFormStore();

    constructor(props) {
        super(props);
        this.state = {
            status: EStatus.Unloaded,
            action: EAction.Idle,
            groups: [],
            locations: [],
            data: null,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.loadData();
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(this.props.id != prevProps.id) this.loadData();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        return (
            <CompanyDetails
                id={this.props.companyId}
                title={<h3 className="m-0">Details</h3>}
                tab={ETab.DataSources}
                breadcrumb={
                    <>
                        <li className="breadcrumb-item">
                            <Link url={`companies/${this.props.companyId}/data-sources`}><a>Data Sources</a></Link>
                        </li>
                        <li className="breadcrumb-item">Data Sources Details</li>
                    </>
                }
            >
                {this.state.status == EStatus.Loading && <Loading label="Loading chart..." />}
                {this.state.status == EStatus.Failed && <div className="alert alert-danger"><i className="fa fa-exclamation-triangle" /> Stats loading error</div>}
                {this.state.status == EStatus.Loaded &&
                    <>
                        {this.state.data.type == 1 &&
                            <Form store={this.form} onSubmit={() => this.onSubmit()}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Name <span className="text-danger">*</span></label>
                                                <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.name}/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Active</label>
                                                <SwitchField disabled={this.state.action != EAction.Idle} store={this.form.fields.active} wide={true}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Host Name</label>
                                                <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.hostname}/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Port</label>
                                                <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.port}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>User Name</label>
                                                <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.username}/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Password</label>
                                                <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.password}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>SSL</label>
                                                <SwitchField disabled={this.state.action != EAction.Idle} store={this.form.fields.option1num} wide={true}/>
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <label>Version</label>
                                                <SelectField disabled={this.state.action != EAction.Idle} store={this.form.fields.option2num}>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </SelectField>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.status == EStatus.Loaded &&
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col-auto">
                                                <button
                                                    type="button"
                                                    disabled={this.state.action != EAction.Idle}
                                                    className="btn btn-danger"
                                                    onClick={() => this.setState({
                                                        action: EAction.DeleteConfirmation,
                                                    })}
                                                >
                                                    {this.state.action == EAction.Deleting
                                                        ? <span className="spinner-border spinner-border-sm" />
                                                        : <i className="fa fa-trash" />
                                                    } Delete
                                                </button>
                                            </div>
                                            <div className="col"/>
                                            <div className="col-auto">
                                                <button type="submit" disabled={this.state.action != EAction.Idle} className="btn btn-primary">
                                                    {this.state.action == EAction.Saving
                                                        ? <span className="spinner-border spinner-border-sm" />
                                                        : <i className="fa fa-check" />
                                                    } Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </Form>
                        }
                        {this.state.data.type != 1 &&
                            <Form store={this.form} onSubmit={() => this.onSubmit()}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Name <span className="text-danger">*</span></label>
                                                <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.name}/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Active</label>
                                                <SwitchField disabled={this.state.action != EAction.Idle} store={this.form.fields.active} wide={true}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>API Key</label>
                                                <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.option1str}/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>SSL</label>
                                                <SwitchField disabled={this.state.action != EAction.Idle} store={this.form.fields.option1num} wide={true}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.status == EStatus.Loaded &&
                                    <div className="card-footer">
                                    <div className="row">
                                            <div className="col-auto">
                                                <button
                                                    type="button"
                                                    disabled={this.state.action != EAction.Idle}
                                                    className="btn btn-danger"
                                                    onClick={() => this.setState({
                                                        action: EAction.DeleteConfirmation,
                                                    })}
                                                >
                                                    {this.state.action == EAction.Deleting
                                                        ? <span className="spinner-border spinner-border-sm" />
                                                        : <i className="fa fa-trash" />
                                                    } Delete
                                                </button>
                                            </div>
                                            <div className="col"/>
                                            <div className="col-auto">
                                                <button type="submit" disabled={this.state.action != EAction.Idle} className="btn btn-primary">
                                                    {this.state.action == EAction.Saving
                                                        ? <span className="spinner-border spinner-border-sm" />
                                                        : <i className="fa fa-check" />
                                                    } Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </Form>
                        }
                    </>
                }

                {this.state.action == EAction.DeleteConfirmation &&
                    <Modal
                        size={EModalSize.SM}
                        onClickOutside={() => this.setState({ action: EAction.Idle })}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">Delete</h5>
                            <button type="button" className="btn btn-light btn-sm" onClick={() => this.setState({ action: EAction.Idle })}>
                                <i className="fa fa-times"/>
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => this.setState({ action: EAction.Idle })}><i className="fa fa-ban" /> Cancel</button>
                            <button type="button" className="btn btn-danger" onClick={() => this.onDelete()}><i className="fa fa-trash" /> Delete</button>
                        </div>
                    </Modal>
                }
            </CompanyDetails>
        );
    }

    private loadData() : void {
        (async () => {
            this.setState({ status: EStatus.Loading });
            const res = await DataSourcesModel.details(this.props.id);
            if(!this.isMount) return;
            if(res.success && res.payload) {
                this.setState({
                    status: EStatus.Loaded,
                    data: res.payload.data,
                });
                this.form.fill({
                    ...res.payload.data,
                }).save();
            } else this.setState({ status: EStatus.Failed });
        })();
    }

    private onSubmit() : void {
        this.form.validate();
        if(!this.form.isValid) return;
        this.setState({ action: EAction.Saving });
        (async () => {
            const res = await DataSourcesModel.update(this.props.id, this.form.getValues());
            if(!this.isMount) return;
            if(res.success) {
                this.form.save();
                App.notification({
                    type: ENotificationType.Success,
                    title: 'Success',
                    message: 'Successfully saved.',
                });
            }
            this.setState({ action: EAction.Idle });
        })();
    }

    private onDelete() : void {
        this.setState({ action: EAction.Deleting });
        (async () => {
            const res = await DataSourcesModel.delete(this.props.id);
            if(!this.isMount) return;
            if(res.success) {
                App.notification({
                    type: ENotificationType.Success,
                    title: 'Success',
                    message: 'Successfully deleted.',
                });
                App.redirect(`companies/${this.props.companyId}/data-sources`);
            }
            this.setState({ action: EAction.Idle });
        })();
    }

}