import React from 'react';
import moment from 'moment';
import { Calendar } from '@Framework/Component/Calendar';
import { OnClickOutside } from '@Framework/Factory';

interface IProps {
    id : string,
    date ?: number,
    dropdownRight ?: boolean,
    onChanged : (date : number) => void,
}
interface IState {
    value : string,
    focus : boolean,
}

export class SelectDate extends React.Component<IProps, IState> {

    private hover : boolean = false;

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.date ? moment.unix(this.props.date).format('YYYY.MM.DD') : '',
            focus: false,
        };
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(this.props.date != prevProps.date) {
            this.setState({ value: this.props.date ? moment.unix(this.props.date).format('YYYY.MM.DD') : '' });
        }
    }

    public render() : React.ReactNode {
        const style = {
            width: '240px',
            left: this.props.dropdownRight ? 'auto' : '0px',
            right: this.props.dropdownRight ? '0px' : 'auto',
        };
        return (
            <div className="dropdown" id={this.props.id}>
                <input
                    type="text"
                    className="form-control form-control-sm"
                    value={this.state.focus ? this.state.value : this.props.date ? moment.unix(this.props.date).format('YYYY.MM.DD') : ''}
                    placeholder="YYYY.MM.DD"
                    onChange={e => this.onWrote(e.target.value)}
                    onFocus={() => this.setState({ focus: true })}
                    onBlur={() => this.onBlur()}
                />
                {this.state.focus &&
                    <OnClickOutside topLevelId={this.props.id} onClick={() => this.onBlur()}>
                        <div
                            style={style}
                            className="dropdown-menu show px-2"
                            onMouseEnter={() => this.hover = true}
                            onMouseLeave={() => this.hover = false}
                        >
                            <Calendar
                                displayed={this.props.date}
                                selected={this.props.date || undefined}
                                onChanged={date => this.onChanged(date)}
                            />
                        </div>
                    </OnClickOutside>
                }
            </div>
        );
    }

    private setValidDate(value : string) : boolean {
        const values = value.match(/^(\d{4}).(\d{2}).(\d{2})$/);
        if(values && values[1].length == 4 && Number(values[2]) <= 12 && Number(values[3]) <= 31) {
            this.onChanged(moment()
                .year(Number(values[1]))
                .month(Number(values[2]) - 1)
                .date(Number(values[3]))
                .unix());
            return true;
        }
        return false;
    }

    private onWrote(value : string) : void {
        const newValue = value.trim().replace(/[^\d.,]/, '').replace(',', '.');
        if(!this.setValidDate(newValue)) this.setState({ value: newValue });
    }

    private onBlur() : void {
        if(this.hover) return;
        this.setValidDate(this.state.value);
        this.setState({ focus: false });
    }

    private onChanged(date : number) : void {
        this.props.onChanged(date);
    }

}