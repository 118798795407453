import React from 'react';
import { Link } from '@Framework/Factory';
import { Location } from '@Framework/Core/Location';

interface IProps {
    children : React.ReactNode,
    url ?: string,
    name : string,
}
interface IState {}

export class SortLink extends React.Component<IProps, IState> {

    public render() : React.ReactNode {
        const pathData = Location.parseUrl(this.props.url ? this.props.url : Location.url);
        const isActive = pathData.params.orderBy == this.props.name;
        const isDescribed = pathData.params.sort == 'desc' && isActive;
        pathData.params.orderBy = this.props.name;
        pathData.params.sort = isDescribed ? 'asc' : 'desc';
        return (
            <Link url={`${Location.buildPath(pathData.segments)}?${Location.buildQuery(pathData.params)}`}>
                <a>{isActive && <i className={`fa fa-angle-${isDescribed ? 'down' : 'up'}`}/>} {this.props.children}</a>
            </Link>
        );
    }

}