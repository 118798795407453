import React from 'react';
import { App } from '@Framework/Core/App';
import { Form } from '@Framework/Library/Form';

interface IProps {
    children : React.ReactElement,
    url : string,
}
interface IState {}

export class Link extends React.Component<IProps, IState> {

    public render() : React.ReactNode {
        return React.cloneElement(this.props.children, {
            onClick: e => {
                e.preventDefault();
                if(Form.unsaved) {
                    App.discardForms(() => {
                        Form.discard();
                        this.move();
                    });
                } else this.move();
            },
            ...this.props.children.type == 'a'
                ? { href: this.props.url }
                : {},
        });
    }

    private move() : void {
        App.redirect(this.props.url);
    }

}