export namespace CompaniesModelStruct {

    export interface ICompanyShort {
        id : number,
        name : string,
        users : number,
        instanceId : number,
    }
    export interface ICompanyShortSource {
        id : string,
        name : string,
        users : string,
        instance_id : string,
    }

    export interface ICompany extends ICompanyShort {
        address : string,
        notes : string,
        ownerId : number,
        ownerName : string,
        instance : number,
        instanceTwo : number,
        instancePort : number,
        instancePortTwo : number,

    }
    export interface ICompanySource extends ICompanyShortSource {
        address : string,
        notes : string,
        owner_id : string,
        owner_name : string,
        instances : {
            id : string,
            id2 : string,
            port : string,
            port2 : string,
        }
    }

    export interface ICompanySearch {
        id : number,
        name : string,
    }
    export interface ICompanySearchSource {
        id : number,
        name : string,
    }

}