import React from 'react';
import { observer } from 'mobx-react';
import { Form } from '@Framework/Library/Form';
import { FiltersFormStore } from '@App/Service/Companies/Details/Sensors/List/FiltersFormStore';
import { SearchField, SelectField } from '@Framework/Component/FormField';
import { Location } from '@Framework/Core/Location';
import { App } from '@Framework/Core/App';
import { DevicesModel } from '@App/Service/Companies/Details/Devices/Model/DevicesModel';
import { EStatus } from '@App/Service/Companies/Details/Sensors/Details/Details';

interface IProps {
    ports : string[],
    port ?: string,
    device ?: string,
    id : number,
    onChange ?: Function,
}
interface IState {}

@observer
export class FiltersForm extends React.Component<IProps, IState> {

    private readonly form : FiltersFormStore = new FiltersFormStore();

    public componentDidMount() : void {
        this.form.fields.port.fill(this.props.port || '');
        this.form.fields.device.fill(this.props.device || '', { name: '' });
        if(this.props.device) this.loadData();

    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(
            this.props.port != prevProps.port ||
            this.props.device != prevProps.device
        ) {
            this.form.fields.port.fill(this.props.port || '');
            this.form.fields.device.fill(this.props.device || '', { name: '' });
        }
        if(this.props.device != prevProps.device && this.props.device) this.loadData();
    }

    public render() : React.ReactNode {
        return (
            <Form store={this.form} unsafe={true} onSubmit={() => this.onSubmit()}>
                <div className="form-group">
                    <label>Ports</label>
                    <SelectField store={this.form.fields.port} size="sm">
                        <option value="">Any</option>
                        {this.props.ports.map(item => (
                            <option key={item} value={item}>{item}</option>
                        ))}
                    </SelectField>
                </div>
                <div className="form-group">
                    <label>Devices</label>
                    <SearchField
                        id={'user-search'}
                        onSearch={async (keywords, page) => {
                            const res = await DevicesModel.list({
                                qstr: keywords,
                                page,
                                limit: 10,
                            });
                            return {
                                keywords,
                                options: res.success && res.payload
                                    ? res.payload.data.map(item => ({
                                        id: item.deviceId,
                                        name: item.name,
                                    }))
                                    : [],
                                pages: res.success && res.payload
                                    ? res.payload.pagination.page
                                    : 1,
                                total: res.success && res.payload
                                    ? res.payload.pagination.pages
                                    : 1,
                            };
                        }}
                        store={this.form.fields.device}
                    />
                </div>
                <div className="btn-group btn-group-sm w-100">
                    <button type="submit" className="btn btn-primary">
                        <i className="fa fa-check" /> Apply
                    </button>
                    <button type="button" className="btn btn-secondary" onClick={() => this.onClear()}>
                        <i className="fa fa-ban" /> Clear
                    </button>
                </div>
            </Form>
        );
    }

    private loadData() : void {
        (async () => {
            this.setState({ status: EStatus.Loading });
            const res = await DevicesModel.details(Number(this.props.device));
            if(res.success && res.payload) {
                this.form.fields.device.meta.name = res.payload.data.name;
                this.setState({ status: EStatus.Loaded });
            } else this.setState({ status: EStatus.Failed });
        })();
    }

    private onSubmit() : void {
        const filters = this.form.getValues();
        const query = { ...Location.params };
        for(const key in filters) {
            if(filters[key] === null || (key == 'port' && !filters[key])) {
                delete query[key];
            } else query[key] = filters[key];
        }
        query.page = '1';
        const newQuery = Location.buildQuery(query);
        if(this.props.onChange) this.props.onChange(query);
        App.redirect(`/companies/${this.props.id}/sensors${newQuery ? `?${newQuery}` : ''}`);
    }

    private onClear() : void {
        const filters = this.form.getValues();
        const query = { ...Location.params };
        for(const key in filters) delete query[key];
        query.page = '1';
        const newQuery = Location.buildQuery(query);
        if(this.props.onChange) this.props.onChange(query);
        App.redirect(`/companies/${this.props.id}/sensors${newQuery ? `?${newQuery}` : ''}`);
    }

}