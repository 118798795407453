import { ERequestMethod, IModelOptions, Model, Reply } from '@Framework/Library/Gateway';
import { OnlineModelRes, OnlineModelReq } from '@App/Service/Online/Model';
import { ERemoteResource } from '@App';
import { DefaultStruct } from '@App/Structures';

export abstract class OnlineModel extends Model {

    public static list(
        query : DefaultStruct.IQuery = {},
        options : IModelOptions = {},
    ) : Promise<Reply<OnlineModelRes.List.IPayload>> {
        return this.request<DefaultStruct.IQuery, {}, OnlineModelRes.List.IPayload>({
            resource: ERemoteResource.Auth,
            path: 'list',
            query: OnlineModelReq.List.getQuery(query),
            responseHandler: OnlineModelRes.List.getPayload,
        }, options);
    }

}