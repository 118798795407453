import { TRuleType, IRuleResult } from '@Framework/Library/Form/Rule';
import { Locale } from '@Framework/Core/Locale';

interface IProps {
    message ?: string
}

export function ruleEmail(props : IProps = {}) : TRuleType {
    const lang = Locale.Lang.rules.email;
    return function (value : string) : IRuleResult {
        if(value.length < 1) return { isValid: true };
        if(!value.match(/^[a-z\d]+([.\-_+][a-z\d]+)*@[a-z\d]+([.\-_+][a-z\d]+)*\.[a-z\d]+$/i)) {
            return {
                isValid: false,
                message: props.message || lang.invalidMessage(),
            };
        }
        return { isValid: true };
    };
}