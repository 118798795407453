import { ERequestMethod, IModelOptions, Model, Reply } from '@Framework/Library/Gateway';
import { SensorsModelReq } from '@App/Service/Companies/Details/Sensors/Model/SensorsModel.req';
import { SensorsModelRes } from '@App/Service/Companies/Details/Sensors/Model/SensorsModel.res';

export abstract class SensorsModel extends Model {

    public static list(
        query : SensorsModelReq.List.IQuery,
        options : IModelOptions = {},
    ) : Promise<Reply<SensorsModelRes.List.IPayload>> {
        return this.request<SensorsModelReq.List.IQueryOutput, {}, SensorsModelRes.List.IPayload>({
            path: 'admin/devices/sensors',
            query: SensorsModelReq.List.getQuery(query),
            responseHandler: SensorsModelRes.List.getPayload,
        }, options);
    }

    public static create(
        payload : SensorsModelReq.Settings.IPayload,
        options : IModelOptions = {},
    ) : Promise<Reply<SensorsModelRes.Details.IPayload>> {
        return this.request<{}, SensorsModelReq.Settings.IPayloadOutput, SensorsModelRes.Details.IPayload>({
            path: 'admin/devices/sensors/create',
            method: ERequestMethod.POST,
            payload: SensorsModelReq.Settings.getOutput(payload),
        }, options);
    }

    public static details(
        id : number,
        options : IModelOptions = {},
    ) : Promise<Reply<SensorsModelRes.Details.IPayload>> {
        return this.request<{}, {}, SensorsModelRes.Details.IPayload>({
            path: `admin/devices/sensors/${id}`,
            responseHandler: SensorsModelRes.Details.getPayload,
        }, options);
    }

    public static update(
        id : number,
        payload : SensorsModelReq.Settings.IPayload,
        options : IModelOptions = {},
    ) : Promise<Reply<SensorsModelRes.Details.IPayload>> {
        return this.request<{}, SensorsModelReq.Settings.IPayloadOutput, SensorsModelRes.Details.IPayload>({
            path: `admin/devices/sensors/${id}`,
            method: ERequestMethod.POST,
            payload: SensorsModelReq.Settings.getOutput(payload),
            responseHandler: SensorsModelRes.Details.getPayload,
        }, options);
    }

    public static delete(
        id : number,
        options : IModelOptions = {},
    ) : Promise<Reply<any>> {
        return this.request<{}, {}, {}>({
            path: `admin/devices/sensors/${id}`,
            method: ERequestMethod.DELETE,
        }, options);
    }

    public static groups(
        options : IModelOptions = {},
    ) : Promise<Reply<SensorsModelRes.Relations.IPayload>> {
        return this.relations('groups', options);
    }

    public static locations(
        options : IModelOptions = {},
    ) : Promise<Reply<SensorsModelRes.Relations.IPayload>> {
        return this.relations('locations', options);
    }

    public static relations(
        type : 'groups' | 'locations',
        options : IModelOptions = {},
    ) : Promise<Reply<SensorsModelRes.Relations.IPayload>> {
        return this.request<{}, {}, SensorsModelRes.Relations.IPayload>({
            path: type,
            query: { limit: 500 },
            responseHandler: SensorsModelRes.Relations.getPayload,
        }, options);
    }

}