import React from 'react';
import { EModalSize, Link, Modal } from '@Framework/Factory';
import { Loading } from '@Framework/Component/Loading';
import { Pagination } from '@Framework/Component/Pagination';
import { DefaultStruct } from '@App/Structures';
import { App } from '@Framework/Core/App';
import moment from 'moment';
import { Details, ETab } from '@App/Service/Companies/Details/Details';
import { SetsModel } from '@App/Service/Companies/Details/Sets/Model/SetsModel';
import { SetsModelStruct } from '@App/Service/Companies/Details/Sets/Model/SetsModel.struct';

enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}
interface IProps {
    companyId : number,
    page ?: number,
    limit ?: number,
    orderBy ?: string,
    sort ?: string,
    port ?: string,
    device ?: string,
}
interface IState {
    status : EStatus,
    data : SetsModelStruct.ISets[],
    pagination : DefaultStruct.IPagination,
}

export class List extends React.Component<IProps, IState> {

    private isMount : boolean = false;

    constructor(props) {
        super(props);
        this.state = {
            status: EStatus.Unloaded,
            data: [],
            pagination: null,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.loadData();
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(
            this.props.page != prevProps.page ||
            this.props.limit != prevProps.limit ||
            this.props.orderBy != prevProps.orderBy ||
            this.props.sort != prevProps.sort ||
            this.props.port != prevProps.port ||
            this.props.device != prevProps.device
        ) this.loadData();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        return (
            <Details
                id={this.props.companyId}
                tab={ETab.Sets}
                title={<h3 className="m-0">Sets</h3>}
                controls={
                    <>
                        <Link url={`companies/${this.props.companyId}/sets/create`}><a className="btn btn-sm btn-primary"><i className="fa fa-plus" /> Add Sets</a></Link>
                    </>
                }
                breadcrumb={
                    <>
                        <li className="breadcrumb-item">Sets</li>
                    </>
                }
            >
                {this.state.status != EStatus.Loaded &&
                    <div className="card-body">
                        {this.state.status == EStatus.Loading && <Loading />}
                        {this.state.status == EStatus.Failed && <div className="alert alert-danger"><i className="fa fa-exclamation-triangle" /> Loading error...</div>}
                    </div>
                }
                {this.state.status == EStatus.Loaded &&
                    <>
                        <div className="card-body p-0 d-none d-lg-block">
                            <div className="table-responsive">
                                <table className="table table-hover table-wide m-0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Sensors</th>
                                            <th>Updated</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.data.sort((a, b) => {
                                            if(a.orderno > b.orderno) return 1;
                                            if(a.orderno < b.orderno) return -1;
                                            return 0;
                                        }).map(item => (
                                            <tr key={item.id}>
                                                <td onClick={() => this.openDetails(item.id)}>
                                                    {item.name}
                                                </td>
                                                <td onClick={() => this.openDetails(item.id)}>
                                                    {item.sensors.sort((a, b) => {
                                                        if(a.orderno > b.orderno) return 1;
                                                        if(a.orderno < b.orderno) return -1;
                                                        return 0;
                                                    }).map(items => (
                                                        <span key={items.sensor_id}>
                                                            <span className="badge bg-light me-2">{items.value}</span>
                                                        </span>
                                                    ))}
                                                </td>
                                                <td onClick={() => this.openDetails(item.id)}>
                                                    {moment.unix(item.tm).fromNow()}
                                                </td>
                                                <td className="text-end">
                                                    <Link url={`companies/${this.props.companyId}/sets/${item.id}`}>
                                                        <a className="btn btn-xs btn-primary">
                                                            <i className="fa fa-eye" /> Details
                                                        </a>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-footer">
                            <Pagination limit={this.props.limit} { ...this.state.pagination } />
                        </div>
                    </>
                }
            </Details>
        );
    }

    private loadData() : void {
        (async () => {
            this.setState({ status: EStatus.Loading });
            const sets = await SetsModel.list(this.props);
            if(!this.isMount) return;
            if(sets.success && sets.payload) {
                this.setState({
                    status: EStatus.Loaded,
                    data: sets.payload.data,
                    pagination: sets.payload.pagination,
                });
            } else this.setState({ status: EStatus.Failed });
        })();
    }

    private openDetails(id : number) : void {
        App.redirect(`companies/${this.props.companyId}/sets/${id}`);
    }

}