import { SetsModelStruct } from '@App/Service/Companies/Details/Sets/Model/SetsModel.struct';
import { DefaultStruct } from '@App/Structures';

export namespace SetsModelRes {

    export namespace List {
        export interface IPayload {
            data : SetsModelStruct.ISets[],
            pagination : DefaultStruct.IPagination,
        }
        interface IPayloadSource {
            data : SetsModelStruct.ISetsSource[],
            paginator : DefaultStruct.IPaginationSource,
            status : number,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            return {
                data: source.data.map(item => ({
                    id: Number(item.id) || 0,
                    company_id: Number(item.company_id) || 0,
                    tm_a: Number(item.tm_a) || 0,
                    tm: Number(item.tm) || 0,
                    deleted: Number(item.deleted) || 0,
                    orderno: Number(item.orderno) || 0,
                    name: item.name || '',
                    sensors: item.sensors.map(item => ({
                        sensor_id: Number(item.sensor_id) || 0,
                        value: Number(item.value) || 0,
                        value_tm: Number(item.value_tm) || 0,
                        orderno: Number(item.orderno) || 0,
                    })),
                })),
                pagination: {
                    limit: Number(source.paginator.limit) || 25,
                    page: Number(source.paginator.page) || 1,
                    pages: Number(source.paginator.pages) || 1,
                    total: Number(source.paginator.total) || 0,
                },
            };
        }
    }

    export namespace Details {
        export interface IPayload {
            data : SetsModelStruct.IDetails,
        }
        interface IPayloadSource {
            data : SetsModelStruct.IDetailsSource,
            orderno : number,
            iconId : number,
            status : number,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            return {
                data: {
                    id: Number(source.data.id) || 0,
                    companyId: Number(source.data.company_id) || 0,
                    tmA: Number(source.data.tm_a) || 0,
                    tm: Number(source.data.tm) || 0,
                    deleted: Number(source.data.deleted) || 0,
                    iconId: Number(source.data.icon_id) || 0,
                    name: source.data.name || '',
                    orderno: source.data.orderno || 0,
                    sensors: source.data.sensors.sort((a, b) => {
                        if(a.orderno > b.orderno) return -1;
                        if(a.orderno < b.orderno) return 1;
                        return 0;
                    }).reverse().map(item => ({
                        id: Number(item.sensor_id) || 0,
                        name: String(item.name) || '',
                    })),
                },
            };
        }
    }

}