import React from 'react';
import { Map } from 'subway-router';
import { TLoadService } from '@Framework/Core/App';
import { LoggedIn } from '@App/Middleware';
import { Dashboard } from '@App/Service/Dashboard';

export function register(map : Map, loadService : TLoadService) {
    map.route('/dashboard?', () => {
        loadService(<Dashboard />);
    }).name('dashboard').middleware(new LoggedIn(loadService));
}