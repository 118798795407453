export enum ERequestMethod {
    GET = 'GET',
    PUT = 'PUT',
    POST = 'POST',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

interface IProps<TPayload> {
    host : string,
    path : string,
    query ?: Record<string, string>,
    method ?: ERequestMethod,
    headers ?: Record<string, string>,
    payload ?: TPayload,
}

export class Inquiry<TPayload = any> {

    private readonly _url : string;
    private readonly _method : ERequestMethod;
    private readonly _headers : Record<string, string>;
    private readonly _payload : string | FormData | Blob;

    public get url() : string {
        return this._url;
    }

    public get method() : ERequestMethod {
        return this._method;
    }

    public get headers() : Record<string, string> {
        return this._headers;
    }

    public get payload() : string | FormData | Blob {
        return this._payload;
    }

    constructor(props : IProps<TPayload>) {
        this._url = props.host.trim().replace(/\/+$/, '/') +
            props.path.trim().replace(/^\/+/, '/') +
            Inquiry.getQuery(props.query || {});
        this._method = props.method || ERequestMethod.GET;
        this._headers = props.headers || {};
        this._payload = props.payload
            ? (props.payload instanceof Blob || props.payload instanceof FormData || typeof props.payload == 'string')
                ? props.payload
                : JSON.stringify(props.payload)
            : null;
    }

    private static getQuery(params : Record<string, string>) : string {
        const pairs = [];
        for(const key in params) {
            if(!params.hasOwnProperty(key)) continue;
            pairs.push(`${encodeURIComponent(key)}=${params[encodeURIComponent(key)]}`);
        }
        return pairs.length > 0 ? `?${pairs.join('&')}` : '';
    }

}