import { DefaultStruct } from '@App/Structures';

export namespace OnlineModelReq {

    export namespace List {
        export interface IQuery extends DefaultStruct.IQuery {
            companyId ?: number,
            userId ?: number,
        }
        export interface IQueryOutput extends DefaultStruct.IQueryOutput {
            company_id ?: number,
            user_id ?: number,
            qstr ?: string,
        }
        export function getQuery(query : IQuery) : IQueryOutput {
            const output : IQueryOutput = {};
            if(query.page) output.page = query.page;
            if(query.limit) output.limit = query.limit;
            if(query.qstr) output.qstr = query.qstr;
            if(query.orderBy) output.orderBy = query.orderBy;
            if(query.sort) output.sort = query.sort;
            if(query.companyId) output.company_id = query.companyId;
            if(query.userId) output.user_id = query.userId;
            return output;
        }
    }

}