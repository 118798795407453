import { DefaultStruct } from '@App/Structures';
import { CompaniesModelStruct } from '@App/Service/Companies/Model/CompaniesModel.struct';
import { UserModelStruct } from '@App/Service/Users/Model';

export namespace CompaniesModelRes {

    export namespace List {
        export interface IPayload {
            data : CompaniesModelStruct.ICompanyShort[],
            pagination : DefaultStruct.IPagination,
        }
        export interface IPayloadSource {
            data : CompaniesModelStruct.ICompanyShortSource[],
            status : number,
            paginator : DefaultStruct.IPaginationSource,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            return {
                data: source.data.map(item => ({
                    id: Number(item.id),
                    instanceId: Number(item.instance_id),
                    users: Number(item.users),
                    name: item.name || '',
                })),
                pagination: {
                    limit: 100,
                    page: 1,
                    pages: 1,
                    total: source.data.length,
                },
            };
        }
    }

    export namespace Search {
        export interface IPayload {
            data : CompaniesModelStruct.ICompanySearch[],
            // pagination : DefaultStruct.IPagination,
        }
        export interface IPayloadSource {
            data : CompaniesModelStruct.ICompanySearchSource[],
            status : number,
            // paginator : DefaultStruct.IPaginationSource,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            return {
                data: source.data.map(item => ({
                    id: Number(item.id) || 0,
                    name: item.name,
                })),
                // pagination: {
                //     limit: Number(source.paginator.limit),
                //     page: Number(source.paginator.page),
                //     pages: Number(source.paginator.pages),
                //     total: Number(source.paginator.total),
                // },
            };
        }
    }

    export namespace Details {
        export interface IPayload {
            data : CompaniesModelStruct.ICompany,
        }
        export interface IPayloadSource {
            data : CompaniesModelStruct.ICompanySource,
            status : number,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            return {
                data: {
                    id: Number(source.data.id),
                    instanceId: Number(source.data.instance_id),
                    users: Number(source.data.users),
                    name: source.data.name || '',
                    address: source.data.address || '',
                    notes: source.data.notes || '',
                    ownerName: source.data.owner_name || '',
                    ownerId: Number(source.data.owner_id),
                    instance: Number(source.data.instances.id),
                    instanceTwo: Number(source.data.instances.id2),
                    instancePort: Number(source.data.instances.port),
                    instancePortTwo: Number(source.data.instances.port2),
                },
            };
        }
    }

}