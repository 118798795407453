export enum EHistoryState {
    Default,
    Revert,
    Force
}

export abstract class Location {

    private static _historyId : number = 0;
    private static _historyState : EHistoryState = EHistoryState.Default;
    private static _segments : string[];
    private static _params : Record<string, string>;

    public static get historyState() : EHistoryState {
        return this._historyState;
    }

    public static get historyId() : number {
        return this._historyId;
    }

    public static get url() : string {
        return `${this.path}${this.query ? `?${this.query}` : ''}`;
    }

    public static get path() : string {
        return Location.buildPath(this._segments);
    }

    public static get segments() : string[] {
        return this._segments;
    }

    public static get query() : string {
        return Location.buildQuery(this._params);
    }

    public static get params() : Record<string, string> {
        return this._params;
    }

    public static segment(number : number) : string {
        return this._segments[number > 0 ? number - 1 : 0] || '';
    }

    public static param(name : string) : string {
        return this._params[name] || '';
    }

    public static updateUrl(url : string) : void;

    public static updateUrl(path : string, query : string) : void;

    public static updateUrl(segments : string[], params : Record<string, string>) : void;

    public static updateUrl(segments : string[], query : string) : void;

    public static updateUrl(path : string, params : Record<string, string>) : void;

    public static updateUrl(arg1 : string | string[], arg2 ?: string | Record<string, string>) : void {
        if(typeof arg1 == 'string' && arg2 === undefined) {
            const parts = Location.parseUrl(arg1);
            this._segments = parts.segments;
            this._params = parts.params;
        } else {
            this._segments = typeof arg1 == 'object' ? arg1 : Location.parsePath(arg1);
            this._params = typeof arg2 == 'object' ? arg2 : Location.parseQuery(arg2);
        }
        this.insertHistory();
    }

    public static updatePath(path : string) : void;

    public static updatePath(segments : string[]) : void;

    public static updatePath(arg : string | string[]) : void {
        if(typeof arg == 'string') {
            this._segments = Location.parsePath(arg);
        } else this._segments = arg;
        this.insertHistory();
    }

    public static updateQuery(query : string) : void;

    public static updateQuery(params : Record<string, string>) : void;

    public static updateQuery(arg : string | Record<string, string>) : void {
        if(typeof arg == 'string') {
            this._params = Location.parseQuery(arg);
        } else this._params = arg;
        this.insertHistory();
    }

    public static backward() : void {
        this._historyState = EHistoryState.Default;
        window.history.back();
        this.restoreHistory();
    }

    public static forward() : void {
        this._historyState = EHistoryState.Default;
        window.history.forward();
        this.restoreHistory();
    }

    public static onPopState(locked : boolean, onForce : Function) : boolean {
        let redirected = true;
        if(this._historyState == EHistoryState.Default && locked) {
            this._historyState = EHistoryState.Revert;
            const historyId = (window.history.state || {}).id || 0;
            const backward = historyId < this._historyId;
            onForce(() => {
                this._historyState = EHistoryState.Force;
                window.history[backward ? 'back' : 'forward']();
            });
            window.history[backward ? 'forward' : 'back']();
            redirected = false;
        } else if(this._historyState == EHistoryState.Revert) {
            this._historyState = EHistoryState.Default;
            redirected = false;
        } else if(this._historyState == EHistoryState.Force) {
            this._historyState = EHistoryState.Default;
        }
        this.restoreHistory();
        return redirected;
    }

    private static restoreHistory() : void {
        this._historyId = (window.history.state || {}).id || 0;
        const parts = Location.parseUrl(window.location.href);
        this._segments = parts.segments;
        this._params = parts.params;
    }

    private static insertHistory() : void {
        this._historyId = new Date().getTime();
        window.history.pushState({ id: this._historyId }, null, this.url);
    }

    public static parseUrl(url : string = '') : { segments : string[], params : Record<string, string> } {
        const parts = decodeURI(url)
            .replace(/^https?:\/{2}.*?\//, '')
            .replace(/#.*?$/, '')
            .split('?');
        return {
            segments: Location.parsePath(parts[0]),
            params: Location.parseQuery(parts[1]),
        };
    }

    public static buildPath(segments : string[] = []) : string {
        return `/${segments.join('/')}`;
    }

    public static parsePath(path : string = '') : string[] {
        return path
            .replace(/^\/+/, '')
            .replace(/\/+$/, '')
            .replace(/\/+/, '/')
            .split('/');
    }

    public static buildQuery(params : Record<string, string> = {}) : string {
        const props = [];
        for(const key in params) {
            if(!params.hasOwnProperty(key)) continue;
            const val = params[key];
            props.push(`${encodeURIComponent(key)}${val !== null ? `=${encodeURIComponent(val)}` : ''}`);
        }
        return props.join('&');
    }

    public static parseQuery(query : string = '') : Record<string, string> {
        const params : Record<string, string> = {};
        const props = query
            .replace(/^\?+/, '')
            .split('&');
        for(let i = 0; i < props.length; i++) {
            const temp = props[i].split('=');
            if(temp[0] == '') continue;
            params[decodeURIComponent(temp[0])] = temp.length > 1 ? decodeURIComponent(temp[1]) : null;
        }
        return params;
    }

}