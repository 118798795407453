import React from 'react';
import './Loading.less';

interface Props {
    label ?: string,
    noLabel ?: boolean,
    small ?: boolean,
    vertical ?: boolean,
    light ?: boolean,
}
interface State {}

export class Loading extends React.Component<Props, State> {

    public render() {
        return (
            <div className={`loading${this.props.vertical ? ' loading-vertical' : ''}`}>
                <div><span className={`spinner-border${this.props.small ? ' spinner-border-sm' : ''}`}></span></div>
                {!this.props.noLabel && <div className={this.props.light ? 'text-light' : ''}><em>{this.props.label || 'Loading...'}</em></div>}
            </div>
        );
    }

}