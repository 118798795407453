import React from 'react';
import { EModalSize, Link, Modal } from '@Framework/Factory';
import { Loading } from '@Framework/Component/Loading';
import { Pagination } from '@Framework/Component/Pagination';
import { DefaultStruct } from '@App/Structures';
import { App } from '@Framework/Core/App';
import { SortLink } from '@Framework/Component/SortLink';
import { FiltersForm } from '@App/Service/Companies/Details/Sensors/List/FiltersForm';
import moment from 'moment';
import { Details, ETab } from '@App/Service/Companies/Details/Details';
import { SensorsModel } from '@App/Service/Companies/Details/Sensors/Model/SensorsModel';
import { SensorsModelStruct } from '@App/Service/Companies/Details/Sensors/Model/SensorsModel.struct';
import { Tooltip } from '@Framework/Component/Tooltip';

enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}
interface IProps {
    companyId : number,
    page ?: number,
    limit ?: number,
    orderBy ?: string,
    sort ?: string,
    port ?: string,
    device ?: string,
}
interface IState {
    status : EStatus,
    data : SensorsModelStruct.ISensor[],
    pagination : DefaultStruct.IPagination,
    filtersOpen : boolean,
    ports : string[],
}

export class List extends React.Component<IProps, IState> {

    private isMount : boolean = false;

    constructor(props) {
        super(props);
        this.state = {
            status: EStatus.Unloaded,
            data: [],
            pagination: null,
            filtersOpen: false,
            ports: [],
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.loadData();
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(
            this.props.page != prevProps.page ||
            this.props.limit != prevProps.limit ||
            this.props.orderBy != prevProps.orderBy ||
            this.props.sort != prevProps.sort ||
            this.props.port != prevProps.port ||
            this.props.device != prevProps.device
        ) this.loadData();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        let activeFilters = 0;
        if(this.props.port) activeFilters++;
        if(this.props.device) activeFilters++;

        return (
            <Details
                id={this.props.companyId}
                tab={ETab.Sensors}
                title={<h3 className="m-0">Sensors</h3>}
                controls={
                    <>
                        <button type="button" className="btn btn-outline-primary btn-sm d-lg-none " onClick={() => this.setState({ filtersOpen: true })} style={{ margin: '-10px 0' }}>
                            <i className="fa fa-cogs" /> Filters {activeFilters > 0 && <span className="badge bg-info">{activeFilters}</span>}
                        </button>
                        {' '}
                        <Link url={`companies/${this.props.companyId}/sensors/create`}><a className="btn btn-sm btn-primary"><i className="fa fa-plus" /> Add Sensor</a></Link>
                    </>
                }
                rightSide={
                    this.state.status == EStatus.Loaded
                        ? <div className="col-auto d-none d-lg-block" style={{ width: '220px' }}>
                            <div className="card">
                                <div className="card-header">Filters {activeFilters > 0 && <span className="badge bg-info">{activeFilters}</span>}</div>
                                <div className="card-body">
                                    <FiltersForm
                                        ports={this.state.ports}
                                        port={this.props.port}
                                        device={this.props.device}
                                        id={this.props.companyId}
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                }
                breadcrumb={
                    <>
                        <li className="breadcrumb-item">Sensors</li>
                    </>
                }
            >
                {this.state.status != EStatus.Loaded &&
                    <div className="card-body">
                        {this.state.status == EStatus.Loading && <Loading />}
                        {this.state.status == EStatus.Failed && <div className="alert alert-danger"><i className="fa fa-exclamation-triangle" /> Loading error...</div>}
                    </div>
                }
                {this.state.status == EStatus.Loaded &&
                    <>
                        <div className="card-body p-0 d-none d-lg-block">
                            <div className="table-responsive">
                                <table className="table table-hover table-wide m-0">
                                    <thead>
                                        <tr>
                                            <th><SortLink name="name">Name</SortLink></th>
                                            <th>Value</th>
                                            <th>Location</th>
                                            <th><SortLink name="active">Active</SortLink></th>
                                            <th><SortLink name="priority">Priority</SortLink></th>
                                            <th><SortLink name="updated">Updated</SortLink></th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.data.map(item => (
                                            <tr key={item.idx} className={item.alerts > 0 ? 'bg-danger' : undefined}>
                                                <td onClick={() => this.openDetails(item.idx)}>
                                                    {item.iconId > 0 &&
                                                        <img src={item.icon.src} height={22} alt="" />
                                                    } {item.name}
                                                </td>
                                                <td onClick={() => this.openDetails(item.idx)}>
                                                    <span className="badge bg-light">{item.valueVisual}</span>
                                                    {/*{item.value !== null &&*/}
                                                    {/*    <span className={`badge bg-${((item.alertMin !== null && item.value < item.alertMin) || (item.alertMax !== null && item.value > item.alertMax)) ? 'danger' : 'success'}`}>*/}
                                                    {/*        {item.value}{item.valueType.sign}*/}
                                                    {/*    </span>*/}
                                                    {/*}*/}
                                                </td>
                                                <td onClick={() => this.openDetails(item.idx)} className="font-monospace">
                                                    {item.location
                                                        ? item.location.name.length > 12
                                                            ? <Tooltip content={item.location.name}><span className="badge bg-light">{item.location.name.slice(0, 9)}...</span></Tooltip>
                                                            : <span className="badge bg-light">{item.location.name}</span>
                                                        : null
                                                    }
                                                </td>
                                                <td onClick={() => this.openDetails(item.idx)}>
                                                    <span className={`badge bg-${item.isActive ? 'success' : 'danger'}`}>
                                                        {item.isActive ? 'Yes' : 'No'}
                                                    </span>
                                                </td>
                                                <td onClick={() => this.openDetails(item.idx)}>
                                                    {item.priorityId && item.priority.name}
                                                </td>
                                                <td onClick={() => this.openDetails(item.deviceId)}>
                                                    {moment.unix(item.tm).fromNow()}
                                                </td>
                                                <td className="text-end">
                                                    <Link url={`companies/${this.props.companyId}/sensors/${item.idx}`}>
                                                        <a className="btn btn-xs btn-primary">
                                                            <i className="fa fa-eye" /> Details
                                                        </a>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-body py-0 d-lg-none">
                            {this.state.data.map(item => (
                                <div className="item-block" key={item.idx} onClick={() => this.openDetails(item.idx)}>
                                    <div className="item-body">
                                        <div className="row">
                                            <div className="col">
                                                {item.alerts > 0 && <span className="text-danger"><i className="fa fa-exclamation-triangle" /></span>} <strong>{item.name}</strong>
                                            </div>
                                            <div className="col-auto">
                                                <span className={`badge bg-${item.isActive ? 'success' : 'danger'}`}>
                                                    {item.isActive ? 'Active' : 'Not Active'}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div>
                                                    <span className="prop">Value:</span>
                                                    {' '}
                                                    <span className="badge bg-light">{item.valueVisual}</span>
                                                    {/*{item.value !== null &&*/}
                                                    {/*    <span className={`badge bg-${((item.alertMin !== null && item.value < item.alertMin) || (item.alertMax !== null && item.value > item.alertMax)) ? 'danger' : 'success'}`}>*/}
                                                    {/*        {item.value}{item.valueType.sign}*/}
                                                    {/*    </span>*/}
                                                    {/*}*/}
                                                </div>
                                                <div className="font-monospace">
                                                    <span className="prop">Location:</span>
                                                    {' '}
                                                    {item.location
                                                        ? item.location.name.length > 12
                                                            ? <Tooltip content={item.location.name}><span className="badge bg-light">{item.location.name.slice(0, 9)}...</span></Tooltip>
                                                            : <span className="badge bg-light">{item.location.name}</span>
                                                        : null
                                                    }
                                                </div>
                                                <div className="font-monospace">
                                                    <span className="prop">Group:</span>
                                                    {' '}
                                                    {item.group
                                                        ? item.group.name.length > 12
                                                            ? <Tooltip content={item.group.name}><span className="badge bg-light">{item.group.name.slice(0, 9)}...</span></Tooltip>
                                                            : <span className="badge bg-light">{item.group.name}</span>
                                                        : null
                                                    }
                                                </div>
                                                <div>
                                                    <span className="prop">Priority:</span>
                                                    {' '}
                                                    {item.priorityId && item.priority.name}
                                                </div>
                                                <div>
                                                    <span className="prop">Updated:</span>
                                                    {' '}
                                                    {moment.unix(item.tm).fromNow()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-controls text-end">
                                        <Link url={`companies/${this.props.companyId}/sensors/${item.idx}`}>
                                            <a className="btn btn-xs btn-primary">
                                                <i className="fa fa-eye" /> Details
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="card-footer">
                            <Pagination limit={this.props.limit} { ...this.state.pagination } />
                        </div>
                    </>
                }
                {this.state.filtersOpen &&
                    <Modal
                        size={EModalSize.SM}
                        class="d-lg-none"
                        onClickOutside={() => this.setState({ filtersOpen: false })}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">Filters {activeFilters > 0 && <span className="badge bg-info">{activeFilters}</span>}</h5>
                            <button type="button" className="btn btn-light btn-sm" onClick={() => this.setState({ filtersOpen: false })}>
                                <i className="fa fa-times"/>
                            </button>
                        </div>
                        <div className="modal-body">
                            <FiltersForm
                                ports={this.state.ports}
                                port={this.props.port}
                                device={this.props.device}
                                id={this.props.companyId}
                                onChange={() => this.setState({ filtersOpen: false })}
                            />
                        </div>
                    </Modal>
                }
            </Details>
        );
    }

    private loadData() : void {
        (async () => {
            this.setState({ status: EStatus.Loading });
            const sensors = await SensorsModel.list(this.props);
            if(!this.isMount) return;
            if(sensors.success && sensors.payload) {
                this.setState({
                    status: EStatus.Loaded,
                    data: sensors.payload.data,
                    ports: sensors.payload.ports,
                    pagination: sensors.payload.pagination,
                });
            } else this.setState({ status: EStatus.Failed });
        })();
    }

    private openDetails(id : number) : void {
        App.redirect(`companies/${this.props.companyId}/sensors/${id}`);
    }

}