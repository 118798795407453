import React from 'react';
import { Map } from 'subway-router';
import { TLoadService } from '@Framework/Core/App';
import { LoggedIn } from '@App/Middleware';
import { List, Details } from '@App/Service/Logs';
import { Location } from '@Framework/Core/Location';
import { RangeSelector } from '@App/Component/RangeSelector';

export function register(map : Map, loadService : TLoadService) {
    map.group('/logs', group => {
        group.route('/', res => {
            loadService(<List
                page={Number(res.key('page')) || 1}
                limit={Number(res.key('limit')) || 50}
                orderBy={res.key('orderBy') || undefined}
                sort={res.key('sort') || undefined}
                typeId={Number(Location.param('typeId')) || null}
                severity={Number(Location.param('severity')) || null}
                ip={Location.param('ip') || null}
                rangeType={RangeSelector.stringToRangeType(res.key('rangeType'))}
                dateFrom={res.key('dateFrom') ? Number(res.key('dateFrom')) : undefined}
                dateTo={res.key('dateTo') ? Number(res.key('dateTo')) : undefined}
            />);
        });
        group.route('/{id:i}', res => {
            loadService(<Details id={Number(res.segment(2))} />);
        });
    }).name('logs').middleware(new LoggedIn(loadService));
}