import React from 'react';
import { IMiddleware, TOnLoad } from 'subway-router';
import { Session } from '@Framework/Core/Session';
import { Login } from '@App/Service/Auth/Login/Login';
import { AccessDeny } from '@App/SystemPage';
import { TLoadService } from '@Framework/Core/App';

export class LoggedIn implements IMiddleware {

    private readonly _loadService : TLoadService;

    constructor(loadService : TLoadService) {
        this._loadService = loadService;
    }

    public onResolving(onLoad : TOnLoad) : TOnLoad {
        if(!Session.isActive) {
            return () => this._loadService(<Login />);
        } else if(Session.user.role.id != 1) {
            return () => this._loadService(<AccessDeny />);
        } else return onLoad;
    }

}