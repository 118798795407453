import { EFieldType, Field, Group } from '@Framework/Library/Form';
import { makeObservable, override } from 'mobx';

type TFields = {
    typeId : Field,
    severity : Field,
    ip : Field,
};

interface IValues {
    typeId : number,
    severity : number,
    ip : string,
}

export class FiltersFormStore extends Group<TFields, IValues> {

    @override protected _fields = {
        typeId: new Field({
            type: EFieldType.Number,
        }),
        severity: new Field({
            type: EFieldType.Number,
        }),
        ip: new Field({
            type: EFieldType.String,
        }),
    };

    constructor() {
        super(null, true);
        makeObservable(this);
    }

}