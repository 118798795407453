import React from 'react';
import moment from 'moment';
import { CalendarWeek } from '@Framework/Component/Calendar';
import '@Framework/Component/Calendar/calendar.less';
import { ELocale, Locale } from '@Framework/Core/Locale';

interface IProps {
    displayed ?: number,
    selected ?: number,
    onSelected ?: (date : number) => void,
}
interface IState {}

export class CalendarSheet extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);
        this.onSelected = this.onSelected.bind(this);
    }

    public render() : React.ReactNode {
        const days = [ ...Locale.Lang.days.short ];
        if(Locale.formats == ELocale.US) {
            days.unshift(days[6]);
            days.splice(7);
        }
        const selected = this.props.selected ? moment.unix(this.props.selected) : null;
        const displayed = this.getDisplayed();
        const startOffset = Locale.formats == ELocale.US ? displayed.weekday() : displayed.isoWeekday() - 1;
        const daysInMonth = displayed.daysInMonth();
        const prevMonthEndDay = moment(displayed).subtract(1, 'month').daysInMonth();
        const today = displayed.year() == moment().year() && displayed.month() == moment().month()
            ? moment().date()
            : -1;
        const active = selected
            ? displayed.year() == selected.year() && displayed.month() == selected.month()
                ? selected.date()
                : -1
            : -1;
        return (
            <div className="calendar-sheet">
                <div className="calendar-header">
                    {days.map(item => (
                        <div key={item}>{item}</div>
                    ))}
                </div>
                <div className="calendar-body">
                    <CalendarWeek
                        startIndex={-startOffset}
                        daysInMonth={daysInMonth}
                        prevMonthEndDay={prevMonthEndDay}
                        today={today}
                        active={active}
                        onSelected={this.onSelected}
                    />
                    <CalendarWeek
                        startIndex={7 - startOffset}
                        daysInMonth={daysInMonth}
                        prevMonthEndDay={prevMonthEndDay}
                        today={today}
                        active={active}
                        onSelected={this.onSelected}
                    />
                    <CalendarWeek
                        startIndex={14 - startOffset}
                        daysInMonth={daysInMonth}
                        prevMonthEndDay={prevMonthEndDay}
                        today={today}
                        active={active}
                        onSelected={this.onSelected}
                    />
                    <CalendarWeek
                        startIndex={21 - startOffset}
                        daysInMonth={daysInMonth}
                        prevMonthEndDay={prevMonthEndDay}
                        today={today}
                        active={active}
                        onSelected={this.onSelected}
                    />
                    <CalendarWeek
                        startIndex={28 - startOffset}
                        daysInMonth={daysInMonth}
                        prevMonthEndDay={prevMonthEndDay}
                        today={today}
                        active={active}
                        onSelected={this.onSelected}
                    />
                    <CalendarWeek
                        startIndex={35 - startOffset}
                        daysInMonth={daysInMonth}
                        prevMonthEndDay={prevMonthEndDay}
                        today={today}
                        active={active}
                        onSelected={this.onSelected}
                    />
                </div>
            </div>
        );
    }

    private onSelected(day : number, month : number = 0) : void {
        if(!this.props.onSelected) return;
        const displayed = this.getDisplayed();
        if(month < 0) displayed.subtract(1, 'month');
        if(month > 0) displayed.add(1, 'month');
        displayed.date(day);
        this.props.onSelected(displayed.unix());
    }

    private getDisplayed() : moment.Moment {
        const displayed = this.props.displayed
            ? moment.unix(this.props.displayed)
            : this.props.selected
                ? moment.unix(this.props.selected)
                : moment();
        displayed.date(1);
        return displayed;
    }

}