import { DefaultStruct } from '@App/Structures';

export namespace CompaniesModelReq {

    export namespace List {
        export interface IQuery extends DefaultStruct.IQuery {
            deleted ?: number,
        }
        export interface IQueryOutput extends DefaultStruct.IQueryOutput {
            deleted ?: number,
            qstr ?: string,
        }
        export function getQuery(query : IQuery) : IQueryOutput {
            const output : IQueryOutput = {};
            if(query.page) output.page = query.page;
            if(query.limit) output.limit = query.limit;
            if(query.orderBy) output.orderBy = query.orderBy;
            if(query.sort) output.sort = query.sort;
            if(query.qstr) output.qstr = query.qstr;
            if(query.deleted) output.deleted = query.deleted;
            return output;
        }
    }

    export namespace Create {
        export interface IPayload {
            name : string,
            address : string,
            notes : string,
            ownerId : number,
            instance : number,
            instanceTwo : number,
            instancePort : number,
            instancePortTwo : number,
        }
        export interface IPayloadOutput {
            name : string,
            address : string,
            notes : string,
            owner_id : string,
            instance_id : string,
            instance2_id : string,
            instance_port : string,
            instance2_port : string,

        }
        export function getOutput(payload : IPayload) : IPayloadOutput {
            return {
                name: payload.name || '',
                address: payload.address || '',
                notes: payload.notes || '',
                owner_id: String(payload.ownerId),
                instance_id: String(payload.instance),
                instance2_id: String(payload.instanceTwo),
                instance_port: String(payload.instancePort),
                instance2_port: String(payload.instancePortTwo),
            };
        }
    }

    export namespace Details {
        export interface IPayload {
            name : string,
            address : string,
            notes : string,
            ownerId : number,
            instance : number,
            instanceTwo : number,
            instancePort : number,
            instancePortTwo : number,
        }
        export interface IPayloadOutput {
            name : string,
            address : string,
            notes : string,
            owner_id : string,
            instance_id : string,
            instance2_id : string,
            instance_port : string,
            instance2_port : string,

        }
        export function getOutput(payload : IPayload) : IPayloadOutput {
            return {
                name: payload.name || '',
                address: payload.address || '',
                notes: payload.notes || '',
                owner_id: String(payload.ownerId),
                instance_id: String(payload.instance),
                instance2_id: String(payload.instanceTwo),
                instance_port: String(payload.instancePort),
                instance2_port: String(payload.instancePortTwo),
            };
        }
    }

}