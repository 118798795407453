import React from 'react';
import { observer } from 'mobx-react';
import { Field } from '@Framework/Library/Form';
import '@Framework/Component/FormField/FormField.less';

export interface IFormFieldProps {
    store : Field,
    placeholder ?: string,
    readonly ?: boolean,
    disabled ?: boolean,
    silent ?: boolean,
    inline ?: boolean,
    wrapperClass ?: string,
    size ?: 'xs' | 'sm' | 'lg',
}

@observer
export class FormField<TFieldProps extends IFormFieldProps, TFieldState = any> extends React.Component<TFieldProps, TFieldState> {

    protected readonly _formFieldClass : string = '';
    protected readonly _defaultInline : boolean = false;

    public render() : React.ReactElement {
        const inline = this.props.inline !== undefined ? this.props.inline : this._defaultInline;
        return (
            <div className={`form-field${inline ? '-inline' : ''}${this._formFieldClass ? ` ${this._formFieldClass}` : ''}${this.props.wrapperClass ? ` ${this.props.wrapperClass}` : ''}`}>
                {this.renderField()}
                {(!this.props.silent && !this.props.store.isValid && this.props.store.error) &&
                    <div className="invalid-tooltip">{this.props.store.error}</div>
                }
            </div>
        );
    }

    protected renderField() : React.ReactElement {
        return null;
    }

}