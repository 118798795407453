import { Pagination } from '@Framework/Component/Pagination';

Pagination.buttonClasses = {
    firstLast: 'btn-light',
    prevNext: 'btn-light',
    numbers: 'btn-light',
    current: 'btn-light',
    limit: 'btn-light',
};
Pagination.limits = [
    25,
    50,
    100,
    250,
    500,
    1000,
];