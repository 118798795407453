import { TRuleType, IRuleResult } from '@Framework/Library/Form/Rule';

interface IProps {
    cond : () => boolean,
    rules : TRuleType[],
    alter ?: TRuleType[],
}

export function ruleIf(props : IProps) : TRuleType {
    return function (value : string) : IRuleResult {
        if(props.cond()) {
            for(let i = 0; i < props.rules.length; i++) {
                const res = props.rules[i](value);
                if(!res.isValid) return res;
            }
        } else if(props.alter) {
            for(let i = 0; i < props.alter.length; i++) {
                const res = props.alter[i](value);
                if(!res.isValid) return res;
            }
        }
        return { isValid: true };
    };
}