import { EFieldType, Field, Group, List, Rule } from '@Framework/Library/Form';
import { makeObservable } from 'mobx';

type TFields = {
    alertDaily : Field,
    superuser : Field,
    email : Field,
    name : Field,
    phone : Field,
    companies : List<Group<{
        id : Field,
        role : Field,
    }>>,
    isActive : Field,
    password : Field,
    passwordConfirm : Field,
}
interface IValues {
    alertDaily : boolean,
    email : string,
    superuser : boolean,
    name : string,
    phone : string,
    companies : {
        id : number,
        role : number,
    }[],
    isActive : boolean,
    password : string,
    passwordConfirm : string,
}

export class DetailsFormStore extends Group<TFields, IValues> {

    constructor() {
        super(null, true);
        const superuser = new Field({
            type: EFieldType.Boolean,
            value: false,
        });
        const password = new Field();
        const passwordConfirm = new Field({
            rules: [ Rule.matches({ field: password }) ],
        });
        this._fields = {
            alertDaily: new Field({
                type: EFieldType.Boolean,
            }),
            superuser,
            email: new Field({
                rules: [
                    Rule.required(),
                    Rule.email(),
                ],
            }),
            name: new Field({
                rules: [ Rule.required() ],
            }),
            phone: new Field(),
            companies: new List(() => (
                new Group(() => ({
                    id: new Field({
                        rules: [ Rule.required() ],
                        type: EFieldType.Number,
                    }),
                    role: new Field({
                        rules: [ Rule.required() ],
                        type: EFieldType.Number,
                    }),
                }))
            )),
            isActive: new Field({
                type: EFieldType.Boolean,
            }),
            password,
            passwordConfirm,
        };
        makeObservable(this);
    }

}