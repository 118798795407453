import React from 'react';
import { CreateFormStore } from '@App/Service/Companies/Details/Devices/Create/CreateFormStore';
import { Form } from '@Framework/Library/Form';
import { InputField, SelectField, SwitchField, TextField } from '@Framework/Component/FormField';
import { observer } from 'mobx-react';
import { App } from '@Framework/Core/App';
import { ENotificationType } from '@Framework/Component/Notification';
import { DevicesModel } from '@App/Service/Companies/Details/Devices/Model/DevicesModel';
import { Details, ETab } from '@App/Service/Companies/Details/Details';
import { Link } from '@Framework/Factory';
import { Reply } from '@Framework/Library/Gateway';
import { SensorsModelRes } from '@App/Service/Companies/Details/Sensors/Model/SensorsModel.res';
import { SensorsModel } from '@App/Service/Companies/Details/Sensors/Model/SensorsModel';
import { DefaultStruct } from '@App/Structures';

export enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}

interface IProps {
    companyId : number;
}
interface IState {
    saving : boolean,
    status : EStatus,
    groups : DefaultStruct.IOption[],
    locations : DefaultStruct.IOption[],
    openPassword : boolean,
}

@observer
export class Create extends React.Component<IProps, IState> {

    private isMount : boolean = false;
    private form : CreateFormStore = new CreateFormStore();

    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            status: EStatus.Unloaded,
            groups: [],
            locations: [],
            openPassword: false,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.form.save();
        this.loadData();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        return (
            <Details
                id={this.props.companyId}
                tab={ETab.Devices}
                title={<h3 className="m-0">Add Device</h3>}
                breadcrumb={
                    <>
                        <li className="breadcrumb-item">
                            <Link url={`companies/${this.props.companyId}/devices`}><a>Devices</a></Link>
                        </li>
                        <li className="breadcrumb-item">Add Device</li>
                    </>
                }
            >
                <Form store={this.form} onSubmit={() => this.onSubmit()}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>UID <span className="text-danger">*</span></label>
                                    <InputField disabled={this.state.saving} store={this.form.fields.uid} />
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <label>Name</label>
                                    <InputField disabled={this.state.saving} store={this.form.fields.name} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group">
                                    <label>Active</label>
                                    <SwitchField disabled={this.state.saving} store={this.form.fields.active} wide={true} />
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <label>Priority</label>
                                    <SelectField disabled={this.state.saving} store={this.form.fields.priority}>
                                        <option value="3">Normal</option>
                                        <option value="1">Highest</option>
                                        <option value="2">High</option>
                                        <option value="4">Low</option>
                                        <option value="5">Lowest</option>
                                    </SelectField>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group">
                                    <label>Notes</label>
                                    <TextField disabled={this.state.saving} store={this.form.fields.notes} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Group <span className="text-danger">*</span></label>
                                    <SelectField disabled={this.state.saving} store={this.form.fields.groupId}>
                                        <option value=""></option>
                                        {this.state.groups.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}
                                    </SelectField>
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <label>Location <span className="text-danger">*</span></label>
                                    <SelectField disabled={this.state.saving} store={this.form.fields.locationId}>
                                        <option value=""></option>
                                        {this.state.locations.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}
                                    </SelectField>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col"/>
                            <div className="col-auto">
                                <button type="submit" disabled={this.state.saving} className="btn btn-primary">
                                    {this.state.saving
                                        ? <span className="spinner-border spinner-border-sm" />
                                        : <i className="fa fa-check" />
                                    } Create
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </Details>
        );
    }

    private loadData() : void {
        this.setState({ status: EStatus.Loading });
        let loaded = 0;
        const onLoaded = (res : Reply<SensorsModelRes.Relations.IPayload>, field : 'groups' | 'locations') => {
            loaded++;
            if(!this.isMount) return;
            if(res.success) {
                const keys = {};
                keys[field] = res.payload.data || [];
                this.setState(state => ({
                    ...keys,
                    ...loaded >= 2 && state.status != EStatus.Failed ? { status: EStatus.Loaded } : null,
                }));
            } else this.setState({ status: EStatus.Failed });
        };
        SensorsModel
            .groups()
            .then(res => onLoaded(res, 'groups'));
        SensorsModel
            .locations()
            .then(res => onLoaded(res, 'locations'));
    }

    private onSubmit() : void {
        this.form.validate();
        if(!this.form.isValid) return;
        this.setState({ saving: true });
        (async () => {
            const res = await DevicesModel.create(this.form.getValues());
            if(!this.isMount) return;
            if(res.success) {
                this.form.save();
                App.notification({
                    type: ENotificationType.Success,
                    title: 'Success',
                    message: 'Successfully created.',
                });
                App.redirect(`companies/${this.props.companyId}/devices`);
            }
            this.setState({ saving: true });
        })();
    }

}