import { DefaultStruct } from '@App/Structures';
import { LogsModelStruct } from '@App/Service/Logs/Model/LogsModel.struct';

export namespace LogsModelRes {

    export namespace List {

        export interface IPayload {
            data : LogsModelStruct.ILogsShort[],
            pagination : DefaultStruct.IPagination,
        }
        export interface IPayloadSource {
            data : LogsModelStruct.ILogsShortSource[],
            status : number,
            paginator : DefaultStruct.IPaginationSource,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            const getType = function (id : string) : string {
                if(id == '1') return 'Auth';
                if(id == '2') return 'Sensor';
                if(id == '3') return 'Device';
                if(id == '4') return 'User';
                if(id == '5') return 'Group';
                if(id == '6') return 'Location';
                if(id == '7') return 'Company';
                return 'Undefined';
            };
            const getSeverity = function (id : string) : string {
                if(id == '1') return 'Critical';
                if(id == '2') return 'High';
                if(id == '3') return 'Normal';
                if(id == '4') return 'Low';
                if(id == '5') return 'Notice';
                return 'Undefined';
            };
            return {
                data: source.data.map(item => ({
                    companyId: Number(item.company_id),
                    id: Number(item.id),
                    ip: item.ip || '',
                    severity: getSeverity(item.severity),
                    tm: Number(item.tm),
                    txt: item.txt || '',
                    userId: Number(item.user_id),
                    type: {
                        name: getType(item.type_id),
                    },
                })),
                pagination: {
                    limit: source.paginator.limit,
                    page: source.paginator.page,
                    pages: source.paginator.pages,
                    total: Number(source.paginator.total),
                },
            };
        }
    }

    export namespace Details {
        export interface IPayload {
            data : LogsModelStruct.ILogsShort,
        }
        export interface IPayloadSource {
            data : LogsModelStruct.ILogsShortSource,
            status : number,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            const getType = function (id : string) : string {
                if(id == '1') return 'Auth';
                if(id == '2') return 'Sensor';
                if(id == '3') return 'Device';
                if(id == '4') return 'User';
                if(id == '5') return 'Group';
                if(id == '6') return 'Location';
                return 'Company';
            };
            const getSeverity = function (id : string) : string {
                if(id == '1') return 'Critical';
                if(id == '2') return 'High';
                if(id == '3') return 'Normal';
                if(id == '4') return 'Low';
                if(id == '5') return 'Notice';
                return 'Undefined';
            };
            return {
                data: {
                    companyId: Number(source.data.company_id),
                    id: Number(source.data.id),
                    ip: source.data.ip || '',
                    severity: getSeverity(source.data.severity),
                    tm: Number(source.data.tm),
                    txt: source.data.txt || '',
                    userId: Number(source.data.user_id),
                    type: {
                        name: getType(source.data.type_id),
                    },
                },
            };
        }
    }

}