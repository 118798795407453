import { ILanguage } from '@Framework/Core/Language';

export const English : ILanguage = {
    days: {
        short: [ 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun' ],
        full: [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ],
    },
    months: {
        short: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ],
        full: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
    },
    rules: {
        date: {
            invalidMessage() {
                return 'Invalid format.';
            },
            minMessage(min : number) {
                return `Cannot be less than ${min}.`;
            },
            maxMessage(max : number) {
                return `Cannot be greater than ${max}.`;
            },
            lessMessage(less : number) {
                return `Should be less than ${less}.`;
            },
            greaterMessage(greater : number) {
                return `Should be greater than ${greater}.`;
            },
            inPastMessage() {
                return 'Should be in past.';
            },
            inFutureMessage() {
                return 'Should be in future.';
            },
        },
        email: {
            invalidMessage() {
                return 'Invalid email.';
            },
        },
        length: {
            equalMessage(equal : number) {
                return `Should contain ${equal} characters.`;
            },
            minMessage(min : number) {
                return `Should contain at least ${min} characters.`;
            },
            maxMessage(max : number) {
                return `Should contain at max ${max} characters.`;
            },
        },
        matches: {
            notMatchMessage() {
                return 'Fields does not match.';
            },
        },
        number: {
            invalidMessage() {
                return 'Invalid number.';
            },
            noNegativeMessage() {
                return 'Should contain positive number.';
            },
            noDecimalMessage() {
                return 'Should contain integer number.';
            },
            minMessage(min : number) {
                return `Cannot be less than ${min}.`;
            },
            maxMessage(max : number) {
                return `Cannot be greater than ${max}.`;
            },
            lessMessage(less : number) {
                return `Should be less than ${less}.`;
            },
            greaterMessage(greater : number) {
                return `Should be greater than ${greater}.`;
            },
        },
        pattern: {
            invalidMessage() {
                return 'Invalid format.';
            },
        },
        required: {
            requiredMessage() {
                return 'Field is required.';
            },
        },
        url: {
            invalidMessage() {
                return 'Invalid URL';
            },
        },
    },
};