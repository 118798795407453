import React from 'react';
import { FormField, IFormFieldProps } from '@Framework/Component/FormField';

interface IProps extends IFormFieldProps {
    cols ?: number,
    rows ?: number,
    fixed ?: boolean,
}

export class TextField extends FormField<IProps> {

    protected renderField() : React.ReactElement {
        return (
            <textarea
                readOnly={!!this.props.readonly}
                disabled={!!this.props.disabled}
                placeholder={this.props.placeholder || undefined}
                className={`form-control${this.props.size ? ` form-control-${this.props.size}` : ''}${this.props.store.isValid ? '' : ' is-invalid'}`}
                value={this.props.store.value}
                onChange={e => this.props.store.value = e.target.value}
                autoComplete="off"
                cols={this.props.cols || undefined}
                rows={this.props.rows || undefined}
                style={this.props.fixed ? { resize: 'none' } : undefined}
            />
        );
    }

}