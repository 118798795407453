import { TRuleType, IRuleResult } from '@Framework/Library/Form/Rule';
import { Locale } from '@Framework/Core/Locale';

interface IProps {
    pattern : RegExp,
    message ?: string
}

export function rulePattern(props : IProps) : TRuleType {
    const lang = Locale.Lang.rules.pattern;
    return function (value : string) : IRuleResult {
        if(value.length < 1) return { isValid: true };
        if(!value.match(props.pattern)) {
            return {
                isValid: false,
                message: props.message || lang.invalidMessage(),
            };
        }
        return { isValid: true };
    };
}