import { EFieldType, Field, Group, List, Rule } from '@Framework/Library/Form';
import { makeObservable } from 'mobx';

type TFields = {
    name : Field,
    address : Field,
    notes : Field,
    ownerId : Field,
    instance : Field,
    instanceTwo : Field,
    instancePort : Field,
    instancePortTwo : Field,
}
interface IValues {
    name : string,
    address : string,
    notes : string,
    ownerId : number,
    instance : number,
    instanceTwo : number,
    instancePort : number,
    instancePortTwo : number,

}

export class CreateFormStore extends Group<TFields, IValues> {

    constructor() {
        super(null, true);
        this._fields = {
            name: new Field({
                rules: [ Rule.required() ],
            }),
            address: new Field(),
            notes: new Field(),
            ownerId: new Field({
                rules: [ Rule.required() ],
                type: EFieldType.Number,
            }),
            instance: new Field(),
            instanceTwo: new Field(),
            instancePort: new Field(),
            instancePortTwo: new Field(),

        };
        makeObservable(this);
    }

}