import React from 'react';
import { MainLayout } from '@App/Layout';
import { Link } from '@Framework/Factory';
import { CompaniesModel, CompaniesModelStruct } from '@App/Service/Companies/Model';

export enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}
export enum ETab {
    Devices,
    Sets,
    Sensors,
    DataSources,
    Settings,
    Logs
}
interface IProps {
    children : React.ReactNode,
    id : number,
    tab : ETab,
    controls ?: React.ReactNode,
    rightSide ?: React.ReactNode,
    breadcrumb ?: React.ReactNode,
    title ?: React.ReactNode,
}
interface IState {
    status : EStatus,
    data : CompaniesModelStruct.ICompany,
}

export const CompaniesContext = React.createContext<IState>(null);

export class Details extends React.Component<IProps, IState> {

    private isMount : boolean = false;

    constructor(props) {
        super(props);
        this.state = {
            status: EStatus.Unloaded,
            data: null,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.loadData();
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(this.props.id != prevProps.id) this.loadData();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        return (
            <MainLayout service="companies">
                <CompaniesContext.Provider value={this.state}>
                    <div className="container-fluid my-3">
                        <div className="row">
                            <div className="col">
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link url="/"><a><i className="fa fa-home" /> Home</a></Link></li>
                                        <li className="breadcrumb-item"><Link url="/companies"><a>Companies</a></Link></li>
                                        {this.props.breadcrumb}
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid my-3">
                        <div className="row">
                            <div className="col">
                                <div className="card">
                                    <div className="card-header">
                                        {this.state.status == EStatus.Loaded
                                            ? <div className="row">
                                                <div className="col-md">
                                                    {this.props.title}
                                                    <h3 className="m-0"></h3>
                                                </div>
                                            </div>
                                            : <><span className="spinner-border spinner-border-sm"></span> Loading...</>
                                        }
                                    </div>
                                    <div className="card-header p-0 pt-2 border-bottom-0">
                                        <ul className="nav nav-tabs flex-row px-3">
                                            <li className="nav-item">
                                                <Link url={`/companies/${this.props.id}/devices`}>
                                                    <a className={`nav-link ${this.props.tab == ETab.Devices ? 'active' : ''}`}>Devices</a>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link url={`/companies/${this.props.id}/sets`}>
                                                    <a className={`nav-link ${this.props.tab == ETab.Sets ? 'active' : ''}`}>Sets</a>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link url={`/companies/${this.props.id}/sensors`}>
                                                    <a className={`nav-link ${this.props.tab == ETab.Sensors ? 'active' : ''}`}>Sensors</a>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link url={`/companies/${this.props.id}/data-sources`}>
                                                    <a className={`nav-link ${this.props.tab == ETab.DataSources ? 'active' : ''}`}>Data Sources</a>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link url={`/companies/${this.props.id}/settings`}>
                                                    <a className={`nav-link ${this.props.tab == ETab.Settings ? 'active' : ''}`}>Settings</a>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link url={`/companies/${this.props.id}/logs`}>
                                                    <a className={`nav-link ${this.props.tab == ETab.Logs ? 'active' : ''}`}>Logs</a>
                                                </Link>
                                            </li>
                                            {this.props.controls &&
                                                <li className="ms-auto">
                                                    {this.props.controls}
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                    {this.props.children}
                                </div>
                            </div>
                            {this.props.rightSide ? this.props.rightSide : null}
                        </div>
                    </div>
                </CompaniesContext.Provider>
            </MainLayout>
        );
    }

    private loadData() : void {
        (async () => {
            this.setState({ status: EStatus.Loading });
            const res = await CompaniesModel.details(this.props.id);
            if(!this.isMount) return;
            if(res.success && res.payload) {
                this.setState({
                    status: EStatus.Loaded,
                    data: res.payload.data,
                });
            } else this.setState({ status: EStatus.Failed });
        })();
    }

}