import { DefaultStruct } from '@App/Structures';
import { DevicesModelStruct } from '@App/Service/Companies/Details/Devices/Model/DevicesModel.struct';

export namespace DevicesModelRes {

    export namespace List {
        export interface IPayload {
            data : DevicesModelStruct.IDevicesShort[],
            pagination : DefaultStruct.IPagination,
        }
        export interface IPayloadSource {
            data : DevicesModelStruct.IDevicesShortSource[],
            status : number,
            paginator : DefaultStruct.IPaginationSource,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            return {
                data: source.data.map(item => ({
                    deviceId: item.device_id || '',
                    uid: item.uid || '',
                    name: item.name || '',
                    statusText: item.status_text || '',
                    notes: item.notes || '',
                    alerts: Number(item.alerts) || 0,
                    time: Number(item.tm) || 0,
                    ports: Number(item.ports) || 0,
                    portsActive: Number(item.ports_active) || 0,
                })),
                pagination: {
                    limit: Number(source.paginator.limit),
                    page: Number(source.paginator.page),
                    pages: Number(source.paginator.pages),
                    total: Number(source.paginator.total),
                },
            };
        }
    }

    export namespace Details {
        export interface IPayload {
            data : DevicesModelStruct.IDevices,
        }
        export interface IPayloadSource {
            data : DevicesModelStruct.IDevicesSource,
            status : number,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            return {
                data: {
                    deviceId: source.data.device_id || '',
                    uid: source.data.uid || '',
                    name: source.data.name || '',
                    notes: source.data.notes || '',
                    active: source.data.active == '1',
                    priority: Number(source.data.priority) || 0,
                    groupId: Number(source.data.group_id) || null,
                    locationId: Number(source.data.location_id) || null,
                    statusText: source.data.status_text || '',
                    alerts: Number(source.data.alerts) || 0,
                    time: Number(source.data.tm) || 0,
                    ports: Number(source.data.ports) || 0,
                    portsActive: Number(source.data.ports_active) || 0,

                },
            };
        }
    }
}