import React from 'react';
import { FormField, IFormFieldProps } from '@Framework/Component/FormField';

interface IProps extends IFormFieldProps {
    type ?: string,
}

export class InputField extends FormField<IProps> {

    protected renderField() : React.ReactElement {
        return (
            <input
                type={this.props.type ? this.props.type : 'text'}
                readOnly={!!this.props.readonly}
                disabled={!!this.props.disabled}
                placeholder={this.props.placeholder || undefined}
                className={`form-control${this.props.size ? ` form-control-${this.props.size}` : ''}${this.props.store.isValid ? '' : ' is-invalid'}`}
                value={this.props.store.value}
                onChange={e => this.props.store.value = e.target.value}
                autoComplete="off"
            />
        );
    }

}