import { Field, Group } from '@Framework/Library/Form';
import { makeObservable, override } from 'mobx';

type TFields = {
    port : Field,
    device : Field,
};

interface IValues {
    port : string,
    device : string,
}

export class FiltersFormStore extends Group<TFields, IValues> {

    @override protected _fields = {
        port: new Field(),
        device: new Field(),
    };

    constructor() {
        super(null, true);
        makeObservable(this);
    }

}