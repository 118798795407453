import React from 'react';
import { CalendarControls, CalendarSheet } from '@Framework/Component/Calendar';
import '@Framework/Component/Calendar/calendar.less';

interface IProps {
    displayed ?: number,
    selected ?: number,
    onChanged ?: (date : number) => void,
}
interface IState {
    displayed : number,
    selected : number,
}

export class Calendar extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            displayed: this.props.displayed || undefined,
            selected: this.props.selected || undefined,
        };
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(this.props.displayed != prevProps.displayed || this.props.selected != prevProps.selected) {
            this.setState({
                displayed: this.props.displayed || undefined,
                selected: this.props.selected || undefined,
            });
        }
    }

    public render() : React.ReactNode {
        return (
            <div className="calendar">
                <CalendarControls
                    displayed={this.state.displayed}
                    onDisplayChanged={displayed => this.setState({ displayed })}
                />
                <CalendarSheet
                    displayed={this.state.displayed}
                    selected={this.state.selected}
                    onSelected={selected => this.onChanged(selected)}
                />
            </div>
        );
    }

    private onChanged(selected : number) : void {
        this.setState({ selected });
        if(this.props.onChanged) this.props.onChanged(selected);
    }

}