import { EFieldType, Field, Group, List, Rule } from '@Framework/Library/Form';
import { makeObservable } from 'mobx';

type TFields = {
    id : Field,
    type : Field,
    active : Field,
    hostname : Field,
    port : Field,
    username : Field,
    password : Field,
    authType : Field,
    option1num : Field,
    option2num : Field,
    option3num : Field,
    option1str : Field,
    option2str : Field,
    option3str : Field,
    name : Field,

}
interface IValues {
    id : string,
    type : number,
    active : number,
    hostname : string,
    port : number,
    username : string,
    password : string,
    authType : number,
    option1num : number,
    option2num : number,
    option3num : number,
    option1str : string,
    option2str : string,
    option3str : string,
    name : string,
}

export class DetailsFormStore extends Group<TFields, IValues> {

    constructor() {
        super(null, true);

        this._fields = {
            id: new Field({
                rules: [ Rule.required() ],
            }),
            name: new Field(),
            type: new Field(),
            hostname: new Field(),
            active: new Field({
                type: EFieldType.Boolean,
            }),
            port: new Field(),
            username: new Field(),
            authType: new Field({
                rules: [ Rule.number() ],
            }),
            option1num: new Field({
                rules: [ Rule.number() ],
            }),
            option2num: new Field({
                rules: [ Rule.number() ],
            }),
            option3num: new Field({
                rules: [ Rule.number() ],
            }),
            option1str: new Field(),
            option2str: new Field(),
            option3str: new Field(),
            password: new Field(),
        };
        makeObservable(this);
    }

}