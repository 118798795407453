import { DefaultStruct } from '@App/Structures';
import { DevicesModelStruct } from '@App/Service/Companies/Details/Devices/Model/DevicesModel.struct';
import { DataSourcesModelStruct } from '@App/Service/Companies/Details/DataSources/Model/DataSourcesModel.struct';

export namespace DataSourcesModelRes {

    export namespace List {
        export interface IPayload {
            data : DataSourcesModelStruct.IDataSourcesShort[],
            pagination : DefaultStruct.IPagination,
        }
        export interface IPayloadSource {
            data : DataSourcesModelStruct.IDataSourcesShortSource[],
            status : number,
            paginator : DefaultStruct.IPaginationSource,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            return {
                data: source.data.map(item => ({
                    type: Number(item.type) || 0,
                    active: Number(item.active) || 0,
                    port: Number(item.port) || 0,
                    authType: Number(item.auth_type) || 0,
                    option1num: Number(item.option1num) || 0,
                    option2num: Number(item.option2num) || 0,
                    option3num: Number(item.option3num) || 0,
                    tmAdded: Number(item.tm_added) || 0,
                    tmUpdated: Number(item.tm_updated) || 0,
                    tmLast: Number(item.tm_last) || 0,

                    id: item.id || '',
                    hostname: item.hostname || '',
                    username: item.username || '',
                    password: item.password || '',
                    option1str: item.option1str || '',
                    option2str: item.option2str || '',
                    option3str: item.option3str || '',
                    name: item.name || '',
                })),
                pagination: {
                    limit: Number(source.paginator.limit),
                    page: Number(source.paginator.page),
                    pages: Number(source.paginator.pages),
                    total: Number(source.paginator.total),
                },
            };
        }
    }

    export namespace Details {
        export interface IPayload {
            data : DataSourcesModelStruct.IDataSourcesShort,
        }
        export interface IPayloadSource {
            data : DataSourcesModelStruct.IDataSourcesShortSource,
            status : number,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            return {
                data: {
                    type: Number(source.data.type) || 0,
                    active: Number(source.data.active) || 0,
                    port: Number(source.data.port) || 0,
                    authType: Number(source.data.auth_type) || 0,
                    option1num: Number(source.data.option1num) || 0,
                    option2num: Number(source.data.option2num) || 0,
                    option3num: Number(source.data.option3num) || 0,
                    tmAdded: Number(source.data.tm_added) || 0,
                    tmUpdated: Number(source.data.tm_updated) || 0,
                    tmLast: Number(source.data.tm_last) || 0,
                    id: source.data.id || '',
                    hostname: source.data.hostname || '',
                    username: source.data.username || '',
                    password: source.data.password || '',
                    option1str: source.data.option1str || '',
                    option2str: source.data.option2str || '',
                    option3str: source.data.option3str || '',
                    name: source.data.name || '',
                },
            };
        }
    }
}