import React from 'react';
import moment from 'moment';
import { OnClickOutside } from '@Framework/Factory';
import { SelectDate } from '@Framework/Component/Calendar';
import { ELocale, Locale } from '@Framework/Core/Locale';

export enum ERangeType {
    None,
    Custom,
    Today,
    Yesterday,
    ThisWeek,
    Last7Days,
    Last30Days,
    ThisMonth,
}
export interface IRangeProps {
    rangeType ?: ERangeType,
    dateFrom ?: number,
    dateTo ?: number,
}
export interface IDateQuery {
    from ?: number,
    to ?: number,
}
interface IProps extends IRangeProps {
    onChange : (props : IRangeProps) => void,
}
interface IState {
    rangeType : ERangeType,
    dateFrom : number,
    dateTo : number,
    open : boolean,
}

export class RangeSelector extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            rangeType: this.props.rangeType,
            dateFrom: this.props.rangeType == ERangeType.Custom && this.props.dateFrom
                ? this.props.dateFrom
                : null,
            dateTo: this.props.rangeType == ERangeType.Custom && this.props.dateTo
                ? this.props.dateTo
                : null,
            open: false,
        };
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(this.props.rangeType != prevProps.rangeType) {
            this.setState({
                rangeType: this.props.rangeType,
                ...this.props.rangeType == ERangeType.Custom
                    ? { dateFrom: this.props.dateFrom || null, dateTo: this.props.dateFrom || null }
                    : { dateFrom: null, dateTo: null },
            });
        }
    }

    public render() : React.ReactNode {
        return (
            <div className="dropdown" id="period-selector">
                <button className="btn btn-sm btn-outline-primary" onClick={() => this.setState(state => ({ open: !state.open }))}>
                    <i className="fa fa-calendar" />
                    {' '}
                    {this.state.rangeType == ERangeType.Custom
                        ? `${moment.unix(this.state.dateFrom).format('DD.MM.YYYY')}-${moment.unix(this.state.dateTo).format('DD.MM.YYYY')}`
                        : RangeSelector.getRangeTypeText(this.state.rangeType)
                    }
                </button>
                {this.state.open &&
                    <OnClickOutside topLevelId="period-selector" onClick={() => this.setState({ open: false })}>
                        <div className="dropdown-menu show p-1" style={{ left: 'auto', right: 0, width: '360px' }}>
                            <div className="row">
                                <div className="col">
                                    <ul className="nav nav-pills">
                                        <li className="nav-item" style={{ flex: '33.33%' }}>
                                            <button className={`nav-link w-100 p-1${this.state.rangeType == ERangeType.Today ? ' active' : ''}`} onClick={() => this.changeRangeType(ERangeType.Today)}>Today</button>
                                        </li>
                                        <li className="nav-item" style={{ flex: '33.33%' }}>
                                            <button className={`nav-link w-100 p-1${this.state.rangeType == ERangeType.Yesterday ? ' active' : ''}`} onClick={() => this.changeRangeType(ERangeType.Yesterday)}>Yesterday</button>
                                        </li>
                                        <li className="nav-item" style={{ flex: '33.33%' }}>
                                            <button className={`nav-link w-100 p-1${this.state.rangeType == ERangeType.ThisWeek ? ' active' : ''}`} onClick={() => this.changeRangeType(ERangeType.ThisWeek)}>This week</button>
                                        </li>
                                        <li className="nav-item" style={{ flex: '33.33%' }}>
                                            <button className={`nav-link w-100 p-1${this.state.rangeType == ERangeType.Last7Days ? ' active' : ''}`} onClick={() => this.changeRangeType(ERangeType.Last7Days)}>Last 7 days</button>
                                        </li>
                                        <li className="nav-item" style={{ flex: '33.33%' }}>
                                            <button className={`nav-link w-100 p-1${this.state.rangeType == ERangeType.Last30Days ? ' active' : ''}`} onClick={() => this.changeRangeType(ERangeType.Last30Days)}>Last 30 days</button>
                                        </li>
                                        <li className="nav-item" style={{ flex: '33.33%' }}>
                                            <button className={`nav-link w-100 p-1${this.state.rangeType == ERangeType.ThisMonth ? ' active' : ''}`} onClick={() => this.changeRangeType(ERangeType.ThisMonth)}>This month</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col text-center">
                                    <em className="text-secondary">Or select range</em>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col px-0">
                                    <SelectDate id="date-from" date={this.state.dateFrom || undefined} onChanged={date => this.setState({ dateFrom: date })} />
                                </div>
                                <div className="col px-0">
                                    <SelectDate id="date-to" date={this.state.dateTo || undefined} dropdownRight={true} onChanged={date => this.setState({ dateTo: date })} />
                                </div>
                                <div className="col-auto px-0">
                                    <button type="button" disabled={!this.state.dateFrom || !this.state.dateTo} className="btn btn-sm btn-primary" onClick={() => this.selectCustomRange()}>
                                        <i className="fa fa-check" /> Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </OnClickOutside>
                }
            </div>
        );
    }

    private changeRangeType(rangeType : ERangeType) : void {
        this.setState({
            open: false,
            rangeType,
            dateFrom: null,
            dateTo: null,
        });
        this.props.onChange({ rangeType });
    }

    private selectCustomRange() : void {
        this.setState({ open: false });
        this.props.onChange({
            rangeType: ERangeType.Custom,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
        });
    }

    public static stringToRangeType(src : string) : ERangeType {
        if(src == '1') {
            return ERangeType.Custom;
        } else if(src == '2') {
            return ERangeType.Today;
        } else if(src == '3') {
            return ERangeType.Yesterday;
        } else if(src == '4') {
            return ERangeType.ThisWeek;
        } else if(src == '5') {
            return ERangeType.Last7Days;
        } else if(src == '6') {
            return ERangeType.Last30Days;
        } else if(src == '7') {
            return ERangeType.ThisMonth;
        } else return null;
    }

    public static getDateQuery(props : IRangeProps) : IDateQuery {
        const query : IDateQuery = {};
        if(props.rangeType == ERangeType.Today) {
            query.from = moment()
                .hours(0)
                .minutes(0)
                .seconds(0)
                .unix();
        } else if(props.rangeType == ERangeType.Yesterday) {
            query.from = moment()
                .subtract(1, 'day')
                .hours(0)
                .minutes(0)
                .seconds(0)
                .unix();
            query.to = query.from + 86399;
        } else if(props.rangeType == ERangeType.ThisWeek) {
            const weekday = moment().isoWeekday();
            query.from = moment()
                .subtract(Locale.formats == ELocale.US ? weekday == 7 ? 0 : weekday : weekday - 1, 'days')
                .hours(0)
                .minutes(0)
                .seconds(0)
                .unix();
        } else if(props.rangeType == ERangeType.Last7Days) {
            query.from = moment()
                .subtract(7, 'days').hours(0)
                .hours(0)
                .minutes(0)
                .seconds(0)
                .unix();
        } else if(props.rangeType == ERangeType.Last30Days) {
            query.from = moment()
                .subtract(30, 'days')
                .hours(0)
                .minutes(0)
                .seconds(0)
                .unix();
        } else if(props.rangeType == ERangeType.ThisMonth) {
            query.from = moment()
                .date(1)
                .hours(0)
                .minutes(0)
                .seconds(0)
                .unix();
        } else if(props.rangeType == ERangeType.Custom) {
            if(props.dateFrom) query.from = props.dateFrom;
            if(props.dateTo) query.to = props.dateTo;
        }
        return query;
    }

    private static getRangeTypeText(rangeType : ERangeType) : string {
        if(rangeType == ERangeType.Today) {
            return 'Today';
        } else if(rangeType == ERangeType.Yesterday) {
            return 'Yesterday';
        } else if(rangeType == ERangeType.ThisWeek) {
            return 'This week';
        } else if(rangeType == ERangeType.Last7Days) {
            return 'Last 7 days';
        } else if(rangeType == ERangeType.Last30Days) {
            return 'Last 30 days';
        } else if(rangeType == ERangeType.ThisMonth) {
            return 'This month';
        } else return 'All time';
    }

}