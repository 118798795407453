import { EFieldType, Field, Group, List, Rule } from '@Framework/Library/Form';
import { makeObservable, override } from 'mobx';

type TFields = {
    name : Field,
    sensors : List<Field>,
    iconId : Field,
};

interface IValues {
    name : string,
    sensors : number[],
    iconId : number,
}

export class CreateFormStore extends Group<TFields, IValues> {

    @override protected _fields = {
        name: new Field({
            rules: [ Rule.required() ],
        }),
        sensors: new List(() => (
            new Field({
                type: EFieldType.Number,
            })
        )),
        iconId: new Field({
            rules: [ Rule.required() ],
        }),
    };

    constructor() {
        super(null, true);
        makeObservable(this);
    }

}