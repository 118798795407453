import React from 'react';
import '@Framework/Component/Notification/Notification.less';

export enum ENotificationType {
    Default = 'default',
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Error = 'error',
}

export interface INotification {
    type ?: ENotificationType,
    icon ?: string,
    title ?: string,
    message : string | React.ReactElement,
    onClose ?: Function,
}

export function Notification(props : INotification) : React.ReactElement {
    const type = props.type ? props.type : ENotificationType.Default;
    let icon = props.icon || '';
    let title = props.title || '';
    if(type == ENotificationType.Default) {
        icon = 'circle';
        title = 'Notification';
    } else if(type == ENotificationType.Success) {
        icon = 'check-circle';
        title = 'Success';
    } else if(type == ENotificationType.Info) {
        icon = 'info-circle';
        title = 'Info';
    } else if(type == ENotificationType.Warning) {
        icon = 'exclamation-circle';
        title = 'Warning';
    } else if(type == ENotificationType.Error) {
        icon = 'exclamation-circle';
        title = 'Error';
    }
    if(props.icon) icon = props.icon;
    if(props.title) title = props.title;

    return (
        <div className={`toast notification notification-${type}`}>
            <div className="toast-header">
                <strong className="me-auto"><i className={`fa fa-${icon}`} /> {title}</strong>
                <button type="button" className="btn-close" onClick={() => props.onClose ? props.onClose() : null} />
            </div>
            <div className="toast-body">
                {props.message}
            </div>
        </div>
    );
}