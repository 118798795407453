import { DefaultStruct } from '@App/Structures';

export namespace UserModelReq {

    export namespace List {
        export interface IQuery extends DefaultStruct.IQuery {
            companyId ?: number,
        }
        export interface IQueryOutput extends DefaultStruct.IQueryOutput {
            company_id ?: number,
            qstr ?: string,
        }
        export function getQuery(query : IQuery) : IQueryOutput {
            const output : IQueryOutput = {};
            if(query.page) output.page = query.page;
            if(query.limit) output.limit = query.limit;
            if(query.qstr) output.qstr = query.qstr;
            if(query.orderBy) output.orderBy = query.orderBy;
            if(query.sort) output.sort = query.sort;
            if(query.companyId) output.company_id = query.companyId;
            return output;
        }
    }

    export namespace Create {
        export interface IPayload {
            alertDaily : boolean,
            sendLink : boolean,
            superuser : boolean,
            email : string,
            name : string,
            phone : string,
            companies : {
                id : number,
                role : number,
            }[],
            isActive : boolean,
            password : string,
            passwordConfirm : string,
        }
        export interface IPayloadOutput {
            alert_daily : string,
            sendlink : string,
            superuser : string,
            email : string,
            fullname : string,
            phone : string,
            companies ?: {
                id : string,
                role : string,
            }[],
            status : string,
            password ?: string,
            password_confirm ?: string,
        }
        export function getOutput(payload : IPayload) : IPayloadOutput {
            const data : IPayloadOutput = {
                alert_daily: payload.alertDaily ? '1' : '0',
                sendlink: payload.sendLink ? '1' : '0',
                superuser: payload.superuser ? '1' : '0',
                email: payload.email || '',
                fullname: payload.name || '',
                phone: payload.phone || '',
                status: payload.isActive ? '1' : '0',
            };
            if(!payload.superuser) {
                data.companies = payload.companies.map(item => ({
                    id: String(item.id),
                    role: String(item.role),
                }));
            }
            if(!payload.sendLink) {
                data.password = payload.password || '';
                data.password_confirm = payload.passwordConfirm || '';
            }
            return data;
        }
    }

    export namespace Details {
        export interface IPayload {
            alertDaily : boolean,
            superuser : boolean,
            email : string,
            name : string,
            phone : string,
            companies : {
                id : number,
                role : number,
            }[],
            isActive : boolean,
            password : string,
            passwordConfirm : string,
        }
        export interface IPayloadOutput {
            alert_daily : string,
            superuser : string,
            email : string,
            fullname : string,
            phone : string,
            companies ?: {
                id : string,
                role : string,
            }[],
            status : string,
            password ?: string,
            password_confirm ?: string,
        }
        export function getOutput(payload : IPayload) : IPayloadOutput {
            const data : IPayloadOutput = {
                alert_daily: payload.alertDaily ? '1' : '0',
                superuser: payload.superuser ? '1' : '0',
                email: payload.email || '',
                fullname: payload.name || '',
                phone: payload.phone || '',
                status: payload.isActive ? '1' : '0',
                password: payload.password || '',
                password_confirm: payload.passwordConfirm || '',
            };

            if(!payload.superuser) {
                data.companies = payload.companies.map(item => ({
                    id: String(item.id),
                    role: String(item.role),
                }));
            }
            return data;
        }
    }

}