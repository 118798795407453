import { EFieldType, Field, Group } from '@Framework/Library/Form';
import { makeObservable, override } from 'mobx';

type TFields = {
    status : Field,
};

interface IValues {
    status : number,
}

export class FiltersFormStore extends Group<TFields, IValues> {

    @override protected _fields = {
        status: new Field({
            type: EFieldType.Number,
        }),
    };

    constructor() {
        super(null, true);
        makeObservable(this);
    }

}