import { makeObservable, override } from 'mobx';
import { EFieldType, Field, Group, Rule } from '@Framework/Library/Form';

type TFields = {
    username : Field,
    password : Field,
    remember : Field,
}

interface IValues {
    username : string,
    password : string,
    remember : boolean,
}

export class LoginFormStore extends Group<TFields, IValues> {

    @override protected _fields : TFields = {
        username: new Field({
            rules: [ Rule.required(), Rule.email() ],
        }),
        password: new Field({
            rules: [ Rule.required() ],
        }),
        remember: new Field({
            type: EFieldType.Boolean,
        }),
    };

    constructor() {
        super(null, true);
        makeObservable(this);
    }

}